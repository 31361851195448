import 'bootstrap';
import './Styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import RootLayout from './Layout';
import PageSeoComponent from './SEO';
import RoutesComponents from './Routes';
import SEO from './Constant/SEO.json';

const helmetContext = {};

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider context={helmetContext}>
        <RootLayout>
          <PageSeoComponent {...SEO.default} />
          <RoutesComponents />
        </RootLayout>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
