import React from 'react';
import Input from '../Components/Input';
import Button2 from '../Components/Button2';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import showPwdImg from '../images/showpwd.svg';
import hidePwdImg from '../images/hidepwd.svg';
import Navb from '../Components/Navbar';
import httpuserprofile from '../js/http-lms_userprofile';
import { ROUTES } from '../Constant/Routes';
import loginBackground from '../images/login.png';
import PageSeoComponent from '../SEO';
import SEO from '../Constant/SEO.json';

const SignInBS = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [pass, setPass] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState([]);
  const [emailError, setEmailError] = useState();
  const [passError, setPassError] = useState();
  const [disable, setDisable] = useState(true);
  const [roleName, setRoleName] = useState('builder');
  const [roleId, setRoleId] = useState(10);

  useEffect(() => {
    getRoles();
    setDisable(false); // Enable the login button by default
  }, []);

  useEffect(() => {
    if (roleName === 'default' || pass.length === 0 || email.length === 0) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [roleName, pass, email]);

  function roleChange(e) {
    let roles = role.filter((data) => {
      return data.roleName === e.target.value;
    });

    if (e.target.value !== 'default') {
      console.log(roles, 'rrrrrrrrrrrrrrr');
      // sessionStorage.setItem("roleId", roles[0].roleId);
      setRoleId(roles[0].roleId);
    }

    setRoleName(e.target.value);
  }

  function passChange(e) {
    setPass(e.target.value);
  }

  function emailChange(e) {
    var emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let email = e.target.value;
    var validemail = emailRegex.test(email);
    if (validemail) {
      setEmail(e.target.value);
      setEmailError();
    } else {
      setEmailError('Please enter a valid Email Id');
    }
  }

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    await httpuserprofile
      .get('/userloginLMS/getUserRoles?appCode=KRD')
      .then((result) => {
        console.log(result.data);
        setRole(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SignInAuth = async () => {
    setLoading(true);
    setPassError();
    setEmailError();
    sessionStorage.setItem('roleId', roleId);
    await httpuserprofile
      .post('/userloginLMS/validate', {
        emailId: email,
        passWord: pass,
        roleId: roleId,
        authCode: 'KRDREBM390',
      })
      .then((result) => {
        console.log(result.data);
        setLoading(false);

        if (result.data[0].apiStatusMessage === 'Account doesnt exist') {
          setEmailError(result.data[0].apiStatusMessage);
          setPassError();
        } else if (result.data[0].apiStatusMessage === 'PASSWORD_INCORRECT') {
          setPassError('Please enter the correct password');
          setEmailError();
        } else if (
          result.data[0].apiStatusMessage === 'Account is terminated'
        ) {
          setEmailError(result.data[0].apiStatusMessage);
          setPassError();
        } else {
          const bearerToken = result.data[0].jwtToken;
          sessionStorage.setItem('JWT', bearerToken);
          sessionStorage.setItem('userEmail', result.data[0].userEmail);
          sessionStorage.setItem('userId', result.data[0].userId);
          sessionStorage.setItem('LOGGEDIN', true);
          httpuserprofile
            .get(
              `/userprofileLMS/getLoggedInUserDetails?userId=${result.data[0].userId}&roleId=${roleId}&authCode=KRDREBM390`
            )
            .then((response) => {
              console.log(response, 'pid');
              sessionStorage.setItem('profileId', response.data.profileId);
              console.log('UserDetails in update user:', response);
              navigate(ROUTES.MY_PROPERTIES);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((e) => {
        console.log(e.response);
        setLoading(false);
      });
  };

  return (
    <>
      <PageSeoComponent {...SEO.login} />
      {/* <Helmet>
        <title>Login</title>
        <meta
          name="description"
          content="Easiest way to find your dream home"
        />
        <meta name="keywords" content="keywords, separated, by, commas" />
        <link rel="canonical" href="https://www.example.com/page" />
      </Helmet> */}

      {/* <Navb class="d-flex justify-content-between zi100 py-2 w-100 greybg px-5" /> */}

      <div
        className="row d-flex align-items-center justify-content-end vh-100 login-back-container"
        style={{
          backgroundImage: `url(${loginBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="col-lg-4 me-lg-5 col-12 m-md-0  h-100 justify-content-center align-items-center bgwhite d-flex">
          <div className="col px-5 pt-5 bg-white pb-5 d-flex flex-column rounded-3">
            <div className="col-12">
              <div className=" mx-auto text-center pt-1">
                <img
                  className="navlink"
                  src={require('../images/KRHome-final-regular.png')}
                  alt="Image not found"
                  style={{ height: '70px' }}
                />
              </div>
              <div className="col-md-10 mx-auto">
                {/* <p className="font18 inter text-center mt-3">Log in to KRRealEstate!</p> */}
                <div className="mt-4 mb-3">
                  <p className="inter font18 required">E-mail</p>
                  <Input
                    change={emailChange}
                    type="email"
                    name="email"
                    placeholder="Enter your Email"
                  />
                  <div className="height15">
                    {emailError && (
                      <div className="errMsg mb-2">{emailError}</div>
                    )}
                  </div>
                </div>
                <p className="inter font18 required">Password</p>
                <div className="pwd-container">
                  <input
                    name="pwd"
                    placeholder="Enter your Password"
                    type={isRevealPwd ? 'text' : 'password'}
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                  />
                  <img
                    title={isRevealPwd ? 'Hide password' : 'Show password'}
                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                  <div className="height15 pb-4">
                    {passError && (
                      <div className="errMsg mb-4">{passError}</div>
                    )}
                  </div>
                </div>
                <div>
                  {/* <p className="inter font18 required">Role</p> */}
                  <label className="inter font18 required" htmlFor="role">Role</label>
<select onChange={roleChange} name="role" id="role" className="your-select-class">
  {role.map((data) => (
    <option
      key={data.roleId}
      value={data.roleName}
      className="pe-2"
    >
      {data.roleName}
    </option>
  ))}
</select>


                </div>
                <Button2
                  path={'/forget-password'}
                  button={'Forget Password?'}
                />
                <div className="text-center col-12">
                  {loading ? (
                    <Spinner
                      animation="border"
                      className="mt-4 mb-4"
                      variant="warning"
                    />
                  ) : (
                    <button
                      onClick={SignInAuth}
                      disabled={disable}
                      className={
                        disable
                          ? 'signin-btn-disable col-12 py-2 mb-4'
                          : 'signin-btn col-12 py-2 mb-4'
                      }
                    >
                      Login
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default SignInBS;
