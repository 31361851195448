import React, { useState } from 'react';
import Button from '../Components/Button';
// import "../styles/ForgetPassword.css";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import httpuserprofile from '../js/http-lms_userprofile';
// import Logo from '../images/careerTunerLogo.svg';
import passwordCheck from '../images/password_check1.png';
import passwordCross from '../images/password_cross.png';
import showPwdImg from '../images/showpwd.svg';
import hidePwdImg from '../images/hidepwd.svg';
import { ROUTES } from '../Constant/Routes';
import Navb from '../Components/Navbar';
// import itreion from '../images/itreion.svg'
import SEO from '../Constant/SEO.json';
import PageSeoComponent from '../SEO';

const Updatepass = () => {
  const [error, setError] = useState();
  const [pop, setPop] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  //password validations
  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [passwordCapitalLetterValid, setPasswordCapitalLetterValid] =
    useState(false);
  const [passwordNumberValid, setPasswordNumberValid] = useState(false);
  const [passwordSpecialCharValid, setPasswordSpecialCharValid] =
    useState(false);
  const [isRevealPwd1, setIsRevealPwd1] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);

  function handleClick() {
    navigate(ROUTES.HOME);
  }
  useEffect(() => {
    if (
      confirmPassword.length > 0 &&
      newPassword === confirmPassword &&
      passwordLengthValid &&
      passwordCapitalLetterValid &&
      passwordNumberValid &&
      passwordSpecialCharValid
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [confirmPassword]);

  async function changePass(e) {
    e.preventDefault();
    setLoading(true);
    httpuserprofile
      .put('/userloginLMS/updatePwdForgot', {
        userEmail: localStorage.getItem('userEmail'),
        passwordSalt: newPassword,
        authCode: 'KRDREBM390',
      })
      .then((response) => {
        setLoading(false);
        console.log('change password : ', response);
        if (response.data === 'Password Updated') {
          setError();
          setPop(true);
          setTimeout(() => {
            navigate(ROUTES.LOGIN);
          }, 1600);
        } else {
          setError(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function passChange(e) {
    console.log('on cnage pass : ', e.target.value);
    setNewPassword(e.target.value);
    let format =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    let hasNumber = /\d/;
    let hasSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (newPassword.length != 0) {
      if (newPassword.length >= 8 && newPassword.length <= 16) {
        setPasswordLengthValid(true);
      } else {
        setPasswordLengthValid(false);
      }
      setPasswordNumberValid(hasNumber.test(newPassword));
      setPasswordCapitalLetterValid(/[A-Z]/.test(newPassword));
      setPasswordSpecialCharValid(hasSpecialChar.test(newPassword));
      if (format.test(newPassword)) {
        setError();
      } else {
        setError(
          'Minimum 8 Characters, 1 Special character, 1 Capital Letter and 1 Number'
        );
      }
    } else {
      setPasswordLengthValid(false);
      setError();
    }
  }

  function confirmChange(e) {
    setConfirmPassword(e.target.value);
  }

  const navigate = useNavigate();

  return (
    <>
      <PageSeoComponent {...SEO['update-password']} />
      {/* <Navb class="d-flex justify-content-between zi100 py-2 w-100 greybg px-5" /> */}
      {pop && (
        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
          <div className=" d-flex flex-column justify-content-center align-items-center P-Update-Success-2 ">
            <div>
              <img
                src={require('../images/Tick.png')}
                alt="success"
                className="img-fluid"
              />
            </div>
            <p className="text-center w213 ">
              Sucessfully your password has been updated
            </p>
          </div>
        </div>
      )}

      <div className="row d-flex align-items-center justify-content-center ">
        <div className="col-md-5 col-12 m-md-0  h-100 justify-content-center bgwhite rounded-1 d-flex">
          {/* <div className='col-lg-6 signin-image'></div> */}
          <div className="W-100 pt-5 pb-5 d-flex flex-column">
            <img
              onClick={handleClick}
              width="15px"
              className="backarrow text-left"
              src={require('../images/leftarrow.png')}
              alt="Image not found"
            />
            {/* <img src={require('../images/Logo.png')} alt="logo" className='mx-auto d-block d-md-none' />
          <img src={Logo} alt="logo" className='d-none d-md-block d-lg-none mx-auto'/> */}
            <div className="col-12">
              <div className="logo text-center">
                <img src={require('../images/lock.png')} alt="back" />
              </div>
              <div className="col-md-10 mx-auto">
                <p className="forgetpass-heading">Forget Password</p>
                <p className="font12 black60 mt-2">
                  Your new password must be different from previous used
                  password
                </p>
                <div className="mt-4 mb-3">
                  <form onSubmit={changePass}>
                    <div>
                      <p className="e-heading">New Password</p>
                      {/* <Input change={passChange} type="password" name="Npass" placeholder="" /> */}
                      <div className="pwd-container">
                        <input
                          name="Npass"
                          placeholder="Enter New Password"
                          maxLength="16"
                          type={isRevealPwd1 ? 'text' : 'password'}
                          value={newPassword}
                          onChange={passChange}
                        />
                        <img
                          title={
                            isRevealPwd1 ? 'Hide password' : 'Show password'
                          }
                          src={isRevealPwd1 ? hidePwdImg : showPwdImg}
                          onClick={() =>
                            setIsRevealPwd1((prevState) => !prevState)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <p className="e-heading">Confirm Password</p>
                      {/* <Input change={confirmChange} type="password" name="Cpass" placeholder="" /> */}
                      <div className="pwd-container">
                        <input
                          name="Cpass"
                          placeholder="Enter Confirm Password"
                          maxLength="16"
                          type={isRevealPwd2 ? 'text' : 'password'}
                          value={confirmPassword}
                          onChange={confirmChange}
                        />
                        <img
                          title={
                            isRevealPwd2 ? 'Hide password' : 'Show password'
                          }
                          src={isRevealPwd2 ? hidePwdImg : showPwdImg}
                          onClick={() =>
                            setIsRevealPwd2((prevState) => !prevState)
                          }
                        />
                      </div>
                    </div>
                    <div className="height15">
                      {error && <div className="errMsg">{error}</div>}
                      {disable &&
                        confirmPassword.length > 0 &&
                        newPassword.length > 0 && (
                          <div className="errMsg">
                            Confirm password should match with new password
                          </div>
                        )}
                    </div>
                    <div>
                      <div className="by-clicking-get-otp proximanova-semibold text-left pl-0 col-11 mt-3">
                        Password requirements :{' '}
                      </div>
                      <div className="d-flex mt-1 ml-2">
                        <img
                          title={passwordLengthValid ? 'valid' : 'invalid'}
                          width="20"
                          height="18"
                          src={
                            passwordLengthValid ? passwordCheck : passwordCross
                          }
                        />
                        <div className="font12 ml-1">
                          At least 8 to 16 character(s)
                        </div>
                      </div>
                      <div className="d-flex mt-1 ml-2">
                        <img
                          title={
                            passwordCapitalLetterValid ? 'valid' : 'invalid'
                          }
                          width="20"
                          height="18"
                          src={
                            passwordCapitalLetterValid
                              ? passwordCheck
                              : passwordCross
                          }
                        />
                        <div className="font12 ml-1">
                          At least one Capital letter [A-Z]
                        </div>
                      </div>
                      <div className="d-flex mt-1 ml-2">
                        <img
                          title={passwordNumberValid ? 'valid' : 'invalid'}
                          width="20"
                          height="18"
                          src={
                            passwordNumberValid ? passwordCheck : passwordCross
                          }
                        />
                        <div className="font12 ml-1">
                          At least one Number [0-9]
                        </div>
                      </div>
                      <div className="d-flex mt-1 ml-2">
                        <img
                          title={passwordSpecialCharValid ? 'valid' : 'invalid'}
                          width="20"
                          height="18"
                          src={
                            passwordSpecialCharValid
                              ? passwordCheck
                              : passwordCross
                          }
                        />
                        <div className="font12 ml-1">
                          At least one Special character [!@#$%^&* etc]
                        </div>
                      </div>
                    </div>
                    <div className="mx-auto text-center col-12">
                      {loading ? (
                        <Spinner
                          animation="border"
                          className="text-center mt-4 mb-2"
                          variant="warning"
                        />
                      ) : (
                        <Button
                          disable={disable}
                          class={
                            disable
                              ? 'signin-btn-disable col-12 py-2 mb-4'
                              : 'update-pass-btn'
                          }
                          button="Update"
                        />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Updatepass;
