import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import PageSeoComponent from '../SEO';
import SEO from '../Constant/SEO.json';

function Property({ name, location, price }) {
  return (
    <div className="property">
      <h3>{name}</h3>
      <p>{location}</p>
      <p>{price}</p>
    </div>
  );
}

const FindYourHome = () => {
  const [country, setCountry] = useState('');
  const [properties, setProperties] = useState([]);

  // mock property data
  const mockProperties = [
    { id: 1, name: 'Property 1', location: 'Location 1', price: '$1000' },
    { id: 2, name: 'Property 2', location: 'Location 2', price: '$2000' },
    { id: 3, name: 'Property 3', location: 'Location 3', price: '$3000' },
  ];

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get('http://localhost:8088/vi/getPropertiesAddress/PropertyAddress');
  //       console.log(response.data, "Data")
  //       setData(response.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchData()
  // }, []);

  const filteredProperties = country
    ? mockProperties.filter((p) => p.location === country)
    : [];

  return (
    <>
      <PageSeoComponent {...SEO['find-your-home']} />

      {/* <Navbar class="position-fixed d-flex justify-content-between zi100 py-2 w-100 greybg px-5" /> */}

      <div className="">
        <img
          src={require('../images/fyh.jpg')}
          style={{ height: '600px' }}
          alt=""
          className="w-100"
        />
      </div>

      <div className="app">
        <h1>Real Estate Properties</h1>

        <div className="my-3">
          <select
            className="country-select"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            <option value="">Select a Country</option>
            <option value="Location 1">Location 1</option>
            <option value="Location 2">Location 2</option>
            <option value="Location 3">Location 3</option>
          </select>
        </div>

        {filteredProperties.map((property) => (
          <Property
            key={property.id}
            name={property.name}
            location={property.location}
            price={property.price}
          />
        ))}
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default FindYourHome;
