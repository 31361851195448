import React, { useEffect, useState } from 'react';
import {Container, Form, Row, Col, Button } from 'react-bootstrap';
import Navb from '../../Components/Navbar';
import httpproperties from '../../js/http-realestate-properties';

import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import httpcontactus from '../../js/http-lms_contactus';
import { ROUTES } from '../../Constant/Routes';
import PageSeoComponent from '../../SEO';
import SEO from '../../Constant/SEO.json'
// import axios from 'axios';

const CheckRegisterUsers = () => {

    const [error, setError] = useState([]);
    const [registeredUsers, setRegisteredUsers] = useState([]);
  

    const navigate = useNavigate();
    const location = useLocation();
    const selectedEvent = location.state.selectedEvent;
    // const locationId = location.state.selectedEvent.locationId


    useEffect(() => {
        viewRegisteredUsers(location.state.selectedEvent.locationId)

        console.log(selectedEvent,"lplplpplp")
         }, []);


        

    const viewRegisteredUsers = (locationId) => {
        httpcontactus
        .get(`/eventDetails/getUsersListByEventLocation?locationId=${locationId}`)
        .then((response) => {
          if (response.data === 'Record not found') {
            setError(response.data);
          } else {
            console.log('fetch event list : ', response);
            setRegisteredUsers(response.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      };

  function navToPrevRoute() {
    navigate(ROUTES.MY_EVENTS);
  }




  return (

    <>

<PageSeoComponent {...SEO['register-event']} />


<Container className="pt-5">
        <div className="my-models-title d-flex justify-content-between mb-4 mt-5">
          <div className="d-flex">
            <div
              className=" d-flex align-items-center mt-4 pointer"
              onClick={navToPrevRoute}
            >
              <img
                className="pe-3 backarrow"
                src={require('../../images/leftarrow.png')}
                alt="back"
              />
              <h4 className="SectionHeadings mt-2">Event List</h4>
            </div>
          </div>
          <div className="mt-4 ">
            {/* <Button onClick={addEvent}>Add Event</Button> */}
          </div>
        </div>
        <div className="container mb-5">
          {/* <Form.Control
            type="text"
            placeholder="Search Model"
            className=" w-25 mb-3 "
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          /> */}

{/* <div className="row">
        {event.map((event, index) => (
          <div key={index} className="col-md-4">
            <div className="card shadow" style={{ maxWidth: '300px', marginTop: '20px' }}>
              <div className="card-body">
                
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-3">{event.eventName}</h5>
                  <Dropdown drop={'down-centered'} align={{ lg: 'start' }}>
                    <Dropdown.Toggle
                      className="dropdown-more p-0"
                      variant="success"
                      title="more actions"
                      id="dropdown-basic"
                    >
                      <img
                        src={require('../../images/more.png')}
                        alt="More actions"
                        className="more"
                        style={{ width: '20px', height: '20px' }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          handleUpdate(event);
                          setShowActiveModal(true);
                        }}
                      >
                        Update Event
                      </Dropdown.Item>
                      <Dropdown.Item
                      onClick={() => openDeleteModal(event)}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

              
                <h6><strong>Date: </strong> {event.eventDate} <br /></h6>
                <h6 className="my-3"><strong>Time: </strong> {event.startTime} - {event.endTime} <br /></h6>
                <h6><strong>Venue: </strong> {event.venue} <br /></h6>
                <h6 className="mt-3"><strong>Location: </strong> {event.cityName}, {event.stateName}, {event.countryName}</h6>
              </div>
            </div>
          </div>
        ))}
      </div> */}

        </div>



        <div className="scrollme mb-5">
        <table className="table bgwhite">
          <thead>
            <tr>
            <th scope="col">Event Name</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Comment</th>
              <th scope="col">Event Description</th>
              {/* <th scope="col">Location</th>
              <th scope="col">More</th> */}
              {/* <th scope="col">Remove</th> */}
            </tr>
          </thead>
          <tbody>
          {registeredUsers.map((data, index) => {
  return (
    <tr key={index}>
        <td scope="row">{data.eventName}</td>
      <td scope="row">{data.firstName}</td>
      <td scope="row">{data.lastName}</td>
      <td>{data.email}</td>
      <td>{data.phoneNumber}</td>
      <td>{data.comment}</td>
      <td>{data.eventDescription}</td>
      {/* <td>
       
      </td> */}
    </tr>
  );
})}

          </tbody>
        </table>
      </div>




       

      </Container>

    
    </>
 
  );
};

export default CheckRegisterUsers;
