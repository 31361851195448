import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Navb from "../Components/Navbar";
import { useNavigate } from "react-router-dom";
import httpproperties from "../js/http-realestate-properties";
import { ROUTES } from '../Constant/Routes';
// import { Helmet } from "react-helmet";


const AddModelPage = () => {
  const [property, setProperty] = useState({
    modelTitle: "",
    price: "",
    modelArea: "",
    noOfBedrooms: "",
    noOfBaths: "",
    noOfHalfbaths: "",
    noOfStories: "",
    noOfGarages: "",
    modelDescription: "",
    homeDesignHighlights: "",
    creationD: null,
    creationUserI: sessionStorage.getItem("userEmail"),
    updatedUserI: "",
    profileId: sessionStorage.getItem("profileId"),
  });
  const [formErros, setFormErrors] = useState({});
  const [ER, setER] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [imgList, setImgList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [pop, setPop] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [propertyId, setPropertyId] = useState();
  const [selectedBrochurePdf, setSelectedBrochurePdf] = useState(null);
  const [selectedFloorPdf, setSelectedFloorPdf] = useState(null);
  const [imgErrorMessage, setImgErrorMessage] = useState();
  const [brochureErrorMessage, setBrochureErrorMessage] = useState();
  const [floorErrorMessage, setFloorErrorMessage] = useState();
  const [vtourErrorMessage, setVtourErrorMessage] = useState();
  const[Vtour, setVtour] = useState()

  
  const navigate = useNavigate();
  function navToPrevRoute() {
    navigate(ROUTES.MY_MODELS);
  }
  const handleChange = (event) => {
    setErrorMessage("");
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setProperty((prevProperty) => ({
      ...prevProperty,
      [name]: fieldValue,
    }));
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const getImgList = () => {
    httpproperties
      .get("/propertyManagement/getImageTypeList")
      .then((result) => {
        const filteredData = result.data.filter(
          (item) => ![9, 10, 12, 17].includes(item.id)
        );
        console.log(filteredData, "Filtered ImgList");
        setImgList(filteredData);
      })
      .catch((err) => {
        console.log(err, "ERRRR");
      });
  };


  const handleBrochureChange = (event) => {
    const file = event.target.files[0];
    setSelectedBrochurePdf(file);
    setBrochureErrorMessage("");
  };

  const handleFloorChange = (event) => {
    const floorfile = event.target.files[0];
    setSelectedFloorPdf(floorfile);
    setFloorErrorMessage("");
  };


  const handleVtourChange = (event) => {
    setVtour(event.target.value);
    console.log(Vtour, "TOURRRRR")
    setVtourErrorMessage("");
  };


  const fetchPropertyList = () => {
    httpproperties
      .get(
        "/propertyManagement/getPropertyIdAndTitleList"
      )
      .then((result) => {
        console.log(result.data, "PropList");
        setPropertyList(Array.isArray(result.data) ? result.data : []);
      })
      .catch((err) => {
        console.log(err, "ERRRR");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(validate(property));


    if (Object.keys(validate(property)).length !== 0 || imageList.length === 0 || !selectedBrochurePdf || !selectedFloorPdf || !Vtour)  {
      console.log("final submission : ", property);
    
      const propertyErrors = validate(property);
      const hasPropertyErrors = Object.keys(propertyErrors).length !== 0;
      const hasNoImages = imageList.length === 0;
      const hasNoBrochure = !selectedBrochurePdf;
      const hasNoFloorPlan = !selectedFloorPdf;
      const hasNoVtour = !Vtour;
    
      if (hasPropertyErrors) {
        setFormErrors(propertyErrors);
      } else {
        setFormErrors({});
      }
    
      if (hasNoImages) {
        setImgErrorMessage("Please add at least one image.");
      } else {
        setImgErrorMessage("");
      }
      if (hasNoBrochure) {
        setBrochureErrorMessage("Please add brochure.");
      } else {
        setBrochureErrorMessage("");
      }
    
      if (hasNoFloorPlan) {
        setFloorErrorMessage("Please add floor plan.");
      } else {
        setFloorErrorMessage("");
      }
      if (hasNoVtour) {
        setVtourErrorMessage("Please add virtual tour.");
      } else {
        setVtourErrorMessage("");
      }
    } else {
      httpproperties
        .post(
          `/modelInformation/insertmodelinformation?propertyId=${propertyId}`,
          JSON.stringify(property)
        )
        .then((result) => {
          if (result.data) {
            setPop(true);
            console.log(result.data, "OKOKOKOKOK");
            setProperty({
              modelTitle: "",
              price: "",
              modelArea: "",
              noOfBedrooms: "",
              noOfBaths: "",
              noOfHalfbaths: "",
              noOfStories: "",
              noOfGarages: "",
              modelDescription: "",
              homeDesignHighlights: "",
              creationD: null,
              creationUserI: sessionStorage.getItem("userEmail"),
              profileId: sessionStorage.getItem("profileId"),
            });
            setTimeout(() => {
              setPop(false);
            }, 1500);

            const brochureInput = document.getElementById("brochureFile");
            const floorInput = document.getElementById("floorFile");
            brochureInput.value = null;
            floorInput.value = null;
    
            saveImg(result.data.id);
            saveBrochurePdf(result.data.id);
            saveFloorPdf(result.data.id);
            saveVtour(result.data.id);
            setSelectedBrochurePdf(null);
            setSelectedFloorPdf(null);
            setFormErrors("");
            setPropertyList([]);
            setImageList([]);
            setVtour("")
          } else {
            console.log("ERR");
          }
        })
        .catch((error) => {
          // Handle error
          console.log(error.result.data);
          setER(error.result.data);
        });
    }
  };

  
  

  const validate = (values) => {
    const errors = {};
    if (!propertyId) {
      errors.propertyId = "Property Type is required!";
    }
    if (!values.modelTitle) {
      errors.modelTitle = "Model Title is required!";
    }
    if (!values.price) {
      errors.price = "Price is required!";
    }
    if (!values.modelArea) {
      errors.modelArea = "Area is required!";
    }
    if (!values.noOfBedrooms) {
      errors.noOfBedrooms = "No.of bedrooms is required!";
    }
    if (!values.noOfBaths) {
      errors.noOfBaths = "No.of Baths is required!";
    }
    if (!values.noOfHalfbaths) {
      errors.noOfHalfbaths = "No.of Halfbaths is required!";
    }
    if (!values.noOfStories) {
      errors.noOfStories = "No.of Stories is required!";
    }
    if (!values.noOfGarages) {
      errors.noOfGarages = "No.of Garages is required!";
    }
    if (!values.modelDescription) {
      errors.modelDescription = "Model Description is required!";
    }
    if (!values.homeDesignHighlights) {
      errors.homeDesignHighlights = "Home Design Highlights are required!";
    }
    console.log("before return errors : ", errors);
    console.log("before return errors : ", property);
    return errors;
  };

  const handleAddToList = () => {
    let type = imgList.filter((x) => x.id==selectedType);

    console.log(imgList[0].id, selectedType, type, 'tttttttttt');
    if (selectedType && selectedImage) {
      const newImage = {
        type: type?.[0]?.imgType ?? "",
        image: selectedImage,
        id: type?.[0]?.id ?? ""
      };
      setImageList([...imageList, newImage]);
      setSelectedType("");
      setSelectedImage(null);
      setImgErrorMessage("");
      const fileInput = document.getElementById("modelImage");
      fileInput.value = null;
    }
  };


  const saveImg = async (mId) => {
    try {
      for (const x of imageList) {
        const formData = new FormData();
        formData.append("propertyOrModelImages", x.image);
  
        const config = {
          method: "post",
          url: `/propertyManagement/uploadPropertyImages?userProfileId=${sessionStorage.getItem(
            "profileId"
          )}&propertyId=&imageTypeId=${
            x.id
          }&imageType=property&modelId=${mId}&creationUserI=${sessionStorage.getItem(
            "userEmail"
          )}`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };
  
        try {
          const response = await httpproperties(config); 
  
          // Handle the response data if needed
          console.log(response.data);
        } catch (error) {
          // Handle errors if needed
          console.error(error);
        }
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };


  const saveBrochurePdf = async (mId) => {
    try {
  
        const formData = new FormData();
        formData.append("propertyOrModelImages", selectedBrochurePdf);
  
        const config = {
          method: "post",
          url: `/propertyManagement/uploadPropertyImages?userProfileId=${sessionStorage.getItem(
            "profileId"
          )}&propertyId=&imageTypeId=
            17
          &imageType=property&modelId=${mId}&creationUserI=${sessionStorage.getItem(
            "userEmail"
          )}`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };
  
        try {
          const response = await httpproperties(config); // Using httpproperties instead of axios
  
          // Handle the response data if needed
          console.log(response.data);
        } catch (error) {
          // Handle errors if needed
          console.error(error);
        }
  
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };






  const saveFloorPdf = async (mId) => {
    try {
  
        const formData = new FormData();
        formData.append("propertyOrModelImages", selectedFloorPdf);
  
        const config = {
          method: "post",
          url: `/propertyManagement/uploadPropertyImages?userProfileId=${sessionStorage.getItem(
            "profileId"
          )}&propertyId=&imageTypeId=
            12
          &imageType=property&modelId=${mId}&creationUserI=${sessionStorage.getItem(
            "userEmail"
          )}`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };
  
        try {
          const response = await httpproperties(config); // Using httpproperties instead of axios
  
          // Handle the response data if needed
          console.log(response.data);
        } catch (error) {
          // Handle errors if needed
          console.error(error);
        }
  
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };


  const saveVtour = async (mId) => {
    try {
      const formData = new FormData();
      formData.append("srcVideoLink", Vtour); // Vtour is explicitly treated as text
  
      const config = {
        method: "post",
        url: `/propertyManagement/uploadPropertyImages?userProfileId=${sessionStorage.getItem(
          "profileId"
        )}&propertyId=&imageTypeId=9&imageType=property&modelId=${mId}&creationUserI=${sessionStorage.getItem(
          "userEmail"
        )}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
  
      try {
        const response = await httpproperties(config); // Using httpproperties instead of axios
  
        // Handle the response data if needed
        console.log(response.data);
      } catch (error) {
        // Handle errors if needed
        console.error(error);
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };







  const handleRemoveFromList = (indexToRemove) => {
    setImageList((prevImageList) => {
      const newList = [...prevImageList];
      newList.splice(indexToRemove, 1);
      return newList;
    });
    setSelectedType("");
    const fileInput = document.getElementById("modelImage");
    fileInput.value = null;
  };

  useEffect(() => {
    fetchPropertyList();

    getImgList();

   



  }, [formErros]);

  const handleFileEvent = (event) => {
    const files = event.target.files;
    setProperty((prevProperty) => ({
      ...prevProperty,
      modelImage: files[0],
    }));
  };

 
  
  return (
    <>
{/* <Helmet>
        <title>Add Model</title>
        <meta
          name="description"
          content="Easiest way to find your dream home"
        />
        <meta name="keywords" content="keywords, separated, by, commas" />
        <link rel="canonical" href="https://www.example.com/page" />
      </Helmet> */}



      {pop && (
        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
          <div className=" d-flex flex-column justify-content-center align-items-center P-Update-Success-2 ">
            <div>
              <img
                src={require("../images/Tick.png")}
                alt="success"
                className="img-fluid"
              />
            </div>
            <p className="text-center w213 ">
              Sucessfully model has been added
            </p>
          </div>
        </div>
      )}

      {/* <Navb isDefault={true} class="d-flex justify-content-between zi100 py-2 w-100 greybg px-5 position-fixed" /> */}

      <div className="pt-5 p-1 p-md-5">
        <div className="col-lg-12 d-flex add-property-title-wrap">
          <div
            className=" d-flex align-items-center mt-5 pt-4 pointer"
            onClick={navToPrevRoute}
          >
            <img
              className="pe-3 backarrow"
              src={require("../images/leftarrow.png")}
              alt="back"
            />
            <h4 className="SectionHeadings mt-2">Add Model</h4>
          </div>
        </div>
        <Form onSubmit={handleSubmit} className="" id="add-property">
          <div className={"font14 green text-center pb-4"}>{errorMessage}</div>
          <h1 className="SectionHeadings ms-2">Model Information: </h1>
          <div className="d-flex flex-wrap col-12 mt-4">
            <Form.Group
              className="col-12 col-md-6 col-lg-4 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="modelTitle"
            >
              <Form.Label className="required form-label">
                Model Title
              </Form.Label>
              <Form.Control
                type="text"
                name="modelTitle"
                value={property.modelTitle}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.modelTitle}</p>
            </Form.Group>
            <Form.Group
              className="col-12 col-md-6 col-lg-4 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="price"
            >
              <Form.Label className="required form-label">Price($)</Form.Label>
              <Form.Control
                type="text"
                name="price"
                value={property.price}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.price}</p>
            </Form.Group>
            <Form.Group
              className="col-12 col-md-6 col-lg-4 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="modelArea"
            >
              <Form.Label className="required">Model Area(sq.ft)</Form.Label>
              <Form.Control
                type="text"
                name="modelArea"
                value={property.modelArea}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.modelArea}</p>
            </Form.Group>
            <Form.Group
              className="col-12 col-md-6 col-lg-4 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="noOfBedrooms"
            >
              <Form.Label className="required">No. of Bedrooms</Form.Label>
              <Form.Control
                type="text"
                name="noOfBedrooms"
                value={property.noOfBedrooms}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.noOfBedrooms}</p>
            </Form.Group>
            {/* Add more form groups for other fields */}

            <Form.Group
              className="col-12 col-md-6 col-lg-4 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="noOfBaths"
            >
              <Form.Label className="required">No. of Baths</Form.Label>
              <Form.Control
                type="text"
                name="noOfBaths"
                value={property.noOfBaths}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.noOfBaths}</p>
            </Form.Group>
            <Form.Group
              className="col-12 col-md-6 col-lg-4 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="noOfHalfbaths"
            >
              <Form.Label className="required">No. of Half Baths</Form.Label>
              <Form.Control
                type="text"
                name="noOfHalfbaths"
                value={property.noOfHalfbaths}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.noOfHalfbaths}</p>
            </Form.Group>
            <Form.Group
              className="col-12 col-md-6 col-lg-4 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="noOfStories"
            >
              <Form.Label className="required">No. of Stories</Form.Label>
              <Form.Control
                type="text"
                name="noOfStories"
                value={property.noOfStories}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.noOfStories}</p>
            </Form.Group>
            {/* Add more form groups for other fields */}

            <Form.Group
              className="col-12 col-md-6 col-lg-4 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="noOfGarages"
            >
              <Form.Label className="required">No. of Garages</Form.Label>
              <Form.Control
                type="text"
                name="noOfGarages"
                value={property.noOfGarages}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.noOfGarages}</p>
            </Form.Group>
          </div>

          <h2 className="SectionHeadings ms-2 mt-2">Other Information</h2>
          <div className="d-flex flex-column col-12 mt-4">
            <Form.Group
              className="col-12 col-md-10 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="modelDescription"
            >
              <Form.Label className="required">Model Description</Form.Label>
              <Form.Control
                type="text"
                name="modelDescription"
                as="textarea"
                rows={3}
                value={property.modelDescription}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.modelDescription}</p>
            </Form.Group>
            <Form.Group
              className="col-12 col-md-10 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="homeDesignHighlights"
            >
              <Form.Label className="required">
                Home Design Highlights
              </Form.Label>
              <Form.Control
                type="text"
                name="homeDesignHighlights"
                as="textarea"
                rows={3}
                value={property.homeDesignHighlights}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.homeDesignHighlights}</p>
            </Form.Group>
          </div>

          <h2 className="SectionHeadings ms-2 mt-2 required">Attach to property</h2>

          <Form.Group
            className="col-12 col-md-10 ps-1 pe-1 ps-md-2 pe-md-2"
            controlId="modelDescription"
          >
            <Form.Select
              onChange={(e) => setPropertyId(e.target.value)}
              name="propertyId"
              aria-label="Default select example"
            >
              <option value="">Select Property Type</option>
              {propertyList.map((property, index) => (
                <option value={property.property_id} key={property.property_id}>
                  {property.property_title}
                </option>
              ))}
            </Form.Select>
            <p className="red font12">{formErros.propertyId}</p>
          </Form.Group>


          <h2 className="SectionHeadings mt-3">Model Images</h2>
          
          <div className="d-flex flex-wrap col-12 mt-4">
            <Form.Group
              className="col-12 col-md-6 col-lg-3 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="imageType"
            >
              <Form.Label className="required form-label">
                Select Image Type
              </Form.Label>
              <Form.Select
                onChange={handleTypeChange}
                name="imageType"
                aria-label="Default select example"
                value={selectedType}
              >
                <option value="">Select Image Type</option>
                {imgList.map((image, index) => (
                  <option value={image.id} key={image.id}>
                    {image.imgType}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="col-12 col-md-6 col-lg-3 ps-1 pe-1 ps-md-2 pe-md-2"
              controlId="modelImage"
            >
              <Form.Label className="required form-label">
                Upload Image
              </Form.Label>
              <Form.Control type="file" multiple onChange={handleImageChange} />
            </Form.Group>
            <Button
              type="button"
              className="addtolistbtn ms-2"
              onClick={handleAddToList}
            >
              Add to the List
            </Button>
          </div>
          <div className={"font12 red ms-2 pb-1 pt-1"}>{imgErrorMessage}</div>

          <Row className="">
            <Col>
              {imageList.length === 0 ? (
                <p>No images added yet.</p>
              ) : (
                <div className="d-flex flex-wrap">
                  {imageList.map((image, index) => (
                    <div key={index} className="m-3">
                      <p>Type: {image.type}</p>
                      <img
                        src={URL.createObjectURL(image.image)}
                        alt={image.type}
                        style={{ width: "200px", height: "200px" }}
                      />

                      <div className="text-center mt-2">
                        <Button
                          variant="danger"
                          className="remove-button"
                          onClick={() => handleRemoveFromList(index)}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
            <h2 className="SectionHeadings mt-2">Upload Brochure/Specification</h2>
              
              <Form>
                <Row>
                  <Col md={4}>
                  <Form.Group controlId="pdfFile">
                <Form.Label className="mb-4">
                </Form.Label>
                <Form.Control
                  type="text"
                  name="pdfFile"
                  disabled
                  value="Brochure/Specification"
                />
                
              </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="brochureFile">
                      <Form.Label className="required form-label">Upload Brochure/Specification:</Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/pdf"
                        onChange={handleBrochureChange}
                      />
                    </Form.Group>

                    {selectedBrochurePdf && (
        <div className="ms-2">
          <p>{selectedBrochurePdf.name}</p>
        </div>
      )}
                  </Col>
                </Row>
              </Form>
              <div className={"font12 red ms-2 pb-1 pt-1"}>{brochureErrorMessage}</div>
            </Col>
          </Row>



          <Row className="mt-2">
            <Col>
            <h2 className="SectionHeadings mt-2">Upload Floor Plan</h2>
              
              <Form>
                <Row>
                  <Col md={4}>
                  <Form.Group controlId="pdfFile">
                <Form.Label className="mb-4">
                </Form.Label>
                <Form.Control
                  type="text"
                  name="pdfFile"
                  disabled
                  value="Floor Plan"
                />
                
              </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="floorFile">
                      <Form.Label className="required form-label">Upload Floor Plan</Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/pdf"
                        onChange={handleFloorChange}
                      />
                    </Form.Group>

                    {selectedFloorPdf && (
        <div className="ms-2">
          <p>{selectedFloorPdf.name}</p>
        </div>
      )}
                  </Col>
                </Row>
              </Form>
              <div className={"font12 red ms-2 pb-1 pt-1"}>{floorErrorMessage}</div>
            </Col>
          </Row>



          <Row className="mt-2">
            <Col>
            <h2 className="SectionHeadings mt-2">Upload Virtual Tour</h2>
              <Form>
                <Row>
                  <Col md={4}>
                  <Form.Group controlId="pdfFile">
                <Form.Label className="mb-4">
                </Form.Label>
                <Form.Control
                  type="text"
                  name="src"
                  disabled
                  value="Virtual Tour"
                />
                
              </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="imageFile">
                      <Form.Label className="required form-label">Upload Virtual Tour</Form.Label>
                      <Form.Control
                        type="text"
                        name="vTour"
                        value={Vtour}
                        onChange={handleVtourChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <div className={"font12 red ms-2 pb-1 pt-1"}>{vtourErrorMessage}</div>
            </Col>
          </Row>

          <div className="d-flex col-12 col-lg-8 mt-4 ps-2">
            <Button type="submit" className="col-5 col-md-3 col-lg-3">
              Add Model
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddModelPage;