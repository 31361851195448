import React from 'react';
import { useNavigate } from 'react-router-dom';
// import Footer from '../Components/Footer';
// import Navbar from '../Components/Navbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
// import { Helmet } from 'react-helmet';
import 'swiper/swiper-bundle.css';
import { ReactComponent as SliderArrow } from '../images/slider-next-arrow.svg';
import { ReactComponent as ExporeArrow } from '../images/expore-arrow.svg';
import { ROUTES } from '../Constant/Routes';
import { Link } from 'react-router-dom';
import PageSeoComponent from '../SEO';
import SEO from '../Constant/SEO.json';
import ScrollToTopButton from '../Components/ScrollToTop';

const AboutUs = () => {
  const navigate = useNavigate();

  function projects() {
    navigate(ROUTES.PROJECTS);
  }

  function showmodels() {
    navigate(ROUTES.SHOW_MODELS);
  }

  function specifications() {
    navigate(ROUTES.SPECIFICATIONS);
  }

  return (
    <>
      <PageSeoComponent {...SEO['about-us']} />

      {/* <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Discover the journey and mission of KR Real Estate Developers LLC, leaders in luxury real estate development in Cumming, GA. Learn about our history, values, and why we are the top choice for luxury homes."
        />
        <meta
          name="keywords"
          content="single family home, luxury homes, custom homes, luxury custom homes, Suwanee Preserves, Cumming custom luxury homes, Sky Lane, new homes in Cumming, new custom luxury homes in Cumming, Luxury Homes Cumming GA, Luxury Real Estate Cumming Georgia, High-End Homes Cumming GA, Luxury Home Builders Cumming GA, New Luxury Homes in Cumming GA, luxury homes for sale Cumming GA, custom luxury homes Cumming GA, Luxury Homes Forsyth County GA, upscale homes Cumming Georgia, premium properties Cumming GA, luxury residences Cumming GA, gated community homes Cumming GA, luxury lakefront homes Cumming GA, modern luxury homes Cumming GA, executive homes Cumming GA, luxury living Cumming GA, top luxury home builders Cumming GA, exclusive homes Cumming GA, luxury home communities Cumming GA, luxury home listings Cumming GA, custom home construction Georgia, custom luxury homes Georgia, high-end custom homes Georgia, luxury custom houses for sale Georgia, new custom home developments Georgia, custom homes Forsyth County, high-end custom real estate Georgia, premium custom homes Georgia, custom residences Georgia, custom gated community homes Georgia, custom luxury lakefront homes Georgia, executive custom homes Georgia, top custom home builders Georgia, custom home communities Georgia, custom home listings Georgia"
        />
        <link rel="canonical" href="https://www.krhomes.net/about-us" />
      </Helmet> */}

      {/* <Navbar class="position-fixed d-flex justify-content-between zi100 py-2 w-100 greybg px-5" /> */}.


      <div className="" style={{ position: 'relative', height: '100%' }}>
        <img
            src={require('../images/aboutBanner.png')}
          alt="Background"
          className="background-propertydesc-image"
          style={{ marginTop: '110px' }}
        />

        {/* <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: '10',
          }}
        >
          <h1>Project Specification</h1>
        </div> */}
      </div>

      {/* <div className="about-banner position-relative">
        <img
          className="d-block w-100"
          src={require('../images/aboutBanner.png')}
          alt="Banner image"
          style={{ maxHeight: '100vh', objectFit: 'cover', width: '100%' }}
        />
        <div className="position-absolute top-40 left-10 start-5 translate-middle-y text-white">
          <h1
            className="text-start playfair font54 mb-0"
            style={{ maxWidth: '550px' }}
          >
            About KR Real Estate
          </h1>
        </div>
      </div> */}

      <div className="our-journey">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>Our Journey</h2>
              <p>
                Since our inception in 2003, KR Real Estate Developers LLC has
                been at the forefront of transforming landscapes into vibrant
                communities. With a robust portfolio that spans both residential
                and commercial sectors, we've dedicated ourselves to crafting
                spaces that inspire.
              </p>

              <p>
                Our journey is marked by a steadfast commitment to excellence,
                innovation, and a deep understanding of the intricacies of real
                estate development. By seamlessly integrating modern design with
                functional living, we create foundations for generations,
                building more than just structures—we build dreams and futures.
              </p>
              <div className="our-journey-info">
                <div className="row">
                  <div className="col-md-4">
                    <div className="our-journey-list">
                      <span>200+</span>
                      Happy Customers
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="our-journey-list">
                      <span>10k+</span>
                      Properties For Clients
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="our-journey-list">
                      <span>10+</span>
                      Years of Experience
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={require('../images/our-journey.jpg')}
                width={810}
                height={550}
                alt="our journey"
                className="rounded-3"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="why-kr-homes">
        <div className="container">
          <h2>Why KR Homes?​</h2>
          <div className="row">
            <div className="col-lg-4">
              <div className="why-kr-homes-img">
                <img
                  src={require('../images/governance-and-ethics.jpg')}
                  width={530}
                  height={350}
                  alt="governance-and-ethics"
                  className="rounded-3"
                />
              </div>
              <h5>Governance and Ethics</h5>
              <p>
                KR Homes prioritizes prime location selection, ensuring each
                development contributes positively to its surroundings. Strong
                business ethics, integrity, and compliance form the cornerstone
                of our operations, guiding our decision-making and
                interactions.​
              </p>
            </div>

            <div className="col-lg-4">
              <div className="why-kr-homes-img">
                <img
                  src={require('../images/experienced-builders.jpg')}
                  width={530}
                  height={350}
                  alt="Experienced Builders"
                  className="rounded-3"
                />
              </div>
              <h5>Experienced Builders</h5>
              <p>
                Our team comprises industry veterans with a proven track record
                in delivering high-quality homes. This experience ensures each
                project meets our stringent standards for craftsmanship and
                design.
              </p>
            </div>

            <div className="col-lg-4">
              <div className="why-kr-homes-img">
                <img
                  src={require('../images/unique-investment-opportunities.jpg')}
                  width={530}
                  height={350}
                  alt="Unique Investment Opportunities​"
                  className="rounded-3"
                />
              </div>
              <h5>Unique Investment Opportunities​​</h5>
              <p>
                Offering diverse investment classes (A, B, C) with varying
                benefits, including annual fixed rates of return with bonuses,
                KR Homes presents lucrative opportunities for investors seeking
                stability and growth in the real estate market.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="project-timeline">
        <div className="container">
          <div className="project-timeline-title d-flex justify-content-between align-items-center">
            <h2>Project Timeline</h2>
            <div className="slider-actions">
              <button
                type="button"
                className="slider-action-arrow slider-action-arrow--left timeline-arrow-left"
                aria-label="prev arrow"
              >
                <SliderArrow />
              </button>

              <button
                type="button"
                className="slider-action-arrow slider-action-arrow--right timeline-arrow-right"
                aria-label="next arrow"
              >
                <SliderArrow />
              </button>
            </div>
          </div>
        </div>
        <div className="container timeline-container">
          <div>
            <Swiper
              spaceBetween={30}
              slidesPerView={'auto'}
              navigation={{
                nextEl: '.timeline-arrow-right',
                prevEl: '.timeline-arrow-left',
              }}
              modules={[Navigation]}
            >
              <SwiperSlide>
                <div className="timeline-count">
                  <span>01</span>
                  <div className="timeline-count-line"></div>
                </div>
                <h6>Suwanee Preserve</h6>
                <div className="timeline-date">June 2021​</div>
                <div className="timeline-desc">
                  The project begins with the strategic purchase of land,
                  setting the foundation for the Suwanee Preserve development.
                  <div>
                    <Link
                      to="../images/Preserve1.pdf"
                      target="_blank"
                      className="btn-primary-text"
                    >
                      Download PDF <ExporeArrow />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="timeline-count">
                  <span>02</span>
                  <div className="timeline-count-line"></div>
                </div>
                <h6>Suwanee Preserve</h6>
                <div className="timeline-date">October 2022​​</div>
                <div className="timeline-desc">
                  Successful completion of land rezoning, a crucial step in
                  aligning the development with local planning and zoning
                  requirements.
                  <div>
                    <Link
                      to="../images/Preserve1.pdf"
                      target="_blank"
                      className="btn-primary-text"
                    >
                      Download PDF <ExporeArrow />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="timeline-count">
                  <span>03</span>
                  <div className="timeline-count-line"></div>
                </div>
                <h6>Suwanee Preserve</h6>
                <div className="timeline-date">May 2023​​</div>
                <div className="timeline-desc">
                  Obtaining the land development permit marks a significant
                  milestone, allowing construction activities to commence.
                  <div>
                    <Link
                      to="../images/Preserve1.pdf"
                      target="_blank"
                      className="btn-primary-text"
                    >
                      Download PDF <ExporeArrow />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="timeline-count">
                  <span>04</span>
                  <div className="timeline-count-line"></div>
                </div>
                <h6>Suwanee Preserve</h6>
                <div className="timeline-date">Oct 23' - May 24'</div>
                <div className="timeline-desc">
                  This phase focuses on preparing the land for construction,
                  including grading, infrastructure development, and other
                  preparatory work.
                  <div>
                    <Link
                      to="../images/Preserve1.pdf"
                      target="_blank"
                      className="btn-primary-text"
                    >
                      Download PDF <ExporeArrow />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="timeline-count">
                  <span>05</span>
                  <div className="timeline-count-line"></div>
                </div>
                <h6>Suwanee Preserve</h6>
                <div className="timeline-date">Oct 23' - May 24'</div>
                <div className="timeline-desc">
                  The core phase of the project where house construction takes
                  place. This period also encompasses the marketing and sales
                  activities, culminating in the handover of homes to buyers.
                  <div>
                    <Link
                      to="../images/Preserve1.pdf"
                      target="_blank"
                      className="btn-primary-text"
                    >
                      Download PDF <ExporeArrow />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="founding-team">
        <div className="container">
          <h2>Founding Team & Key Professionals​</h2>
          <div className="founding-team-list">
            <div className="row">
              <div className="col-lg-6">
                <ul className="row team-list">
                  <li className="col-md-6">
                    <div className="team-list-img">
                      <img
                        src={require('../images/team-placeholder.png')}
                        width={390}
                        height={400}
                        alt="Team"
                        className="rounded-3"
                      />
                      <div className="team-member-name">Yugandhar Kummathi</div>
                    </div>
                    <h6>Partner</h6>
                    <p>
                      Responsible for day-to-day operations, overseeing
                      accounting & finances, coordinating with CPAs, county
                      engineers, contractors, & planning functions. Background
                      as a Design Engineer with L&T ECC & a consultant for L&T
                      Ramboll. Expertise in IT for Banking and Credit Cards,
                      holding a BTech in Civil Eng. from JNTU and an MTech from
                      NIIT.
                    </p>
                  </li>

                  <li className="col-md-6">
                    <div className="team-list-img">
                      <img
                        src={require('../images/team-placeholder.png')}
                        width={390}
                        height={400}
                        alt="Team"
                        className="rounded-3"
                      />
                      <div className="team-member-name">
                        Chennakesava Reddy Kummathi
                      </div>
                    </div>
                    <h6>Partner</h6>
                    <p>
                      focusing on investor relationships, customer relationship
                      management, and new property acquisitions. Brings a wealth
                      of experience in identifying and securing strategic real
                      estate opportunities.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="key-professionals">
                  <li>
                    <h5>Stephen Osley & Associates​</h5>
                    <p>
                      With over 700+ homes built, contributing to over $1
                      billion in turnover and involvement in more than 19
                      communities, Osley & Associates represents a 3rd
                      generation business continuing to thrive in the industry.
                    </p>
                  </li>

                  <li>
                    <h5>Thripura Vemireddy - TSR Properties</h5>
                    <p>
                      Since 2015, TSR Properties has been a licensed residential
                      contractor with a turnover of over $5 million. Thripura's
                      role as a realtor and flip investor adds valuable insight
                      and expertise to the development process.
                    </p>
                  </li>

                  <li>
                    <h5>KR Real Estate Developers & KR Homes</h5>
                    <p>
                      The primary entity behind the Suwanee Preserve, leading
                      the project with a focus on innovative land use and
                      development strategies. Their horizontal development
                      contractor, JC Prep, is known for grading and development
                      contributions to notable communities like Century
                      Communities, Taylor Morrison, and Loyd Development among
                      others.
                    </p>
                    <p>
                      Engineers and Designers: The project is supported by a
                      network of experienced attorneys, CPAs, architects,
                      engineering designers, and technology partners, ensuring
                      compliance, innovation, and quality across all development
                      phases.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="discover-more">
        <div className="container">
          <h2>Discover More About KR Real Estate​</h2>
          <div className="founding-team-list">
            <div className="row">
              <div className="col-lg-4">
                <div className="discover-more-img">
                  <img
                    src={require('../images/our-properties.png')}
                    width={530}
                    height={700}
                    alt="our-properties"
                    className="rounded-3"
                  />
                  <div className="discover-more-overlay"></div>
                  <h3>
                    Our <span>Properties</span>
                  </h3>
                  <button onClick={projects} className="explore-now-btn">
                    Explore Now <ExporeArrow />{' '}
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="discover-more-img">
                  <img
                    src={require('../images/our-models.png')}
                    width={530}
                    height={700}
                    alt="our-properties"
                    className="rounded-3"
                  />
                  <div className="discover-more-overlay"></div>
                  <h3>
                    Our <span>Models</span>
                  </h3>
                  <button onClick={showmodels} className="explore-now-btn">
                    Explore Now <ExporeArrow />{' '}
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="discover-more-img">
                  <img
                    src={require('../images/our-specifications.png')}
                    width={530}
                    height={700}
                    alt="our-properties"
                    className="rounded-3"
                  />
                  <div className="discover-more-overlay"></div>
                  <h3>
                    Our <span>Specifications</span>
                  </h3>
                  <button onClick={specifications} className="explore-now-btn">
                    Explore Now <ExporeArrow />{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScrollToTopButton />

      {/* <Footer /> */}
    </>
  );
};

export default AboutUs;
