import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import httpproperties from '../js/http-realestate-properties';
import { Carousel } from 'react-bootstrap';
import Footer from '../Components/Footer';
import ScrollToTopButton from '../Components/ScrollToTop';
import 'aos/dist/aos.css'; // Import AOS CSS
import Spinner from 'react-bootstrap/Spinner';
import { ROUTES } from '../Constant/Routes';
import Preserve1 from "../images/ModelImages/Preserve1/Preserve1"
import Preserve2 from "../images/ModelImages/Preserve2/Preserve2"
import Preserve3 from "../images/ModelImages/Preserve3/Preserve3"
import Model448 from "../images/ModelImages/Model448/Model448"
import Model39 from "../images/ModelImages/Model39/Model39"
import Model445 from "../images/ModelImages/Model445/Model445"
// import { Helmet } from 'react-helmet';
import AOS from 'aos';

const PropertyModels = () => {
  // const [models, setModels] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [expandedDescriptionIndex, setExpandedDescriptionIndex] =
    useState(null);
  const [loading, setLoading] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [modelTitle, setModelTitle] = useState('');
  const [modelArea, setModelArea] = useState('');
  const [noOfBedrooms, setNoOfBedrooms] = useState('');
  const [noOfBaths, setNoOfBaths] = useState('');
  const [noOfStories, setNoOfStories] = useState('');
  // const [location, setLocation] = useState('');

  const location = useLocation();
  const models = location.state.linkedModelsInfo;

// Sort the models in ascending order by a specific field, e.g., 'modelName'
const sortedModels = models.sort((a, b) => {
  // Replace 'modelName' with the field you want to sort by
  return a.modelTitle.localeCompare(b.modelTitle);
});

// You can then use `sortedModels` for further processing or rendering
console.log(sortedModels);

  const cardsPerPage = 12;

  const handlePageChange = (selectedItem) => {
    setCurrentPage(selectedItem.selected);
    window.scrollTo(0, 1300); // Scroll to top
  };

  const handleToggleDescription = (index) => {
    if (expandedDescriptionIndex === index) {
      setExpandedDescriptionIndex(null);
    } else {
      setExpandedDescriptionIndex(index);
    }
  };

  AOS.init({
    duration: 600, // Animation duration
    easing: 'ease-in-out', // Easing function
  });

  // const indexOfLastCard = (currentPage + 1) * cardsPerPage;
  // const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  // const currentCards = models.slice(indexOfFirstCard, indexOfLastCard);

  useEffect(() => {
    if (models.length === 0) {
      setLoading(1);
      setError('No records found');
    } else {
      setLoading(1); // You can set this to 0 or any other value that indicates not loading
      setError(null);
    }
  }, [models]);

  useEffect(() => {
    // getModelsList();
    console.log(models, 'okokokokokokokok');
  }, []);

  const navigate = useNavigate();

  function contactUs() {
    navigate(ROUTES.CONTACT_US);
  }

  function handleModelDesc(models) {
    navigate(ROUTES.MODEL_DESC, { state: { modelInfo: models } });
  }

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // function getModelsList() {

  //   setModelTitle("")
  //     setModelArea("")
  //     setNoOfBedrooms("")
  //     setNoOfBaths("")
  //     setNoOfStories("")
  //     setLocation("")

  //   httpproperties
  //     .get("/modelInformation/allmodelinformation")
  //     .then((response) => {
  //       if (response.data === "No Records Found") {
  //         setLoading(1)
  //         setError(response.data);
  //       } else {
  //         setLoading(1)
  //         console.log("fetch Model list: ", response);
  //         setModels(response.data);
  //       }
  //     })
  //     .catch((e) => {
  //       setLoading(1)
  //       console.log(e);
  //       setError("Error fetching models");
  //     });
  // }

  // const handleSearch = async () => {
  //   try {
  //     const response = await httpproperties.get('/modelInformation/getAllModelsListForUser', {
  //       params: {
  //         modelTitle,
  //         modelArea,
  //         noOfBedrooms,
  //         noOfBaths,
  //         noOfStories,
  //         location
  //       }
  //     });
  //     setLoading(1)
  //     if (response.data === "No records found") {
  //       setModels([]); // Set an empty array if no records are found
  //     } else {
  //       setModels(response.data);
  //     }
  //   } catch (error) {
  //     setLoading(1)
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // const firstImage =
  // models.imageSrcLink && models.imageSrcLink.length > 0
  //   ? models.imageSrcLink.find(image => (image.imgId!==9 && image.imgId!==12 && image.imgId!==17)).imageUrl
  //   : "";

  return (
    <div>
      {/* <Helmet>
        <title>Property-Models</title>
        <meta
          name="description"
          content="Explore our collection of luxury custom homes and find your dream property. Discover premium living in Cumming, Georgia with our diverse range of models."
        />
        <meta
          name="keywords"
          content="single family home, luxury homes, custom homes, luxury custom homes, Suwanee Preserves, Cumming custom luxury homes, Sky Lane, new homes in Cumming, new custom luxury homes in Cumming, Luxury Homes Cumming GA, Luxury Real Estate Cumming Georgia, High-End Homes Cumming GA, Luxury Home Builders Cumming GA, New Luxury Homes in Cumming GA, luxury homes for sale Cumming GA, custom luxury homes Cumming GA, Luxury Homes Forsyth County GA, upscale homes Cumming Georgia, premium properties Cumming GA, luxury residences Cumming GA, gated community homes Cumming GA, luxury lakefront homes Cumming GA, modern luxury homes Cumming GA, executive homes Cumming GA, luxury living Cumming GA, top luxury home builders Cumming GA, exclusive homes Cumming GA, luxury home communities Cumming GA, luxury home listings Cumming GA, custom home construction Georgia, custom luxury homes Georgia, high-end custom homes Georgia, luxury custom houses for sale Georgia, new custom home developments Georgia, custom homes Forsyth County, high-end custom real estate Georgia, premium custom homes Georgia, custom residences Georgia, custom gated community homes Georgia, custom luxury lakefront homes Georgia, executive custom homes Georgia, top custom home builders Georgia, custom home communities Georgia, custom home listings Georgia"
        />
        <link rel="canonical" href="https://www.krhomes.net/show-models" />
      </Helmet> */}

      {/* <Navbar class="position-fixed d-flex justify-content-between zi100 py-2 w-100 greybg px-5" /> */}

      <Carousel style={{ height: '100vh', overflow: 'hidden' }}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('../images/PP3.jpg')}
            alt="First slide"
            style={{ maxHeight: '100vh', objectFit: 'cover', width: '100%' }}
          />
          <Carousel.Caption
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              left: '5%',
            }}
          ></Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('../images/PP10.png')}
            alt="Second slide"
            style={{ maxHeight: '100vh', objectFit: 'cover', width: '100%' }}
          />
          <Carousel.Caption
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              left: '5%',
            }}
          ></Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('../images/PP2.jpg')}
            alt="Third slide"
            style={{ maxHeight: '100vh', objectFit: 'cover', width: '100%' }}
          />
          <Carousel.Caption
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              left: '5%',
            }}
          ></Carousel.Caption>
        </Carousel.Item>

        {/* Add more Carousel.Items as needed */}
      </Carousel>

      <h1
        className=" text-center playfair heading-color font54 my-5"
        data-aos="fade-up"
      >
        Discover a premium lifestyle
      </h1>

      {/* <div className="container bgFilter rounded-4 pb-2 pt-3">
      <
div className="row d-flex justify-content-center mb-3">
<div className="col">
<input
className="filter-input"
type="text"
placeholder="Model Title"
value={modelTitle}
onChange={(e) => setModelTitle(e.target.value)}
/>
</div>
<div className="col">
<input
className="filter-input"
type="text"
placeholder="Model Area"
value={modelArea}
onChange={(e) => setModelArea(e.target.value)}
/>
</div>
<div className="col">
<input
className="filter-input"
type="text"
placeholder="No. of Bedrooms"
value={noOfBedrooms}
onChange={(e) => setNoOfBedrooms(e.target.value)}
/>
</div>
<div className="col d-flex justify-content-center">
<button className="btn h56 btn-primary label-background-color px-4" onClick={handleSearch}>Find Models</button>
</div>
</div>


  <div className="row">
    <div className="col">
      <input
        className="filter-input"
        type="text"
        placeholder="No. of Baths"
        value={noOfBaths}
        onChange={(e) => setNoOfBaths(e.target.value)}
      />
    </div>
    <div className="col">
      <input
        className="filter-input"
        type="text"
        placeholder="No. of Stories"
        value={noOfStories}
        onChange={(e) => setNoOfStories(e.target.value)}
      />
    </div>
    <div className="col">
      <input
        className="filter-input"
        type="text"
        placeholder="Location"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
      />
    </div>
    <div className="col col d-flex justify-content-center">
      <button onClick={getModelsList} className="btn inter label-color" >Clear</button>
    </div>
  </div>
</div> */}

      {/* {error && <h3 className="text-center mb-5" >{error}</h3>} */}

      {loading === 0 ? (
        <div className="d-flex bgwhite col-lg col-12 py-5 px-3 rounded justify-content-center align-items-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : sortedModels.length === 0 ? (
        <div className="d-flex bgwhite col-lg col-12 py-5 px-3 rounded justify-content-center align-items-center">
          <p>No records found</p>
        </div>
      ) : (
        <div className="customContainer mt-4" data-aos="fade-up">
          <div className="row" data-aos="fade-up">
            {sortedModels.map((model, index) => (
              <div className="col-md-6 col-lg-4 mb-5" key={index}>
                <div
                  className="card mb-4 px-3 py-3 rounded-3"
                  style={{
                    borderRadius: '0.5px',
                    border: '0.5px solid #dddddd',
                  }}
                >
                  <p className="playfair playfair heading-color font32 mb-3 ">
                    {model.modelTitle}
                  </p>
                  <div className="Model-image imagek">
                  <div className="Model-image imagek">
  {model.modelTitle === 'Preserve#1' ? (
    <img
      src={Preserve1[0].imgPath}
      alt=""
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  ) : model.modelTitle === 'Preserve#2' ? (
    <img
      src={Preserve2[0].imgPath}
      alt=""
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  ) : model.modelTitle === 'Preserve#3' ? (
    <img
      src={Preserve3[0].imgPath}
      alt=""
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  )
  
  : model.modelTitle === 'model#448' ? (
    <img
      src={Model448[1].imgPath}
      alt=""
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  )

  : model.modelTitle === 'model#39' ? (
    <img
      src={Model39[6].imgPath}
      alt=""
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  )

  : model.modelTitle === 'model#445' ? (
    <img
      src={Model445[1].imgPath}
      alt=""
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  )
  
  : model.image_url ? (
    <img
      src={model.image_url}
      alt=""
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  ) : null}
</div>

            </div>
                  <div className="mt-2">
                    <div className="Model-details">
                      {/* <p className="playfair playfair heading-color font32 mb-0 ">
                  {model.modelTitle}
                </p> */}
                      <p
                        className="inter font20 Lgrey"
                        style={{ fontWeight: 700 }}
                      >
                        {model.price}
                      </p>
                      <p className="font16 inter Lgrey">
  {expandedDescriptionIndex === index
    ? model.modelDescription
    : model.modelDescription.substring(0, 100)}
  {model.modelDescription.length > 100 && (
    <>
      {' '}
      <a
        href="#"
        className="text-primary"
        style={{ cursor: 'pointer', fontWeight: 600, textDecoration: 'none', }}
        onClick={(e) => {
          e.preventDefault();
          handleToggleDescription(index);
        }}
      >
        {expandedDescriptionIndex === index ? ' Show Less' : '... More'}
      </a>
    </>
  )}
</p>
                      <div className="row d-flex justify-content-between mt-3">
                        <div className="d-flex col-7">
                          <div className="col-3 d-flex align-items-center">
                            <img
                              src={require('../images/bed-alt.png')}
                              alt="Bedroom Icon"
                              className="me-1"
                            />
                            <h6
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {model.noOfBedrooms}
                            </h6>
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <img
                              src={require('../images/bath.png')}
                              alt="Bathroom Icon"
                              className="me-1"
                            />
                            <h6
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {model.noOfBaths}
                            </h6>
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <img
                              src={require('../images/building.png')}
                              alt="Half Bath Icon"
                              className="me-2"
                            />
                            <h6
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {model.noOfStories}
                            </h6>
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <img
                              src={require('../images/garage-car.png')}
                              alt="Garage Icon"
                              className="me-1"
                            />
                            <h6
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {model.noOfGarages}
                            </h6>
                          </div>
                        </div>
                        <div className="col-5 d-flex justify-content-end">
                          <div className="d-flex align-items-center">
                            <img
                              src={require('../images/area.png')}
                              alt="Area Icon"
                              className="me-1"
                            />
                            <h6
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {model.modelArea}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1 col-md-1 p-1 p-md-0 ps-lg-3 mx-4"></div>
                  <hr className="mt-3 mb-3" /> {/* Line */}
                  <div className="d-flex justify-content-between">
                    <button
                      onClick={contactUs}
                      className="btn inter"
                      style={{ color: '#0064C2', fontWeight: 500 }}
                    >
                      Contact Us
                    </button>
                    <button
                      className="btn h56 btn-primary label-background-color px-4"
                      onClick={() => handleModelDesc(model)}
                    >
                      View Model
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {models.length > cardsPerPage && (
            <div className="pagination-container">
              <ReactPaginate
                pageCount={Math.ceil(models.length / cardsPerPage)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
                previousLabel={
                  <img
                    src={require('../images/prev.png')}
                    alt="Prev Icon"
                    className="mb-2"
                  />
                }
                nextLabel={
                  <img
                    src={require('../images/next.png')}
                    alt="Next Icon"
                    className=""
                  />
                }
              />
            </div>
          )}
        </div>
      )}

      {/* <Footer /> */}
      <ScrollToTopButton />
    </div>
  );
};

export default PropertyModels;
