import Preserve1Brochure from "./ModelBrochure/Preserve_1.pdf"
import Preserve2Brochure from "./ModelBrochure/Preserve_2.pdf"
import Preserve3Brochure from "./ModelBrochure/Preserve_3.pdf"
import model39Brochure from "./ModelBrochure/model39.pdf"
import model445Brochure from "./ModelBrochure/model445.pdf"
import model448Brochure from "./ModelBrochure/model448.pdf"




const ModelBrochure = [

 
  {
    "SNO": 1,
    "brochurePath": Preserve1Brochure
},

{
  "SNO": 2,
  "brochurePath": Preserve2Brochure
},

{
  "SNO": 3,
  "brochurePath": Preserve3Brochure
},

{
  "SNO": 4,
  "brochurePath": model39Brochure
},


{
    "SNO": 5,
    "brochurePath": model445Brochure
  },

  
{
    "SNO": 6,
    "brochurePath": model448Brochure
  },


];

export default ModelBrochure;