import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Navb from '../../Components/Navbar';
import httpcontactus from '../../js/http-lms_contactus';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { useNavigate } from 'react-router-dom';
import httpuserprofile from '../../js/http-lms_userprofile';
import { ROUTES } from '../../Constant/Routes';
import PageSeoComponent from '../../SEO';
import { format } from 'date-fns';
import SEO from '../../Constant/SEO.json'
// import axios from 'axios';


const AddEvent = () => {

  
  const [property, setProperty] = useState({
    eventName: '',
    eventDescription: '',
    status: '',
    websiteUrl: '',
    specialInstructions: '',
    eventType:"eb5",
  });


  const [locations, setLocations] = useState([
    {
      venue: '',
      address1: '',
      eventDate: '',
      startTime: '',
      endTime: '',
      countryId: '',
      stateId: '',
      cityId: '',
      zipcode: '',
      mapLink: '',
      eventOrganizerContact: ''
    }
  ]);
  


  const [formErros, setFormErrors] = useState({});
  const [ER, setER] = useState();
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [pop, setPop] = useState(false);


  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    console.log(formErros, 'ffffffff');
  }, [formErros]);

  useEffect(() => {

  
    //fetching countries list
    httpuserprofile.get('/userprofileLMS/getUserCountries').then((res) => {
      console.log('user countries', res);
      setCountriesList(res.data);
    });
  }, []);


  function getUserData() {
    //fetching user data
    httpuserprofile
      .get(
        `/userprofileLMS/getLoggedInUserDetails?userId=${sessionStorage.getItem(
          'userId'
        )}&roleId=${sessionStorage.getItem('roleId')}&authCode=KRDREBM390`
      )
      .then((response) => {
        console.log(response);
        sessionStorage.setItem('profileId', response.data.profileId);
        console.log('UserDetails in update user:', response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let fieldValue = type === 'checkbox' ? checked : value;

    // If the field is contact_number, remove non-numeric characters and limit length to 10
    if (name === 'contact_number') {
      // Remove any non-numeric characters
      fieldValue = fieldValue.replace(/\D/g, '');

      // Limit length to 10 characters
      fieldValue = fieldValue.slice(0, 10);

      // Check if the contact number has exactly 10 digits
      const isValidContactNumber = fieldValue.length === 10;

      // Set the error message if the contact number is invalid
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        salesTeam_contactno: isValidContactNumber
          ? ''
          : 'Mobile number must be of 10 digits!',
      }));
    }

    setProperty((prevProperty) => ({
      ...prevProperty,
      [name]: fieldValue,
    }));
  };




  const addLocation = () => {
    setLocations([
      ...locations,
      {
        venue: '',
        address1: '',
        eventDate: '',
        startTime: '',
        endTime: '',
        countryId: '',
        stateId: '',
        cityId: '',
        zipcode: '',
        mapLink: '',
        eventOrganizerContact: ''
      }
    ]);
  };


  const handleLocationChange = (index, event) => {
    const { name, value } = event.target;
    const updatedLocations = [...locations];
    
    // Format eventDate to MM-dd-yyyy if name is "eventDate"
    const newValue = name === "eventDate" ? format(new Date(value), 'MM-dd-yyyy') : value;
    
    updatedLocations[index] = { ...updatedLocations[index], [name]: newValue };
  
    // Update locations state
    setLocations(updatedLocations);
  
    // Initialize a copy of formErrors
    const errors = { ...formErros };
  
    // Validation: Check if endTime is less than or equal to startTime
    if (name === 'endTime' && updatedLocations[index].startTime) {
      const startTime = parseInt(updatedLocations[index].startTime, 10);
      const endTime = parseInt(value, 10);
  
      if (endTime <= startTime) {
        errors[`endTime-${index}`] = 'End Time must be after Start Time';
      } else {
        delete errors[`endTime-${index}`];
      }
    }
  
    // Update formErrors state
    setFormErrors(errors);
  };

  const removeLocation = (index) => {
    const updatedLocations = locations.filter((_, i) => i !== index);
    setLocations(updatedLocations);
  };





  
  function navToPrevRoute() {
    navigate(ROUTES.MY_EVENTS);
  }



  const convertESTToZulu = (estHour) => {
    // Add 5 hours to EST to get Zulu time
    const utcHour = (parseInt(estHour, 10) + 5) % 24;
    return `${utcHour.toString().padStart(2, '0')}:00Z`; // Format as HH:MMZ
  };



// const generateTimeOptions = () => {
  //   const times = [];
  //   for (let hour = 0; hour < 24; hour++) {
  //     for (let minute = 0; minute < 60; minute += 15) {
  //       const formattedHour = hour;
  //       const formattedMinute = String(minute).padStart(2, '0');
  //       times.push(`${formattedHour}:${formattedMinute}Z`);
  //     }
  //   }
  //   return times;
  // };
  
  // const timeOptions = generateTimeOptions();




// Generate an array of times in EST (Eastern Standard Time)
// const generateTimeOptions = () => {
//   const options = [];
//   for (let hour = 0; hour < 24; hour++) {
//     const estHour = (hour + 19) % 24; // EST is UTC-5; adjust hours accordingly
//     options.push(`${String(estHour).padStart(2, '0')}:00`);
//     options.push(`${String(estHour).padStart(2, '0')}:30`);
//   }
//   return options;
// };

// const timeOptions = generateTimeOptions();


const generateTimeOptions = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    const period = hour >= 12 ? 'PM' : 'AM';
    const displayHour = hour % 12 === 0 ? 12 : hour % 12; // 12-hour format
    const estTime = `${displayHour}:00 ${period} EST`;

    // Store time in 24-hour format for accurate conversion later
    options.push({ display: estTime, value: hour });
  }
  return options;
};

const timeOptions = generateTimeOptions();


 



const handleSubmit = (event) => {
  event.preventDefault();

  // Initialize errors object
  const errors = {};

  // Validate each location for startTime and endTime relationship
  locations.forEach((location, index) => {
    if (location.startTime && location.endTime) {
      const startTime = parseInt(location.startTime, 10);
      const endTime = parseInt(location.endTime, 10);

      if (endTime <= startTime) {
        errors[`endTime-${index}`] = 'End Time must be after Start Time';
      }
    }
  });

  // Validate other property fields
  const propertyErrors = validate(property, locations);
  
  // Combine location and property errors
  const combinedErrors = { ...errors, ...propertyErrors };

  // If there are errors, do not submit and update formErrors
  if (Object.keys(combinedErrors).length !== 0) {
    setFormErrors(combinedErrors);
  } else {
    // Clear errors and proceed with submission
    setFormErrors({});

    const formattedEvent = {
      eventName: property.eventName,
      eventDescription: property.eventDescription,
      status: property.status,
      createdBy: sessionStorage.getItem('userEmail') || "Madhavi",
      specialInstructions: property.specialInstructions,
      websiteUrl: property.websiteUrl,
      eventLocations: locations.map((location) => ({
        venue: location.venue,
        address: location.address1,
        eventDate: location.eventDate,
        startTime: convertESTToZulu(location.startTime),
        endTime: convertESTToZulu(location.endTime),
        countryId: location.countryId,
        stateId: location.stateId,
        cityId: location.cityId,
        zipcode: location.zipcode,
        mapLink: location.mapLink,
        eventOrganizerContact: location.eventOrganizerContact
      }))
    };

    httpcontactus
    .post('/eventDetails/createEventDetails', formattedEvent)
    .then((response) => {
      if (response.data === "This event is already existed!") {
        // Set the error message if a duplicate record is attempted
        setER("This event is already existed! Duplicate records are not allowed.");
      } else if (response.data) {
        setPop(true);
        setTimeout(() => {
          setPop(false);
        }, 1500);
      } else {
        console.log('Unexpected error in response');
      }
    })
    .catch((error) => {
      console.log(error.response.data);
      setER(error.response.data);
    });
  }
};
  

  function handleCountryChange(index, selectedCountry) {
    const updatedLocations = [...locations];
    updatedLocations[index] = {
      ...updatedLocations[index],
      countryId: selectedCountry ? selectedCountry.countryId : ''
    };
    setLocations(updatedLocations);
    
    // Reset state and city for the new country
    updatedLocations[index].stateId = '';
    updatedLocations[index].cityId = '';
  
    // Fetch states based on the selected country
    if (selectedCountry) {
      httpuserprofile
        .get(`/userprofileLMS/getUserStates?countryId=${selectedCountry.countryId}`)
        .then((res) => {
          setStatesList(res.data);
        })
        .catch((error) => {
          console.error('Error fetching states:', error);
        });
    }
  }
  
  function handleStateChange(index, selectedState) {
    const updatedLocations = [...locations];
    updatedLocations[index] = {
      ...updatedLocations[index],
      stateId: selectedState ? selectedState.stateId : ''
    };
    setLocations(updatedLocations);
  
    // Fetch cities based on the selected state
    if (selectedState) {
      httpuserprofile
        .get(`/userprofileLMS/getUserCities?stateId=${selectedState.stateId}`)
        .then((res) => {
          setCitiesList(res.data);
        })
        .catch((error) => {
          console.error('Error fetching cities:', error);
        });
    }
  }
  
  function handleCityChange(index, selectedCity) {
    const updatedLocations = [...locations];
    updatedLocations[index] = {
      ...updatedLocations[index],
      cityId: selectedCity ? selectedCity.cityId : ''
    };
    setLocations(updatedLocations);
  }
  



  const validate = (values, locations) => {
    const errors = {};
    
    // Validate the main event (property) fields
    if (!values.eventName) {
      errors.eventName = 'Event Name is required!';
    }
    if (!values.eventDescription) {
      errors.eventDescription = 'Event Description is required!';
    }
    if (!values.status) {
      errors.status = 'Status is required!';
    }
    if (!values.websiteUrl) {
      errors.websiteUrl = 'Website Url is required!';
    }
    if (!values.specialInstructions) {
      errors.specialInstructions = 'Special Instructions are required!';
    }
  
    // Validate each location
    locations.forEach((location, index) => {
      if (!location.venue) {
        errors[`venue-${index}`] = `Venue is required for location ${index + 1}!`;
      }
      if (!location.address1) {
        errors[`address1-${index}`] = `Address is required for location ${index + 1}!`;
      }
      if (!location.eventDate) {
        errors[`eventDate-${index}`] = `Event date is required for location ${index + 1}!`;
      }
      if (!location.startTime) {
        errors[`startTime-${index}`] = `Start time is required for location ${index + 1}!`;
      }
      if (!location.endTime) {
        errors[`endTime-${index}`] = `End time is required for location ${index + 1}!`;
      }
      if (!location.countryId) {
        errors[`country-${index}`] = `Country is required for location ${index + 1}!`;
      }
      if (!location.stateId) {
        errors[`state-${index}`] = `State is required for location ${index + 1}!`;
      }
      if (!location.cityId) {
        errors[`city-${index}`] = `City is required for location ${index + 1}!`;
      }
      if (!location.zipcode) {
        errors[`zipcode-${index}`] = `Zip Code is required for location ${index + 1}!`;
      }
      if (!location.mapLink) {
        errors[`mapLink-${index}`] = `Map Link is required for location ${index + 1}!`;
      }
      if (!location.eventOrganizerContact) {
        errors[`eventOrganizerContact-${index}`] = `Event Organizer Contact is required for location ${index + 1}!`;
      }
    });
  
    console.log('Validation errors:', errors);
    return errors;
  };
  


  const colourStyles = {
    control: (styles) => ({
      ...styles,
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor:
        state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: '14px',
      paddingTop: '4px',
      paddingBottom: '4px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };



 
  return (
    <>
  
  <PageSeoComponent {...SEO['add-event']} />

      <>
        {pop && (
          <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
            <div className=" d-flex flex-column justify-content-center align-items-center P-Update-Success-2 ">
              <div>
                <img
                  src={require('../../images/Tick.png')}
                  alt="success"
                  className="img-fluid"
                />
              </div>
              <p className="text-center w213 ">
                Sucessfully event has been added
              </p>
            </div>
          </div>
        )}


        <div className="col-lg-12 d-flex mt-5">
          <div
            className=" d-flex align-items-center mt-5 pt-4 pointer add-property-title-wrap"
            onClick={navToPrevRoute}
          >
            <img
              className="pe-3 backarrow"
              src={require('../../images/leftarrow.png')}
              alt="back"
            />
            <h4 className="SectionHeadings mt-2">Add Event</h4>
          </div>
        </div>

        <div className="add-property-page">
          <h1>Event Info</h1>
          <Form onSubmit={handleSubmit}>
            <div className="row add-property-form">

              <div className="col-md-4">
                <Form.Group controlId="eventName">
                  <Form.Label className="required">Event Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="eventName"
                    // value={property.property_title}
                    onChange={handleChange}
                  />
                  <p className="red font12">{formErros.eventName}</p>
                </Form.Group>
              </div>


            

              <div className="col-md-4">
                <Form.Group controlId="eventDescription">
                  <Form.Label className="required">Event Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="eventDescription"
                    value={property.eventDescription}
                    onChange={handleChange}
                  />
                  <p className="red font12">{formErros.eventDescription}</p>
                </Form.Group>
              </div>



              <div className="col-md-4">
            <Form.Group controlId="status">
                <Form.Label className="required">Status</Form.Label>
                <Form.Select
  name="status"
  value={property.status}
  onChange={handleChange}
  aria-label="Default select example"
>
  <option value="">Select Status</option>
  <option value="Active">Active</option>
  <option value="Inactive">Inactive</option>
</Form.Select>

                <p className="red font12">{formErros.status}</p>
              </Form.Group>
            </div>




            <div className="col-md-4">
                <Form.Group controlId="websiteUrl">
                  <Form.Label className="required">Website URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="websiteUrl"
                    value={property.websiteUrl}
                    onChange={handleChange}
                  />
                  <p className="red font12">{formErros.websiteUrl}</p>
                </Form.Group>
              </div>

              <div className="col-md-4">
                <Form.Group controlId="specialInstructions">
                  <Form.Label className="required">Special Instructions</Form.Label>
                  <Form.Control
                    type="text"
                    name="specialInstructions"
                    value={property.specialInstructions}
                    onChange={handleChange}
                  />
                  <p className="red font12">{formErros.specialInstructions}</p>
                </Form.Group>
              </div>


              {locations.map((location, index) => (
  <div key={index} className="row border-top pt-4 mt-3 add-property-form">
     <h5 className="mb-4">Location {index + 1}</h5>
    {/* Venue */}
    <div className="col-md-4">
      <Form.Group controlId={`venue-${index}`}>
        <Form.Label className="required">Venue</Form.Label>
        <Form.Control
          type="text"
          name="venue"
          value={location.venue}
          onChange={(e) => handleLocationChange(index, e)}
        />
        {formErros[`venue-${index}`] && <p className='red font12'>{formErros[`venue-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* Address */}
    <div className="col-md-4">
      <Form.Group controlId={`address-${index}`}>
        <Form.Label className="required">Address</Form.Label>
        <Form.Control
          type="text"
          name="address1"
          value={location.address1}
          onChange={(e) => handleLocationChange(index, e)}
        />
        {formErros[`address1-${index}`] && <p className='red font12'>{formErros[`address1-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* Event Date */}
    <div className="col-md-4">
  <Form.Group controlId={`eventDate-${index}`}>
    <div className='d-flex flex-column'>
    <Form.Label className="required">Date</Form.Label>
    <DatePicker
      selected={location.eventDate}
      onChange={(date) => handleLocationChange(index, { target: { name: "eventDate", value: date } })}
      minDate={today}
      dateFormat="MM-dd-yyyy"
      className="form-control"
    />
    </div>
    {formErros[`eventDate-${index}`] && <p className='red font12'>{formErros[`eventDate-${index}`]}</p>}
  </Form.Group>
</div>

    {/* Start Time */}
    <div className="col-md-4">
  <Form.Group controlId={`startTime-${index}`}>
    <Form.Label className="required">Start Time</Form.Label>
    <Form.Control
  as="select"
  value={location.startTime || ""}
  onChange={(e) =>
    handleLocationChange(index, { target: { name: 'startTime', value: e.target.value } })
  }
  className="form-control"
>
  <option value="" disabled>Select Start Time</option>
  {timeOptions.map((time, i) => (
    <option key={i} value={time.value}>
      {time.display}
    </option>
  ))}
</Form.Control>

    {formErros[`startTime-${index}`] && <p className="red font12">{formErros[`startTime-${index}`]}</p>}
  </Form.Group>
</div>

<div className="col-md-4">
  <Form.Group controlId={`endTime-${index}`}>
    <Form.Label className="required">End Time</Form.Label>
    <Form.Control
  as="select"
  value={location.endTime || ""}
  onChange={(e) =>
    handleLocationChange(index, { target: { name: 'endTime', value: e.target.value } })
  }
  className="form-control"
>
  <option value="" disabled>Select End Time</option>
  {timeOptions.map((time, i) => (
    <option key={i} value={time.value}>
      {time.display}
    </option>
  ))}
</Form.Control>

    {formErros[`endTime-${index}`] && <p className="red font12">{formErros[`endTime-${index}`]}</p>}
  </Form.Group>
</div>



    {/* Country */}
    <div className="col-md-4">
      <Form.Group controlId={`country-${index}`}>
        <Form.Label className="required">Country</Form.Label>
        <Select
  value={location.selectedCountryOption}
  onChange={(selectedCountry) => handleCountryChange(index, selectedCountry)}
  placeholder="Select Country"
  className="multiselectdropdown"
  classNamePrefix="react-select"
  styles={colourStyles}
  getOptionLabel={(option) => `${option.countryName}`}
  options={countriesList}
/>
{formErros[`country-${index}`] && <p className='red font12'>{formErros[`country-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* State */}
    <div className="col-md-4">
      <Form.Group controlId={`state-${index}`}>
        <Form.Label className="required">State</Form.Label>
       
<Select
  value={location.selectedStateOption}
  onChange={(selectedState) => handleStateChange(index, selectedState)}
  placeholder="Select State"
  className="multiselectdropdown"
  classNamePrefix="react-select"
  styles={colourStyles}
  getOptionLabel={(option) => `${option.stateName}`}
  options={statesList}
/>
{formErros[`state-${index}`] && <p className='red font12'>{formErros[`state-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* City */}
    <div className="col-md-4">
      <Form.Group controlId={`city-${index}`}>
        <Form.Label className="required">City</Form.Label>
        <Select
  value={location.selectedCityOption}
  onChange={(selectedCity) => handleCityChange(index, selectedCity)}
  placeholder="Select City"
  className="multiselectdropdown"
  classNamePrefix="react-select"
  styles={colourStyles}
  getOptionLabel={(option) => `${option.cityName}`}
  options={citiesList}
/>
{formErros[`city-${index}`] && <p className='red font12'>{formErros[`city-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* Zip Code */}
    <div className="col-md-4">
      <Form.Group controlId={`zipcode-${index}`}>
        <Form.Label className="required">Zip Code</Form.Label>
        <Form.Control
          type="text"
          name="zipcode"
          value={location.zipcode}
          onChange={(e) => handleLocationChange(index, e)}
        />
         {formErros[`zipcode-${index}`] && <p className='red font12'>{formErros[`zipcode-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* Map Link */}
    <div className="col-md-4">
      <Form.Group controlId={`mapLink-${index}`}>
        <Form.Label className="required">Map Link</Form.Label>
        <Form.Control
          type="text"
          name="mapLink"
          value={location.mapLink}
          onChange={(e) => handleLocationChange(index, e)}
        />
         {formErros[`mapLink-${index}`] && <p className='red font12'>{formErros[`mapLink-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* Organizer Contact */}
    <div className="col-md-4">
      <Form.Group controlId={`eventOrganizerContact-${index}`}>
        <Form.Label className="required">Organizer Contact</Form.Label>
        <Form.Control
          type="text"
          name="eventOrganizerContact"
          value={location.eventOrganizerContact}
          onChange={(e) => handleLocationChange(index, e)}
        />
         {formErros[`eventOrganizerContact-${index}`] && <p className='red font12'>{formErros[`eventOrganizerContact-${index}`]}</p>}
      </Form.Group>
    </div>

    {/* Remove Location Button */}
    {index > 0 && (
      <div className="col-md-4 pt-4">
        <Button variant="danger" onClick={() => removeLocation(index)}>
          Remove Location
        </Button>
      </div>
    )}
  </div>






))}

<div className='col-md-3 py-3'>
<Button onClick={addLocation}>Add More Location</Button>
</div>                    
            </div>

            
            <Button type="submit">Submit</Button>

            {ER && <p className="error-message" style={{ color: 'red', fontSize: '12px', marginTop: '8px' }}>{ER}</p>}
           
          </Form>
        </div>
      </>
    </>
  );
};

export default AddEvent;
