import img1 from "./Model445_Images/005 - 445 pirkle ave buford ga.webp";
import img2 from "./Model445_Images/006 - 445 pirkle ave buford ga.webp";
import img3 from "./Model445_Images/017 - 445 pirkle ave buford ga.webp";
import img4 from "./Model445_Images/019 - 445 pirkle ave buford ga.webp";
import img5 from "./Model445_Images/021 - 445 pirkle ave buford ga.webp";
import img6 from "./Model445_Images/024 - 445 pirkle ave buford ga.webp";
import img7 from "./Model445_Images/029 - 445 pirkle ave buford ga.webp";
import img8 from "./Model445_Images/033 - 445 pirkle ave buford ga.webp";
import img9 from "./Model445_Images/047 - 445 pirkle ave buford ga.webp";
import img10 from "./Model445_Images/048 - 445 pirkle ave buford ga.webp";
import img11 from "./Model445_Images/055 - 445 pirkle ave buford ga.webp";
// import frontElevation from "./Model445_Images/frontElevationPre2.webp";


const Model445 = [


//   { "SNO": 0,
//     "id": 3,
//     "imgType": "Exterior Front",
//     "imgPath": frontElevation
// },


  {
    "SNO": 1,
    "id": 8,
    "imgType": "Hall Way",
    "imgPath": img1
  },

  {"SNO": 2,
    "id": 7,
    "imgType": "Hall",
    "imgPath": img2
},


{"SNO": 3,
  "id": 19,
  "imgType": "Kitchen",
  "imgPath": img3
},


{
  "SNO": 4,
  "id": 20,
  "imgType": "Laundry Area",
  "imgPath": img4
},



{ 
  "SNO": 5,
"id": 11,
"imgType": "Master Bedroom",
"imgPath": img5
},



{    "SNO": 6,
  "id": 22,
  "imgType": "Dressing Room",
  "imgPath": img6
},



{"SNO": 7,
  "id": 7,
  "imgType": "Hall",
  "imgPath": img7
},




{
  "SNO": 8,
  "id": 21,
  "imgType": "Bath#1",
  "imgPath": img8
},



{
  "SNO": 9,
  "id": 27,
  "imgType": "Play Area",
  "imgPath": img9
},


{
  "SNO": 10,
  "id": 27,
  "imgType": "Play Area",
  "imgPath": img10
},


{
  "SNO": 11,
  "id": 24,
  "imgType": "Sit Out",
  "imgPath": img11
},


];

export default Model445;
