import React from "react";
import { useNavigate } from "react-router-dom";

function Button2(props){

    const navigate = useNavigate()
    function handleClick() {
        navigate(props.path)
    }

    return(
        <button onClick={handleClick} className="btn2">{props.button}</button>
    )
}

export default Button2