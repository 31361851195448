import React from "react";
import { useState } from "react";
import httpuserprofile from "../js/http-lms_userprofile";
import closebtn from "../images/close_btn.svg";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import showPwdImg from "../images/showpwd.svg";
import hidePwdImg from "../images/hidepwd.svg";

const UpdatePassInside = ({ setPop }) => {
  const [isRevealPwd1, setIsRevealPwd1] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);
  const [isRevealPwd3, setIsRevealPwd3] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setreNewPassword] = useState("");

  const [finalLoading, setFinalLoading] = useState(false);
  const [message, setMessage] = useState();
  const [errormessage, setErrorMessage] = useState();

  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    reNewPassword: "",
  });

  const closeUpdatePassword = (status) => {
    setPop(false);
  };

  const validatePasswords = () => {
    let newErrors = { oldPassword: "", newPassword: "", reNewPassword: "" };

    if (oldPassword === newPassword) {
      newErrors.newPassword = "Old password should not match with new password";
    } else if (newPassword.length < 8) {
      newErrors.newPassword =
        "Minimum 8 characters, 1 capital letter, 1 special character and 1 number";
    } else {
      let format = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      if (!format.test(newPassword)) {
        newErrors.newPassword =
          "Minimum 8 characters, 1 capital letter, 1 special character and 1 number";
      } else if (newPassword !== reNewPassword) {
        newErrors.reNewPassword =
          "New password should match with re-enter new password";
      }
    }

    setErrors(newErrors);
    return newErrors;
  };

  const updatingPassword = () => {
    setFinalLoading(true);
    setMessage(null);
    setErrorMessage(null);
    let passwordObj = {
      userEmail: sessionStorage.getItem("userEmail"),
      newPassword: newPassword,
      oldPassword: oldPassword,
      authCode: "KRDREBM390",
    };
    httpuserprofile
      .put("userprofileLMS/changePassword", passwordObj)
      .then((response) => {
        console.log("saving pwd : ", response.data);
        setMessage(response.data);
        setFinalLoading(false);
        if (response.data === "Password Updated") {
          setTimeout(() => {
            setMessage();
          }, 2000);
          setTimeout(() => {
            closeUpdatePassword("SUCCESS");
          }, 2000);
        }
      })
      .catch((e) => {
        console.log(e);
        setFinalLoading(false);
      });
  };

  const checkPassword = () => {
    const validationErrors = validatePasswords();
    if (!validationErrors.oldPassword && !validationErrors.newPassword && !validationErrors.reNewPassword) {
      updatingPassword();
    }
  };

  const handlePasswordChange = (setter) => (e) => {
    setter(e.target.value);
    setErrors({ oldPassword: "", newPassword: "", reNewPassword: "" });
  };

  return (
    <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
      <div className="card radius10 border-0 col-11 col-md-8 col-lg-3 d-flex flex-column px-4">
        <div className="col-12 p-2 py-3 radius10 border-0 bold1 background-gray font18 text-center">
          <span>Change Password</span>
          <img
            src={closebtn}
            alt="close"
            width="25px"
            className="closebtn mouseoverstyles1 radiushalf pointer border-0"
            onClick={() => closeUpdatePassword("NOT SUCCESS")}
          />
        </div>
        <div className="col-12 p-1 d-flex flex-column">
          

        {message === "Old password is not matching, Please enter correct password" ? (
  <span className="mb-2 text-center mx-auto font20 red height15">
    {message}
  </span>
) : (
  <span className="mb-2 text-center mx-auto font20 success-color height15">
    {message}
  </span>
)}



          {errormessage && (
            <span className="mb-2 text-center mx-auto font24 red height15">
              {errormessage}
            </span>
          )}
          <Form className=" d-flex flex-column align-items-center">
            <Form.Group
              style={{ width: "90%" }}
              className=""
              controlId="formBasicPassword"
            >
              <label className="required font14"> Old Password</label>
              <div className="pwd-container">
                <input
                  name="Opass"
                  placeholder="Enter Old Password"
                  autoComplete="off"
                  maxLength="16"
                  type={isRevealPwd1 ? "text" : "password"}
                  value={oldPassword}
                  onChange={handlePasswordChange(setOldPassword)}
                />
                <img
                  title={isRevealPwd1 ? "Hide password" : "Show password"}
                  src={isRevealPwd1 ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd1((prevState) => !prevState)}
                />
              </div>
              <label className="required font14"> New Password</label>
              <div className="pwd-container">
                <input
                  name="Npass"
                  placeholder="Enter New Password"
                  autoComplete="off"
                  maxLength="16"
                  type={isRevealPwd2 ? "text" : "password"}
                  value={newPassword}
                  onChange={handlePasswordChange(setNewPassword)}
                />
                <img
                  title={isRevealPwd2 ? "Hide password" : "Show password"}
                  src={isRevealPwd2 ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd2((prevState) => !prevState)}
                />
              </div>
              {errors.newPassword && (
                <div className="text-danger">{errors.newPassword}</div>
              )}
              <label className="required font14"> Re - type New Password</label>
              <div className="pwd-container">
                <input
                  name="RNpass"
                  placeholder="Re-enter New Password"
                  autoComplete="off"
                  maxLength="16"
                  type={isRevealPwd3 ? "text" : "password"}
                  value={reNewPassword}
                  onChange={handlePasswordChange(setreNewPassword)}
                />
                <img
                  title={isRevealPwd3 ? "Hide password" : "Show password"}
                  src={isRevealPwd3 ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd3((prevState) => !prevState)}
                />
              </div>
              {errors.reNewPassword && (
                <div className="text-danger">{errors.reNewPassword}</div>
              )}
            </Form.Group>
          </Form>
        </div>
        <div className="footer text-center p-3">
          {finalLoading ? (
            <div className="col-10 text-center mx-auto">
              <Spinner
                animation="border"
                className="spinner"
                variant="secondary"
              />
            </div>
          ) : (
            <button
              className={
                oldPassword.length >= 8 &&
                newPassword.length >= 8 &&
                reNewPassword.length >= 8 &&
                oldPassword !== newPassword &&
                newPassword === reNewPassword &&
                !errors.oldPassword &&
                !errors.newPassword &&
                !errors.reNewPassword
                  ? "label-background-color inter white w-100 border-0 p-1 rounded"
                  : "w-100 border-button p-1 black60 background-accordion rounded"
              }
              onClick={checkPassword}
              
            >
              Update Password
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdatePassInside;
