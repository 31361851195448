import React from "react";
import { useNavigate } from "react-router-dom";

function Button(props){

    const navigate = useNavigate()
    function handleClick() {
        if (props.function) {
            props.function()
        }
        else {
            navigate(props.path, {state:{data: props.data}})            
        }
    }

    return(
        <button disabled={props.disable} onClick={handleClick} className={props.class}>{props.button}</button>
    )
}

export default Button


