// import img1 from "./Preserve3_Images/003 - 39 cub trce dahlonega ga.webp";
import img2 from "./Preserve3_Images/004 - 39 cub trce dahlonega ga.webp";
import img3 from "./Preserve3_Images/007 - 39 cub trce dahlonega ga.webp";
import img4 from "./Preserve3_Images/009 - 39 cub trce dahlonega ga.webp";
import img5 from "./Preserve3_Images/017 - 39 cub trce dahlonega ga.webp";
import img6 from "./Preserve3_Images/021 - 39 cub trce dahlonega ga.webp";
import img7 from "./Preserve3_Images/023 - 39 cub trce dahlonega ga.webp";
import img8 from "./Preserve3_Images/026 - 39 cub trce dahlonega ga.webp";
import img9 from "./Preserve3_Images/032 - 39 cub trce dahlonega ga.webp";
import img10 from "./Preserve3_Images/037 - 39 cub trce dahlonega ga.webp";
import img11 from "./Preserve3_Images/039 - 39 cub trce dahlonega ga.webp";
import img12 from "./Preserve3_Images/045 - 39 cub trce dahlonega ga.webp";
import img13 from "./Preserve3_Images/048 - 39 cub trce dahlonega ga.webp";
import img14 from "./Preserve3_Images/049 - 39 cub trce dahlonega ga.webp";
import img15 from "./Preserve3_Images/050 - 39 cub trce dahlonega ga.webp";
import img16 from "./Preserve3_Images/055 - 39 cub trce dahlonega ga.webp";
import frontElevation from "./Preserve3_Images/frontElevationPre3.webp";

const Preserve3 = [


  { "SNO": 0,
    "id": 3,
    "imgType": "Exterior Front",
    "imgPath": frontElevation
},


  // {
  //   "SNO": 1,
  //   "id": 8,
  //   "imgType": "Hall Way",
  //   "imgPath": img1
  // },

  {"SNO": 2,
    "id": 7,
    "imgType": "Hall",
    "imgPath": img2
},

{ "SNO": 3,
    "id": 7,
    "imgType": "Hall",
    "imgPath": img3,
},

{ "SNO": 4,
    "id": 7,
    "imgType": "Hall",
    "imgPath": img4
},

{"SNO": 5,
    "id": 19,
    "imgType": "Kitchen",
    "imgPath": img5
},



{    "SNO": 6,
    "id": 22,
    "imgType": "Dressing Room",
    "imgPath": img6
},


  {
    "SNO": 7,
    "id": 16,
    "imgType": "Appliances",
    "imgPath": img7
  },



  { 
    "SNO": 8,
    "id": 30,
    "imgType": "Balcony",
    "imgPath": img8
},


{ 
       "SNO": 9,
    "id": 11,
    "imgType": "Master Bedroom",
    "imgPath": img9
},



  {
    "SNO": 10,
    "id": 21,
    "imgType": "Bath#1",
    "imgPath": img10
  },


  {
    "SNO": 11,
    "id": 23,
    "imgType": "Closest",
    "imgPath": img11
},


{
    "SNO": 12,
    "id": 25,
    "imgType": "Bedroom#1",
    "imgPath": img12
},


{"SNO": 13,
    "id": 19,
    "imgType": "Kitchen",
    "imgPath": img13
},

{"SNO": 14,
    "id": 19,
    "imgType": "Kitchen",
    "imgPath": img14
},

{"SNO": 15,
    "id": 19,
    "imgType": "Kitchen",
    "imgPath": img15
},


{"SNO": 16,
    "id": 33,
    "imgType": "Bath#2",
    "imgPath": img16
},

];

export default Preserve3;
