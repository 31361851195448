import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../Components/Footer';
import ScrollToTopButton from '../Components/ScrollToTop';
import 'aos/dist/aos.css'; // Import AOS CSS
import AOS from 'aos';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { ReactComponent as SliderArrow } from '../images/slider-next-arrow.svg';
import pdfFile from '../images/pre1.pdf';
// import { Helmet } from 'react-helmet';
import httpproperties from '../js/http-realestate-properties';
import ImageGallery from 'react-image-gallery';
import Preserve1 from "../images/ModelImages/Preserve1/Preserve1"
import Preserve2 from "../images/ModelImages/Preserve2/Preserve2"
import Preserve3 from "../images/ModelImages/Preserve3/Preserve3"
import Model448 from "../images/ModelImages/Model448/Model448"
import Model39 from "../images/ModelImages/Model39/Model39"
import Model445 from "../images/ModelImages/Model445/Model445"
import ModelBrochure from '../images/Brochure/ModelBrochure';
import ModelFloorPlan from '../images/Site and Floor Plans/ModelFloorPlan';
import { ROUTES } from '../Constant/Routes';
import 'react-image-gallery/styles/css/image-gallery.css';
import PageSeoComponent from '../SEO';
import SEO from '../Constant/SEO.json';

const ModelDesc = () => {
  const location = useLocation();
  const modelId = location.state.modelInfo.id;
  const [modelInfo, setModelInfo] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [error, setError] = useState();
  const [images, setImages] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [floorPdfUrl, setfloorPdfUrl] = useState(null);
  const [imageObject, setImageObject] = useState({});
  const [selectedBrochurePdf, setSelectedBrochurePdf] = useState(null);
  const [selectedFloorPdf, setSelectedFloorPdf] = useState(null);
  const iframeRef = useRef(null);
  const [isViewerOpen, setIsViewerOpen] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);

  AOS.init({
    duration: 600, // Animation duration
    easing: 'ease-in-out', // Easing function
  });

  function getSelectedModel(id) {
    httpproperties
      .get(`/modelInformation/getmodelinformation/${id}`)
      .then((response) => {
        if (response.data === 'No records found') {
          setError(response.data);
        } else {
          console.log('Selected Model Details: ', response);
          setModelInfo(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    if (modelInfo?.imageSrcLink?.length > 0) {
      setImageObject(modelInfo.imageSrcLink.find((image) => image.imgId === 9));
    }
  }, [modelInfo]);

  useEffect(() => {
    console.log(modelInfo);
    getSelectedModel(modelId);
    getDocuments();
  }, []);

  const navigate = useNavigate();

  function contactUs() {
    navigate(ROUTES.CONTACT_US);
  }

  const scrollToVideo = () => {
    // Check if iframeRef.current exists before scrolling
    if (iframeRef.current) {
      iframeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Set images from modelInfo once component mounts
    if (modelInfo && modelInfo.imageSrcLink) {
      setImages(
        modelInfo.imageSrcLink
          .filter(
            (image) =>
              image.imgId !== 9 && image.imgId !== 12 && image.imgId !== 17
          )
          .map((image) => image.imageUrl)
      );
    }

    // const planImage = selectedFloorPdf;
    // if (planImage && planImage.image_url) {
    //   setfloorPdfUrl(planImage.image_url);
    //   console.log(planImage.image_url, 'PDFPDFPDFPDF')
    // } else {
    //   console.error('Plan image not found or invalid URL');
    // }
  }, [modelInfo]);



  const handleDownloadBrochure = () => {
    let brochureImage;
  
    // Check the model title and set the appropriate brochure path
    if (modelInfo.modelTitle === 'Preserve#1') {
      brochureImage = ModelBrochure[0]?.brochurePath;
    } else if (modelInfo.modelTitle === 'Preserve#2') {
      brochureImage = ModelBrochure[1]?.brochurePath;
    } else if (modelInfo.modelTitle === 'Preserve#3') {
      brochureImage = ModelBrochure[2]?.brochurePath;
    } else if (modelInfo.modelTitle === 'model#39') {
      brochureImage = ModelBrochure[3]?.brochurePath;
    } else if (modelInfo.modelTitle === 'model#445') {
      brochureImage = ModelBrochure[4]?.brochurePath;
    } else if (modelInfo.modelTitle === 'model#448') {
      brochureImage = ModelBrochure[5]?.brochurePath;
    } else {
      // Use the original selectedBrochurePdf if the model doesn't match any of the predefined ones
      brochureImage = selectedBrochurePdf ? selectedBrochurePdf.image_url : null;
    }
  
    // Proceed with the same logic if brochureImage is found
    if (brochureImage) {
      const link = document.createElement('a');
      link.href = brochureImage; // Directly use brochureImage as the href
      link.target = '_blank'; // Open the link in a new tab
      link.rel = 'noopener noreferrer'; // Security measure
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Brochure image not found or invalid URL');
    }
  };
  

  const handleViewPlan = () => {
    let planImage;
  
    // Check if it's a specific model and set the correct floor plan path
    if (modelInfo.modelTitle === 'Preserve#1') {
      planImage = { image_url: ModelFloorPlan[0]?.floorPath };
    } else if (modelInfo.modelTitle === 'Preserve#2') {
      planImage = { image_url: ModelFloorPlan[1]?.floorPath };
    } else if (modelInfo.modelTitle === 'Preserve#3') {
      planImage = { image_url: ModelFloorPlan[2]?.floorPath };
    } else if (modelInfo.modelTitle === 'model#39') {
      planImage = { image_url: ModelFloorPlan[3]?.floorPath };
    } else if (modelInfo.modelTitle === 'model#445') {
      planImage = { image_url: ModelFloorPlan[4]?.floorPath };
    } else if (modelInfo.modelTitle === 'model#448') {
      planImage = { image_url: ModelFloorPlan[5]?.floorPath };
    } else {
      // Default to the selected floor plan for other cases
      planImage = selectedFloorPdf;
    }
  
    // Proceed with opening the link if the planImage exists and has a valid URL
    if (planImage && planImage.image_url) {
      const link = document.createElement('a');
      link.href = planImage.image_url; // Set the href to the image URL
      link.target = '_blank'; // Open the link in a new tab
      link.rel = 'noopener noreferrer'; // Security measure
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Plan image not found or invalid URL');
    }
  };
  

  const getDocuments = () => {
    ['Site Plan', 'Specification'].forEach(async (docType, index) => {
      await httpproperties
        .get(
          `/propertyManagement/getUrlByImgType?imgType=${docType}&modelId=${modelId}`
        )
        .then((res) => {
          console.log(res.data, 'ddddddddddddddddd');
          if (index === 0) {
            setSelectedFloorPdf(res.data);
          }
          if (index === 1) {
            console.log(res.data, 'zzzzzzzzzzzzz');
            setSelectedBrochurePdf(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const galleryImages =
  modelInfo.modelTitle === 'Preserve#1'
    ? Preserve1.map((image) => ({
        original: image.imgPath,
        thumbnail: image.imgPath,
      }))
    : modelInfo.modelTitle === 'Preserve#2'
    ? Preserve2.map((image) => ({
        original: image.imgPath,
        thumbnail: image.imgPath,
      }))
    : modelInfo.modelTitle === 'Preserve#3'
    ? Preserve3.map((image) => ({
        original: image.imgPath,
        thumbnail: image.imgPath,
      }))
      : modelInfo.modelTitle === 'model#448'
    ? Model448.map((image) => ({
        original: image.imgPath,
        thumbnail: image.imgPath,
      }))
      : modelInfo.modelTitle === 'model#39'
    ? Model39.map((image) => ({
        original: image.imgPath,
        thumbnail: image.imgPath,
      }))
      : modelInfo.modelTitle === 'model#445'
      ? Model445.map((image) => ({
          original: image.imgPath,
          thumbnail: image.imgPath,
        }))
    : images.map((image) => ({
        original: image,
        thumbnail: image,
      })); // Default to the galleryImages if no modelTitle matches

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  // Check if the imageSrcLink array exists and has elements before accessing the first element
  const firstImage =
    modelInfo.imageSrcLink && modelInfo.imageSrcLink.length > 0
      ? modelInfo.imageSrcLink.find(
          (image) =>
            image.imgId !== 9 && image.imgId !== 12 && image.imgId !== 17
        ).imageUrl
      : '';

  return (
    <>
      <PageSeoComponent {...SEO['model-description']} />

      {/* <Helmet>
        <title>Model Description-{`${modelInfo.modelTitle}`}</title>
        <meta
          name="description"
          content={`Explore the ${modelInfo.modelTitle}, a premier luxury home model offered by KR Real Estate Developers LLC in Cumming, GA. Discover its features, specifications, and why it's perfect for your dream home.`}
        />
        <meta
          name="keywords"
          content="single family home, luxury homes, custom homes, luxury custom homes, suwanee preserves, cumming custom luxury homes, sky lane, new homes in cumming, new custom luxury homes in cumming, new custom luxury homes, Luxury Homes Cumming GA, Luxury Real Estate Cumming Georgia, High-End Homes Cumming GA, Luxury Home Builders Cumming GA, New Luxury Homes in Cumming GA, Luxury Houses for Sale in Cumming GA, Custom Luxury Homes Cumming GA, Luxury Homes Forsyth County GA, Upscale Homes Cumming Georgia, Premium Properties Cumming GA, Luxury Residences Cumming GA, Gated Community Homes Cumming GA, Luxury Lakefront Homes Cumming GA, Modern Luxury Homes Cumming GA, Executive Homes Cumming GA, Luxury Living Cumming GA, Top Luxury Home Builders in Cumming GA, Exclusive Homes Cumming GA, Luxury Home Communities Cumming GA, Luxury Home Listings Cumming GA, Custom Home Construction Georgia, Custom Luxury Homes in Georgia, High-End Custom Homes Georgia, Luxury Custom Houses for Sale in Georgia, New Custom Home Developments Georgia, Custom Homes Forsyth County, High-End Custom Real Estate Georgia, Premium Custom Homes Georgia, Custom Residences in Georgia, Custom Gated Community Homes in Georgia, Custom Luxury Lakefront Homes Georgia, Executive Custom Homes Georgia, Top Custom Home Builders in Georgia, Custom Home Communities in Georgia, Custom Home Listings in Georgia"
        />
        <link
          rel="canonical"
          href="https://www.krhomes.net/model-description"
        />
      </Helmet> */}

      {/* <Navbar class="position-fixed d-flex justify-content-between zi100 py-2 w-100 greybg px-5" /> */}
      <div className="" style={{ position: 'relative', height: '70%' }}>
        <img
          src={require('../images/PP2.jpg')}
          alt="Background Image"
          style={{ maxHeight: '100vh', objectFit: 'cover', width: '100%' }}
        />

        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: '10',
          }}
        >
          <h1>{modelInfo.modelTitle}</h1>
        </div>
      </div>

      <div
        className="d-flex flex-column align-items-center my-5"
        data-aos="fade-up"
      >
        <h1 className="playfair heading-color font54" data-aos="fade-up">
          Gallery
        </h1>
        {/* <p
          className="inter font24 mt-3 mb-4 text-center"
          style={{ maxWidth: "498px" }}
        >
          Immerse yourself in luxury. Discover inspiring photos and virtual
          tours of your dream home.
        </p> */}
      </div>

      <div className="property-desc-slider" data-aos="fade-up">
        <div className="container 2-75">
          {/* <Swiper
      slidesPerView={1}
      autoHeight={true}
      pagination={{
        type: 'fraction',
        el: '.property-dot',
        clickable: true,
    }}
    navigation={{
        nextEl: '.property-arrow-right',
        prevEl: '.property-arrow-left',
    }}
      modules={[Pagination, Navigation]}
    >
    {images.map((image, index) => (
      <SwiperSlide key={index}>
        <img
          src={image}
          alt={`Gallery Image ${image}`}
        />
        
      </SwiperSlide>
    ))}
      </Swiper>
      <div className="slider-actions">
            <button
                type="button"
                className="slider-action-arrow slider-action-arrow--left property-arrow-left"
                aria-label="prev arrow"
            >
              <SliderArrow />
            </button>
            <div className="slider-action-dot property-dot" />
            <button
                type="button"
                className="slider-action-arrow slider-action-arrow--right property-arrow-right"
                aria-label="next arrow"
            >
              <SliderArrow />
            </button>
        </div> */}
          {isViewerOpen && (
            <ImageGallery
              items={galleryImages}
              startIndex={currentImage}
              showFullscreenButton={true}
              showPlayButton={false}
              onSlide={(index) => setCurrentImage(index)}
              onClose={() => setIsViewerOpen(false)}
            />
          )}
        </div>
      </div>

      <div className="customContainer mt150" data-aos="fade-up">
        <div className="row d-flex my-5" data-aos="fade-up">
          <div className="col-lg-6 col-12 ">
            <div className="d-lg-block d-flex  flex-column align-items-center">
              <h1 className="playfair heading-color font54">Floor Plan</h1>
              <p className="font20 pt-3 inter text-center text-lg-start">
                Experience the finest floor plan with our unparalleled
                expertise, ensuring optimal functionality and aesthetic appeal
                tailored to your needs and preferences.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-12 d-flex justify-content-center justify-content-lg-end ">
            <button
              className="label-btn h56 px-3 py-2 mt-lg-5"
              onClick={handleViewPlan}
            >
              View Full Plan
              <FontAwesomeIcon className="ms-2" icon={faArrowCircleRight} />
            </button>
          </div>
        </div>
        <div className="row">
       {(() => {
  let floorPlanUrl = selectedFloorPdf?.image_url;

  // Set the correct floor plan URL based on the model title
  if (modelInfo.modelTitle === 'Preserve#1') {
    floorPlanUrl = ModelFloorPlan[0]?.floorPath;
  } else if (modelInfo.modelTitle === 'Preserve#2') {
    floorPlanUrl = ModelFloorPlan[1]?.floorPath;
  } else if (modelInfo.modelTitle === 'Preserve#3') {
    floorPlanUrl = ModelFloorPlan[2]?.floorPath;
  } else if (modelInfo.modelTitle === 'model#39') {
    floorPlanUrl = ModelFloorPlan[3]?.floorPath;
  } else if (modelInfo.modelTitle === 'model#445') {
    floorPlanUrl = ModelFloorPlan[4]?.floorPath;
  } else if (modelInfo.modelTitle === 'model#448') {
    floorPlanUrl = ModelFloorPlan[5]?.floorPath;
  }

  // Render the iframe if the floor plan URL is found, otherwise show fallback message
  return floorPlanUrl ? (
    <div style={{ height: '750px', width: '100%' }}>
      <iframe
        src={floorPlanUrl}
        width="100%"
        height="100%"
      />
    </div>
  ) : (
    <div className="d-flex bgwhite col-lg col-12 py-5 px-3 font32 rounded justify-content-center align-items-center">
      <p>No Floor Plan Found</p>
    </div>
  );
})()}

        </div>
      </div>





      <div className="customContainer mt150" data-aos="fade-up">
        <div className="row d-flex my-5" data-aos="fade-up">
          <div className="col-lg-6 col-12 ">
            <div className="d-lg-block d-flex  flex-column align-items-center">
              <h1 className="playfair heading-color font54">Brochure</h1>
              <p className="font20 pt-3 inter text-center text-lg-start">
                Experience the finest brochure with our unparalleled
                expertise, ensuring optimal functionality and aesthetic appeal
                tailored to your needs and preferences.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-12 d-flex justify-content-center justify-content-lg-end ">
            <button
              className="label-btn h56 px-3 py-2 mt-lg-5"
              onClick={handleDownloadBrochure}
            >
              View Brochure
              <FontAwesomeIcon className="ms-2" icon={faArrowCircleRight} />
            </button>
          </div>
        </div>
        <div className="row">
        {(() => {
  let BrochureUrl = selectedBrochurePdf?.image_url;

  // Set the correct brochurePath based on the model title
  if (modelInfo.modelTitle === 'Preserve#1') {
    BrochureUrl = ModelBrochure[0]?.brochurePath;
  } else if (modelInfo.modelTitle === 'Preserve#2') {
    BrochureUrl = ModelBrochure[1]?.brochurePath;
  } else if (modelInfo.modelTitle === 'Preserve#3') {
    BrochureUrl = ModelBrochure[2]?.brochurePath;
  } else if (modelInfo.modelTitle === 'model#39') {
    BrochureUrl = ModelBrochure[3]?.brochurePath;
  } else if (modelInfo.modelTitle === 'model#445') {
    BrochureUrl = ModelBrochure[4]?.brochurePath;
  } else if (modelInfo.modelTitle === 'model#448') {
    BrochureUrl = ModelBrochure[5]?.brochurePath;
  }

  // Render the iframe if the brochure URL is found, otherwise show fallback message
  return BrochureUrl ? (
    <div style={{ height: '750px', width: '100%' }}>
      <iframe
        src={BrochureUrl}
        width="100%"
        height="100%"
      />
    </div>
  ) : (
    <div className="d-flex bgwhite col-lg col-12 py-5 px-3 font32 rounded justify-content-center align-items-center">
      <p>No Brochure Found</p>
    </div>
  );
})()}

        </div>
      </div>






      

      <div
        className="d-flex flex-column align-items-center mt150 customContainer mb-5"
        data-aos="fade-up"
      >
        <h1 className="playfair heading-color font54" data-aos="fade-up">
          Virtual Tour
        </h1>
        <p
          className="inter font20 mt-3 mb-5 text-center"
          style={{ maxWidth: '791px' }}
        >
          There is nothing that compares to experiencing one of our homes in
          person, but our in-depth Virtual Tours are the next best thing.
          Journey through the 3D space at your own pace and take in every
          finishing detail.
        </p>

        {imageObject ? (
          <iframe
            ref={iframeRef}
            width="100%"
            height="750"
            src={imageObject.imageUrl}
            title="3D Video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <div className="d-flex bgwhite col-lg col-12 py-5 px-3 font32 rounded justify-content-center align-items-center">
            <p>No Virtual Tour Available</p>
          </div>
        )}
      </div>

      {/* <div className="customContainer mt150 mb-5" data-aos="fade-up">
        <div className="row my-5">
          <div className=" d-lg-block d-flex flex-column align-items-center">
            <div>
              <h1 className="playfair heading-color font54 text-lg-start text-center" data-aos="fade-up">
                Property Elevations
              </h1>
              <p className="font20 pt-3 inter text-lg-start text-center"  style={{ maxWidth: "721px" }}>
                Discover detailed property elevations revealing the home's
                architectural design, maximizing space and complementing the
                surrounding landscape.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <img
              src={require("../images/Pre1.jpg")}
              alt="Background Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              className=" img-fluid rounded-3"
            />
          </div>
          <div className="col-6">
            <img
              src={require("../images/Pre2.jpg")}
              alt="Background Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              className=" img-fluid rounded-3"
            />
          </div>
        </div>
      </div> */}

      {/* <Footer /> */}
      <ScrollToTopButton />
    </>
  );
};

export default ModelDesc;
