// import img1 from "./Model448_Images/5_005 - 448 golden bear pass dahlonega ga.webp";
import img2 from "./Model448_Images/7_007 - 448 golden bear pass dahlonega ga.webp";
import img3 from "./Model448_Images/8_008 - 448 golden bear pass dahlonega ga.webp";
import img4 from "./Model448_Images/18_018 - 448 golden bear pass dahlonega ga.webp";
import img5 from "./Model448_Images/20_020 - 448 golden bear pass dahlonega ga.webp";
import img6 from "./Model448_Images/23_023 - 448 golden bear pass dahlonega ga.webp";
import img7 from "./Model448_Images/26_026 - 448 golden bear pass dahlonega ga.webp";
import img8 from "./Model448_Images/35_035 - 448 golden bear pass dahlonega ga.webp";
import img9 from "./Model448_Images/37_037 - 448 golden bear pass dahlonega ga.webp";
import img10 from "./Model448_Images/39_039 - 448 golden bear pass dahlonega ga.webp";
import img11 from "./Model448_Images/40_040 - 448 golden bear pass dahlonega ga.webp";
import img12 from "./Model448_Images/42_042 - 448 golden bear pass dahlonega ga.webp";
import img13 from "./Model448_Images/46_046 - 448 golden bear pass dahlonega ga.webp";
import img14 from "./Model448_Images/50_050 - 448 golden bear pass dahlonega ga.webp";
import img15 from "./Model448_Images/58_058 - 448 golden bear pass dahlonega ga.webp";

const Model448 = [


//   { "SNO": 0,
//     "id": 3,
//     "imgType": "Exterior Front",
//     "imgPath": frontElevation
// },

  
  // {
  //   "SNO": 1,
  //   "id": 8,
  //   "imgType": "Hall Way",
  //   "imgPath": img1
  // },
  {
    "SNO": 2,
    "id": 8,
    "imgType": "Hall Way",
    "imgPath": img2
  },
  {
    "SNO": 3,
    "id": 8,
    "imgType": "Hall Way",
    "imgPath": img3
  },
  {
    "SNO": 4,
    "id": 19,
    "imgType": "Kitchen",
    "imgPath": img4
  },
  {
    "SNO": 5,
    "id": 16,
    "imgType": "Appliances",
    "imgPath": img5
  },
  {
    "SNO": 6,
    "id": 19,
    "imgType": "Kitchen",
    "imgPath": img6
  },
  {
    "SNO": 7,
    "id": 16,
    "imgType": "Appliances",
    "imgPath": img7
  },
  {
    "SNO": 8,
    "id": 21,
    "imgType": "Bath#1",
    "imgPath": img8
  },
  {
    "SNO": 9,
    "id": 11,
    "imgType": "Master Bedroom",
    "imgPath": img9
  },
  {
    "SNO": 10,
    "id": 11,
    "imgType": "Master Bedroom",
    "imgPath": img10
  },
  {
    "SNO": 11,
    "id": 11,
    "imgType": "Master Bedroom",
    "imgPath": img11
  },
  {
    "SNO": 12,
    "id": 11,
    "imgType": "Master Bedroom",
    "imgPath": img12
  },
  {
    "SNO": 13,
    "id": 8,
    "imgType": "Hall Way",
    "imgPath": img13
  },
  {
    "SNO": 14,
    "id": 43,
    "imgType": "Basement",
    "imgPath": img14
  },
  {
    "SNO": 15,
    "id": 24,
    "imgType": "Sit Out",
    "imgPath": img15
  }
];

export default Model448;
