import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../Constant/Routes';

function ScrollTop() {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (!hash) {
      if (pathname === ROUTES.SHOW_MODELS || pathname === ROUTES.PROJECTS) {
        window.scrollTo(0, 0);
        // Scroll to a lower point of the page if the pathname matches
        setTimeout(() => {
          const lowerOffset = 940; // Define the lower offset as 950 pixels from the top
          window.scrollTo({ top: lowerOffset, behavior: 'smooth' });
        }, 400);
      }  else if (pathname === ROUTES.SPECIFICATIONS) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          const lowerOffset = 840; // Define the lower offset as 950 pixels from the top
          window.scrollTo({ top: lowerOffset, behavior: 'smooth' });
        }, 400);
      }

      else if (pathname === ROUTES.ABOUT_US) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          const lowerOffset = 820; // Define the lower offset as 950 pixels from the top
          window.scrollTo({ top: lowerOffset, behavior: 'smooth' });
        }, 400);
      }

      else if (pathname === ROUTES.PROPERTY_MODELS) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          const lowerOffset = 900; // Define the lower offset as 950 pixels from the top
          window.scrollTo({ top: lowerOffset, behavior: 'smooth' });
        }, 400);
      }
      else if (pathname === ROUTES.CONTACT_US) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          const lowerOffset = 960; // Define the lower offset as 950 pixels from the top
          window.scrollTo({ top: lowerOffset, behavior: 'smooth' });
        }, 400);
      }
      else if (pathname === ROUTES.PROPERTY_DESC) {
        window.scrollTo(0, 0);
      } else if (pathname === ROUTES.MODEL_DESC) {
        window.scrollTo({ top: 800 });
      } 
       else {
        // Scroll to the top for all other paths
        window.scrollTo(0, 0);
      }
    } else {
      const element = document.getElementById(hash.slice(1)); // Remove the '#' from the hash
      if (element) {
        const topOffset =
          element.getBoundingClientRect().top + window.pageYOffset - 100;
        // Scroll to the top of the page with an offset to bring the element into view
        window.scrollTo({ top: topOffset, behavior: 'smooth' });
      }
    }
  }, [pathname, hash]);

  return null;
}

export default ScrollTop;
