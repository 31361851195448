import axios from 'axios'
import interceptorSetup from './httpInterceptor.js'

const callaxios = axios.create({
	baseURL: "https://apiv1.worknotion.com",
	headers:{
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin' : "https://apiv1.worknotion.com",
        'Access-Control-Allow-Methods' :  "POST, GET, OPTIONS, PUT, DELETE",
        'Access-Control-Allow-Headers' :  "Content-Type, X-Auth-Token, Origin, Authorization, UserId"
    },
    mode: 'no-cors'
    
});



interceptorSetup(callaxios)

export default callaxios