import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
// import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mapPin from '../images/homeicon.png';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {
  // faArrowLeft,
  // faArrowRight,
  faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import ScrollToTopButton from '../Components/ScrollToTop';
import 'aos/dist/aos.css'; // Import AOS CSS
import AOS from 'aos';


// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
// import { ReactComponent as SliderArrow } from '../images/slider-next-arrow.svg';
import { ReactComponent as SalesIcon } from '../images/sales.svg';
import httpproperties from '../js/http-realestate-properties';
import Spinner from 'react-bootstrap/Spinner';
import PropertyDetails from '../Pages/PropertyDetails.json';
import SuwameeSales from '../Pages/SuwaneeSales.json';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { ROUTES } from '../Constant/Routes';
import PropertyId from '../Pages/PropertyId.json';
import Preserve1 from "../images/ModelImages/Preserve1/Preserve1"
import Preserve2 from "../images/ModelImages/Preserve2/Preserve2"
import Preserve3 from "../images/ModelImages/Preserve3/Preserve3"
import SuwaneePreserves from "../images/PropertyImages/SuwaneePreserves/SuwaneePreserves";
import Prop448 from "../images/PropertyImages/Prop448/Prop448";
import Prop39 from "../images/PropertyImages/Prop39/Prop39";
import Prop445 from "../images/PropertyImages/Prop445/Prop445";
import Model448 from "../images/ModelImages/Model448/Model448"
import Model39 from "../images/ModelImages/Model39/Model39"
import Model445 from "../images/ModelImages/Model445/Model445"
import PropertyBrochure from '../images/Brochure/PropertyBrochure';
import PropertySitePlan from '../images/Site and Floor Plans/PropertySitePlan';
import SEO from '../Constant/SEO.json';
import PageSeoComponent from '../SEO';

const PropertyDesc = () => {
  const location = useLocation();
  const propertyId =
    location.state?.propertyInfo?.property_id || PropertyId.pId;
  const [propertyInfo, setPropertyInfo] = useState({});
  const [linkedModels, setLinkedModels] = useState([]);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [expandedDescriptionIndex, setExpandedDescriptionIndex] =
    useState(null);
  const [loading, setLoading] = useState(0);
  const [images, setImages] = useState([]);
  // const [floorPdfUrl, setfloorPdfUrl] = useState(null);
  const iframeRef = useRef(null);
  const [selectedBrochurePdf, setSelectedBrochurePdf] = useState(null);
  const [selectedFloorPdf, setSelectedFloorPdf] = useState(null);
  const [imageObject, setImageObject] = useState({});
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(true);
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showMap, setShowMap] = useState(false);

  const description = propertyInfo.community_description || '';
  const words = description ? description.split(' ') : [];

  const shouldShowMore = words.length > 30;

  const displayedDescription = showMore
    ? description
    : words.slice(0, 30).join(' ') + (shouldShowMore ? '...' : '');

  AOS.init({
    duration: 600, // Animation duration
    easing: 'ease-in-out', // Easing function
  });

  const propertyIcon = L.icon({
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    iconUrl: require('../images/homeicon.png'),
  });

  const openImageViewer = (index) => {
    console.log('Image clicked:', index);
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const galleryImages = (() => {
    if (propertyInfo.property_title === 'Suwanee Preserves') {
      return SuwaneePreserves.map(image => ({
        original: image.imgPath,
        thumbnail: image.imgPath,
      }));
      
    } else if (propertyInfo.property_title === '1#Dahlonega-GA-30533-USA') {
      return Prop448.map(image => ({
        original: image.imgPath,
        thumbnail: image.imgPath,
      }));
    } 
    
    else if (propertyInfo.property_title === '2#Dahlonega-GA-30533-USA') {
      return Prop39.map(image => ({
        original: image.imgPath,
        thumbnail: image.imgPath,
      }));
    }


    else if (propertyInfo.property_title === '3#Dahlonega-GA-30533-USA') {
      return Prop445.map(image => ({
        original: image.imgPath,
        thumbnail: image.imgPath,
      }));
    }
    
    
    else {
      return images.map(image => ({
        original: image,
        thumbnail: image,
      }));
    }
  })();
  
   // Default to the galleryImages if no modelTitle matches

       
  // const moveNext = () => {
  //   setCurrentImage((currentImage + 1) % images.length);
  // };

  // const movePrev = () => {
  //   setCurrentImage((currentImage + images.length - 1) % images.length);
  // };

  const cityLabel = (city) =>
    L.divIcon({
      className: 'city-label',
      html: `<img src="${mapPin}" width="32" height="32" title="${city}"> <span>${city}</span>`,
      // html: `<span>${city}</span>`
    });

  function MapCenter({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  useEffect(() => {
    if (linkedModels.length === 0) {
      setLoading(1);
      setError('No records found');
    } else {
      setLoading(1); // You can set this to 0 or any other value that indicates not loading
      setError(null);
    }
  }, [linkedModels]);

  function handleModelDesc(models) {
    navigate(ROUTES.MODEL_DESC, { state: { modelInfo: models } });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMap(true);
    }, 1500);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const getSelectedProp = async (propId) => {
    await httpproperties
      .get(
        `/propertyManagement/getPropertyListByIdOrTitle?propertyId=${propId}`
      )
      .then((result) => {
        console.log(result.data, 'Updating Prop. Details:');
        setPropertyInfo(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handlePropertyModels(linkedModels) {
    navigate(ROUTES.PROPERTY_MODELS, {
      state: { linkedModelsInfo: linkedModels },
    });
  }

  const getLinkedModels = (propId) => {
    httpproperties
    .get(`/modelInformation/getModelsByPropertyId?propertyId=${propId}`)
    .then((result) => {
      console.log(result.data, 'Linked Models');
  
      // Sort the linked models in ascending order by a specific field, e.g., 'modelName'
      const sortedLinkedModels = result.data.sort((a, b) => {
        // Replace 'modelName' with the field you want to sort by
        return a.modelTitle.localeCompare(b.modelTitle);
      });
  
      setLinkedModels(sortedLinkedModels);
    })
    .catch((err) => {
      console.log(err, 'ERRRR');
    });
  };


  const handleToggleDescription = (index) => {
    if (expandedDescriptionIndex === index) {
      setExpandedDescriptionIndex(null);
    } else {
      setExpandedDescriptionIndex(index);
    }
  };


  useEffect(() => {
    console.log(propertyInfo, 'ioioioio');
    getSelectedProp(propertyId);
    getLinkedModels(propertyId);
    getDocuments();
  }, [propertyId]);

  useEffect(() => {
    if (propertyInfo?.imageSrcLink?.length > 0) {
      setImageObject(
        propertyInfo.imageSrcLink.find((image) => image.imgId === 9)
      );
    }
  }, [propertyInfo]);

  useEffect(() => {
    // Set images from propertyInfo once component mounts
    if (propertyInfo && propertyInfo.imageSrcLink) {
      setImages(
        propertyInfo.imageSrcLink
          .filter(
            (image) =>
              image.imgId !== 9 && image.imgId !== 12 && image.imgId !== 17
          )
          .map((image) => image.imageUrl)
      );
    }

    // const planImage = propertyInfo?.imageSrcLink?.find(image => image.imgId === 12);
    // if (planImage && planImage.imageUrl) {
    //   setfloorPdfUrl(planImage.imageUrl);
    //   console.log(planImage.imageUrl, 'PDFPDFPDFPDF')
    // } else {
    //   console.error('Plan image not found or invalid URL');
    // }
  }, [propertyInfo]);

  const navigate = useNavigate();

  function contactUs(property) {
    navigate(ROUTES.CONTACT_US, { state: { propertyInfo: property } });
  }

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const getValidImageUrl = (imageSrcLink) => {
    const filteredImages = imageSrcLink
      ? imageSrcLink.filter(
          (image) =>
            image.imgId !== 9 && image.imgId !== 12 && image.imgId !== 17
        )
      : [];
    return filteredImages.length > 0 ? filteredImages[0].imageUrl : '';
  };

  const getDocuments = () => {
    ['Site Plan', 'Specification'].forEach(async (docType, index) => {
      await httpproperties
        .get(
          `/propertyManagement/getUrlByImgType?imgType=${docType}&propertyId=${propertyId}`
        )
        .then((res) => {
          console.log(res, 'ddddddddddddddddd');
          if (index === 0) {
            setSelectedFloorPdf(res.data);
          }
          if (index === 1) {
            console.log(res.data, 'zzzzzzzzzzzzz');
            setSelectedBrochurePdf(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleDownloadBrochure = () => {
    let brochureImage;
  
    // Check the property title and set the appropriate brochure path
    if (propertyInfo.property_title === 'Suwanee Preserves') {
      brochureImage = PropertyBrochure[0].brochurePath;
    } else if (propertyInfo.property_title === '1#Dahlonega-GA-30533-USA') {
      brochureImage = PropertyBrochure[1].brochurePath;
    } else if (propertyInfo.property_title === '2#Dahlonega-GA-30533-USA') {
      brochureImage = PropertyBrochure[2].brochurePath;
    } else if (propertyInfo.property_title === '3#Dahlonega-GA-30533-USA') {
      brochureImage = PropertyBrochure[3].brochurePath;
    } else {
      // Use the original selectedBrochurePdf if the property doesn't match any of the four
      brochureImage = selectedBrochurePdf ? selectedBrochurePdf.image_url : null;
    }
  
    // Proceed with the same logic if brochureImage is found
    if (brochureImage) {
      const link = document.createElement('a');
      link.href = brochureImage; // Directly use brochureImage as the href
      link.target = '_blank'; // Open the link in a new tab
      link.rel = 'noopener noreferrer'; // Security measure
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Brochure image not found or invalid URL');
    }
  };
  

  const handleViewPlan = () => {
    let planImageUrl;
  
    // Check the property title and set the appropriate plan path
    if (propertyInfo.property_title === 'Suwanee Preserves') {
      planImageUrl = PropertySitePlan[0].sitePath;
    } else if (propertyInfo.property_title === '1#Dahlonega-GA-30533-USA') {
      planImageUrl = PropertySitePlan[1].sitePath;
    } else if (propertyInfo.property_title === '2#Dahlonega-GA-30533-USA') {
      planImageUrl = PropertySitePlan[2].sitePath;
    }
    else if (propertyInfo.property_title === '3#Dahlonega-GA-30533-USA') {
      planImageUrl = PropertySitePlan[3].sitePath;
    } 
     else {
      // Use the original selectedFloorPdf if the property doesn't match any of the four
      planImageUrl = selectedFloorPdf ? selectedFloorPdf.image_url : null;
    }
  
    // Proceed with the same logic if planImageUrl is found
    if (planImageUrl) {
      const link = document.createElement('a');
      link.href = planImageUrl; // Directly use planImageUrl as the href
      link.target = '_blank'; // Open the link in a new tab
      link.rel = 'noopener noreferrer'; // Security measure
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Plan image not found or invalid URL');
    }
  };
  

  const scrollToVideo = () => {
    // Check if iframeRef.current exists before scrolling
    if (iframeRef.current) {
      iframeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Check if the imageSrcLink array exists and has elements before accessing the first element
  
  const firstImage = (() => {
    if (propertyInfo.property_title === 'Suwanee Preserves') {
      return SuwaneePreserves.find(image => image.SNO === 1)?.imgPath || "";
    }
    
    else if (propertyInfo.property_title === '1#Dahlonega-GA-30533-USA') {
      return Prop448.find(image => image.SNO === 1)?.imgPath || "";
    }
    
    else if (propertyInfo.property_title === '2#Dahlonega-GA-30533-USA') {
      return Prop39.find(image => image.SNO === 4)?.imgPath || "";
    }


    else if (propertyInfo.property_title === '3#Dahlonega-GA-30533-USA') {
      return Prop445.find(image => image.SNO === 5)?.imgPath || "";
    }


     else if (propertyInfo.imageSrcLink && propertyInfo.imageSrcLink.length > 0) {
      return propertyInfo.imageSrcLink.find(image => (
        image.imgId !== 9 && image.imgId !== 12 && image.imgId !== 17
      ))?.imageUrl || "";
    }
    
    else {
      return "";
    }
  })();

  return (
    <>
      <PageSeoComponent {...SEO['luxury-properties']} />

      {/* <Helmet>
        <title>Property Description-{`${propertyInfo.property_title}`}</title>
        <meta
          name="description"
          content={`Explore the ${propertyInfo.property_title}, a premier luxury property offered by KR Real Estate Developers LLC in Cumming, GA. Discover its features, specifications, and why it's perfect for your dream home.`}
        />
        <meta
          name="keywords"
          content="single family home, luxury homes, custom homes, luxury custom homes, suwanee preserves, cumming custom luxury homes, sky lane, new homes in cumming, new custom luxury homes in cumming, new custom luxury homes, Luxury Homes Cumming GA, Luxury Real Estate Cumming Georgia, High-End Homes Cumming GA, Luxury Home Builders Cumming GA, New Luxury Homes in Cumming GA, Luxury Houses for Sale in Cumming GA, Custom Luxury Homes Cumming GA, Luxury Homes Forsyth County GA, Upscale Homes Cumming Georgia, Premium Properties Cumming GA, Luxury Residences Cumming GA, Gated Community Homes Cumming GA, Luxury Lakefront Homes Cumming GA, Modern Luxury Homes Cumming GA, Executive Homes Cumming GA, Luxury Living Cumming GA, Top Luxury Home Builders in Cumming GA, Exclusive Homes Cumming GA, Luxury Home Communities Cumming GA, Luxury Home Listings Cumming GA, Custom Home Construction Georgia, Custom Luxury Homes in Georgia, High-End Custom Homes Georgia, Luxury Custom Houses for Sale in Georgia, New Custom Home Developments Georgia, Custom Homes Forsyth County, High-End Custom Real Estate Georgia, Premium Custom Homes Georgia, Custom Residences in Georgia, Custom Gated Community Homes in Georgia, Custom Luxury Lakefront Homes Georgia, Executive Custom Homes Georgia, Top Custom Home Builders in Georgia, Custom Home Communities in Georgia, Custom Home Listings in Georgia"
        />
        <link
          rel="canonical"
          href="https://www.krhomes.net/property-description"
        />
      </Helmet> */}

      <Navbar
        propertyInfo={propertyInfo}
        class="position-fixed d-flex justify-content-between zi100 py-2 w-100  greybg px-5"
      />

      <div className="" style={{ position: 'relative', height: '70%' }}>
        <img
          src={require('../images/PP2.webp')}
          alt="Background Image"
          className="background-propertydesc-image"
        />

        <div
          style={{
            position: 'absolute',
            top: '65%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: '10',
          }}
        >
          <h6 className="py-2 mb-5 inter font20 rounded-2 salesBlue">
            {propertyInfo.special_notes}
          </h6>
          <h1>{propertyInfo.property_title}</h1>
        </div>
      </div>


    {propertyInfo.property_title === 'Suwanee Preserves' &&
      <div className="salesBlue">
        <div className="row py-4">
          <div className="col-12 d-md-flex justify-content-around">
            <div>
              <div className="d-flex mb-1">
                <SalesIcon className="" />

                <h1 className="inter white font20 mt-1 mb-md-0 mb-2">
                  Sales Team
                </h1>
              </div>

              {propertyInfo.property_title === 'Suwanee Preserves' ? (
                <div className="d-lg-flex my-md-3 my-3">
                  <div className="mb-md-0 mb-3">
                    <h2 className="inter font16 white ms-2">
                      {SuwameeSales.SP1} | {SuwameeSales.SP1Email}
                    </h2>
                    <h3 className="inter font16 white ms-2">
                      {SuwameeSales.SP1Contact}
                    </h3>
                  </div>

                  <div className="ms-2">
                    <h2 className="inter font16 white ms-lg-2">
                      {SuwameeSales.SP2} | {SuwameeSales.SP2Email}
                    </h2>
                    <h3 className="inter font16 white ms-lg-2">
                      {SuwameeSales.SP2Contact}
                    </h3>
                  </div>
                </div>
              ) : (
                <div>
                  <h3 className="inter font16 white ms-2">
                    {propertyInfo.salesPersonDetails?.[0]?.first_name}{' '}
                    {propertyInfo.salesPersonDetails?.[0]?.last_name} |{' '}
                    {propertyInfo.salesPersonDetails?.[0]?.email_id}
                  </h3>
                  <h3 className="inter font16 white ms-2">
                    {propertyInfo.salesPersonDetails?.[0]?.contact_number}
                  </h3>
                </div>
              )}
            </div>

            <div className="d-flex mt-md-3">
              {/* <button
    className="sales-btn h56 mx-3  px-3 py-2 "
    onClick={() => contactUs(propertyInfo)}
  >
    Chat
  </button> */}
              <button
                className="sales-btn h56 mx-3  px-3 py-2 "
                onClick={() => (window.location.href = 'tel:01114707079598')}
              >
                Call
              </button>
              <button
                className="sales-btn h56 mx-3  px-3 py-2 "
                onClick={() => contactUs(propertyInfo)}
              >
                Email
              </button>
            </div>
          </div>
        </div>
      </div>}

      <div
        className="customContainer shadow pb-4 mt150 bg-white rounded-3 "
        data-aos="fade-up"
      >
        <div className="row pt-4 px-3 h-100">
          <div className="col-lg-6 col-12">
            {/* Display the first image available in the API response */}
            <img
              src={firstImage}
              alt="Property Image"
              style={{ width: '100%', height: '460px', objectFit: 'cover' }}
              className="img-fluid img-custom rounded-3"
            />
          </div>
          <div className="col-lg-6 col-12 mt-lg-0 mt-4">
            <div className="row">
              <div className="col-6">
                <h4 className="font40 playfair heading-color">
                  {propertyInfo.property_title}
                </h4>
                <div className="d-flex mt-3">
                  <img
                    src={require('../images/area.png')}
                    alt="Half Bath Icon"
                    className="me-2 mt-1 h22"
                  />
                  <h6
                    className="mt-1 inter Lgrey font20"
                    style={{ fontWeight: 600 }}
                  >
                    {propertyInfo.property_area_range}
                  </h6>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <h5
                  className="mt-1 inter font32 Lgrey"
                  style={{ fontWeight: 700 }}
                >
                  {propertyInfo.prices_range}
                </h5>
              </div>
            </div>

            <div className="row grey p-2 rounded-3 mt-4 me-4 ms-1 ">
              <div className="col d-flex align-items-center">
                <img
                  src={require('../images/bed-alt.png')}
                  alt="Bedroom Icon"
                  className="me-1"
                />
                <h6 className="mt-2 mx-2 inter fw600 Lgrey font26">
                  {propertyInfo.no_of_bedrooms_range}{' '}
                </h6>
                <p className="inter font20 mt-3">Bedrooms</p>
              </div>
              <div className="col d-flex align-items-center">
                <img
                  src={require('../images/bath.png')}
                  alt="Bathroom Icon"
                  className="me-1"
                />
                <h6 className="mt-2 mx-2 inter fw600 Lgrey font26">
                  {propertyInfo.number_of_baths_range}
                </h6>
                <p className="inter font20 mt-3">Bathrooms</p>
              </div>
              <div className="col d-flex align-items-center">
                <img
                  src={require('../images/building.png')}
                  alt="Half Bath Icon"
                  className="me-2"
                />
                <h6 className="mt-2 mx-2 inter fw600 Lgrey font26">
                  {propertyInfo.number_of_story_range}
                </h6>
                <p className="inter font20 mt-3">Stories</p>
              </div>
              <div className="col d-flex align-items-center">
                <img
                  src={require('../images/garage-car.png')}
                  alt="Garage Icon"
                  className="me-1"
                />
                <h6 className="mt-2 mx-2 inter fw600 Lgrey font26">
                  {propertyInfo.number_of_garages_range}
                </h6>
                <p className="inter font20 mt-3">Garage</p>
              </div>
            </div>

            <div className="row mt-4">
              <p className="font18 inter">
                {displayedDescription}
                {shouldShowMore && (
                  <span
                    className="text-primary ms-2"
                    onClick={() => setShowMore(!showMore)}
                    style={{ cursor: 'pointer', fontWeight: 600 }} // Makes "More" text bold
                  >
                    {showMore ? 'Show Less' : 'More'}
                  </span>
                )}
              </p>
            </div>
            <div className="row mt-3">
              <div className="col d-flex flex-column d-lg-block">
                <button
                  className="label-btn h56 px-3 me-lg-3 mb-lg-3 py-2 "
                  onClick={handleDownloadBrochure}
                >
                  Download Brochure
                </button>
                <button
                  className="label-btn h56  px-3 py-2 my-lg-0 my-3 "
                  onClick={scrollToVideo}
                >
                  Virtual Tour
                </button>
                <button
                  className="label-btn h56 mx-3  px-3 py-2 "
                  onClick={() => handlePropertyModels(linkedModels)}
                >
                  View Models
                </button>
                <button
                  onClick={() => contactUs(propertyInfo)}
                  className="label-btn  h56 mt-lg-0 mt-3 px-3 py-2 "
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-column align-items-center mt150"
        data-aos="fade-up"
      >
        <h1 className="playfair heading-color font54 mb-5">Gallery</h1>
        {/* <p
          className="inter font20 mt-3 mb-4 text-center "
          style={{ maxWidth: "498px" }}
        >
          Immerse yourself in luxury. Discover inspiring photos and virtual
          tours of your dream home.
        </p> */}
      </div>

      <div>
        {/* <button onClick={() => openImageViewer(0)}>Open Lightbox</button> */}
      </div>

      <div className="property-desc-slider" data-aos="fade-up">
        <div className="container w-75">
          {/* <Swiper
          slidesPerView={1}
          autoHeight={true}
          pagination={{
            type: 'fraction',
            el: '.property-dot',
            clickable: true,
          }}
          navigation={{
            nextEl: '.property-arrow-right',
            prevEl: '.property-arrow-left',
          }}
          modules={[Pagination, Navigation]}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image}
                alt={`Gallery Image ${image}`}
                onClick={() => openImageViewer(index)}
                style={{ cursor: 'pointer' }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="slider-actions">
          <button
            type="button"
            className="slider-action-arrow slider-action-arrow--left property-arrow-left"
            aria-label="prev arrow"
          >
            <SliderArrow />
          </button>
          <div className="slider-action-dot property-dot" />
          <button
            type="button"
            className="slider-action-arrow slider-action-arrow--right property-arrow-right"
            aria-label="next arrow"
          >
            <SliderArrow />
          </button>
        </div> */}
          {isViewerOpen && (
            <ImageGallery
              items={galleryImages}
              startIndex={currentImage}
              showFullscreenButton={true}
              showPlayButton={false}
              onSlide={(index) => setCurrentImage(index)}
              onClose={() => setIsViewerOpen(false)}
            />
          )}
        </div>
      </div>

      <div className="customContainer mt150" data-aos="fade-up">
        <h1 className="playfair heading-color mb-5 font54">
          Why You Will Love This Community
        </h1>

        <div className="row">
          <div className="col-md col-12">
            <img
              className=" img-fluid"
              src={require('../images/sidePic.jpg')}
              alt="demoPic"
            />
          </div>

          <div className="col-md col-12">
            <ul>
              <li className="inter font24 mb-4 mt-lg-0 mt-4">
                {PropertyDetails.PropDesc1}
              </li>
              <li className="inter font24 mb-4">{PropertyDetails.PropDesc2}</li>
              <li className="inter font24 mb-4">{PropertyDetails.PropDesc3}</li>
              <li className="inter font24 mb-4">{PropertyDetails.PropDesc4}</li>
              <li className="inter font24 mb-4">{PropertyDetails.PropDesc5}</li>
              <li className="inter font24">{PropertyDetails.PropDesc6}</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="customContainer mt150" data-aos="fade-up">
        {/* <h1 className="playfair heading-color font54">Amenities</h1> */}
      </div>
      <div className="customContainer mt150" data-aos="fade-up">
        <div className="row d-flex my-5">
          <div className="col-lg-6 col-12 ">
            <div className="d-lg-block d-flex  flex-column align-items-center">
              <h1 className="playfair heading-color font54">Site Plan</h1>
              <p className="font20 pt-3 inter text-center text-lg-start">
                Experience the finest floor plan with our unparalleled
                expertise, ensuring optimal functionality and aesthetic appeal
                tailored to your needs and preferences.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-12 d-flex justify-content-center justify-content-lg-end ">
            <button
              className="label-btn h56 px-3 py-2 mt-lg-5"
              onClick={handleViewPlan}
            >
              View Full Plan
              <FontAwesomeIcon className="ms-2" icon={faArrowCircleRight} />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
          {(() => {
  let sitePlanUrl = selectedFloorPdf?.image_url;

  // Set the correct sitePath based on the property title
  if (propertyInfo.property_title === 'Suwanee Preserves') {
    sitePlanUrl = PropertySitePlan[0]?.sitePath;
  } else if (propertyInfo.property_title === '1#Dahlonega-GA-30533-USA') {
    sitePlanUrl = PropertySitePlan[1]?.sitePath;
  } else if (propertyInfo.property_title === '2#Dahlonega-GA-30533-USA') {
    sitePlanUrl = PropertySitePlan[2]?.sitePath;
  } else if (propertyInfo.property_title === '3#Dahlonega-GA-30533-USA') {
    sitePlanUrl = PropertySitePlan[3]?.sitePath;
  }

  // Render the iframe if the site plan URL is found, otherwise show fallback message
  return sitePlanUrl ? (
    <div style={{ height: '750px', width: '100%' }}>
      <iframe
        src={sitePlanUrl}
        width="100%"
        height="100%"
      />
    </div>
  ) : (
    <div className="d-flex bgwhite col-lg col-12 py-5 px-3 font32 rounded justify-content-center align-items-center">
      <p>No Site Plan Found</p>
    </div>
  );
})()}

          </div>

          <div className="col-12 d-flex flex-column mt-5  justify-content-center">
            <h1 className="playfair heading-color font40 ms-lg-2 mb-5">
              {PropertyDetails.AmenitiesHeading}
            </h1>
            <ul>
              <li className="inter Lgrey font20 mb-5 border-bottom pb-3">
                {PropertyDetails.Amenities1}
              </li>
              <li className="inter Lgrey font20 mb-5 border-bottom pb-3">
                {PropertyDetails.Amenities2}
              </li>
              <li className="inter Lgrey font20 mb-5 border-bottom pb-3">
                {PropertyDetails.Amenities3}
              </li>
              <li className="inter Lgrey font20 mb-5 border-bottom pb-3">
                {PropertyDetails.Amenities4}
              </li>
              <li className="inter Lgrey font20 mb-5 border-bottom pb-3">
                {PropertyDetails.Amenities5}
              </li>
              <li className="inter Lgrey font20 border-bottom pb-3">
                {PropertyDetails.Amenities6}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="customContainer mt150" data-aos="fade-up">
        <h1 className="playfair heading-color font54">Models</h1>
      </div>

      {loading === 0 ? (
        <div className="d-flex bgwhite col-lg col-12 py-5 px-3 rounded justify-content-center align-items-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : linkedModels.length === 0 ? (
        <div className="d-flex bgwhite col-lg col-12 py-5 px-3 rounded justify-content-center align-items-center">
          <p>No records found</p>
        </div>
      ) : (
        <div className="customContainer mt-5" data-aos="fade-up">
          <div className="row" data-aos="fade-up">
            {linkedModels.map((model, index) => (
              <div className="col mb-5" key={index}>
                <div
                  className="card mb-4 px-3 py-3 rounded-3"
                  style={{
                    borderRadius: '0.5px',
                    border: '0.5px solid #dddddd',
                  }}
                >
                  <p className="playfair playfair heading-color font32 mb-3 ">
                    {model.modelTitle}
                  </p>
                  <div className="Model-image imagek">
                  <div className="Model-image imagek">
  {model.modelTitle === 'Preserve#1' ? (
    <img
      src={Preserve1[0].imgPath}
      alt="modelImg"
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  ) : model.modelTitle === 'Preserve#2' ? (
    <img
      src={Preserve2[0].imgPath}
      alt="modelImg"
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  ) : model.modelTitle === 'Preserve#3' ? (
    <img
      src={Preserve3[0].imgPath}
      alt="modelImg"
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  )
  
  : model.modelTitle === 'model#448' ? (
    <img
      src={Model448[1].imgPath}
      alt="modelImg"
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  )

  : model.modelTitle === 'model#39' ? (
    <img
      src={Model39[6].imgPath}
      alt="modelImg"
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  )

  : model.modelTitle === 'model#445' ? (
    <img
      src={Model445[1].imgPath}
      alt="modelImg"
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  )
  
  : model.image_url ? (
    <img
      src={model.image_url}
      alt="modelImg"
      className="imagek rounded-3"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  ) : null}
</div>

            </div>
                  <div className="mt-2">
                    <div className="Model-details">
                      {/* <p className="playfair playfair heading-color font32 mb-0 ">
                  {model.modelTitle}
                </p> */}
                      <p
                        className="inter font20 Lgrey"
                        style={{ fontWeight: 700 }}
                      >
                        {model.price}
                      </p>
                      <p className="font16 inter Lgrey">
  {expandedDescriptionIndex === index
    ? model.modelDescription
    : model.modelDescription.substring(0, 100)}
  {model.modelDescription.length > 100 && (
    <>
      {' '}
      <a
        href="#"
        className="text-primary"
        style={{ cursor: 'pointer', fontWeight: 600, textDecoration: 'none', }}
        onClick={(e) => {
          e.preventDefault();
          handleToggleDescription(index);
        }}
      >
        {expandedDescriptionIndex === index ? ' Show Less' : '... More'}
      </a>
    </>
  )}
</p>
                      <div className="row d-flex justify-content-between mt-3">
                        <div className="d-flex col col-lg-7">
                          <div className="col d-flex align-items-center">
                            <img
                              src={require('../images/bed-alt.png')}
                              alt="Bedroom Icon"
                              className="me-1"
                            />
                            <h1
                              className="mt-2 ms-1 me-3 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {model.noOfBedrooms}
                            </h1>
                          </div>
                          <div className="col d-flex align-items-center">
                            <img
                              src={require('../images/bath.png')}
                              alt="Bathroom Icon"
                              className="me-1"
                            />
                            <h1
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {model.noOfBaths}
                            </h1>
                          </div>
                          <div className="col d-flex mx-md-0 mx-3 align-items-center">
                            <img
                              src={require('../images/building.png')}
                              alt="Half Bath Icon"
                              className="me-2"
                            />
                            <h1
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {model.noOfStories}
                            </h1>
                          </div>
                          <div className="col d-flex align-items-center">
                            <img
                              src={require('../images/garage-car.png')}
                              alt="Garage Icon"
                              className="me-1"
                            />
                            <h1
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {model.noOfGarages}
                            </h1>
                          </div>
                        </div>
                        <div className="col-12 col-lg-5 d-md-flex justify-content-lg-end">
                          <div className="d-flex align-items-center">
                            <img
                              src={require('../images/area.png')}
                              alt="Area Icon"
                              className="me-1"
                            />
                            <h1
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {model.modelArea}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1 col-md-1 p-1 p-md-0 ps-lg-3 mx-4"></div>
                  <hr className="mt-3 mb-3" /> {/* Line */}
                  <div className="d-flex justify-content-between">
                    <button
                     onClick={() => contactUs(propertyInfo)}
                      className="btn inter"
                      style={{ color: '#0064C2', fontWeight: 500 }}
                    >
                      Contact Us
                    </button>
                    <button
                      className="btn h56 btn-primary label-background-color px-4"
                      onClick={() => handleModelDesc(model)}
                    >
                      View Model
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div
        className="d-flex flex-column align-items-center mt150 customContainer mb-5"
        data-aos="fade-up"
      >
        <h1 className="playfair heading-color font54" data-aos="fade-up">
          Virtual Tour
        </h1>
        {/* <p
          className="inter font20 mt-3 mb-5 text-center"
          style={{ maxWidth: "791px" }}
        >
          There is nothing that compares to experiencing one of our homes in
          person, but our in-depth Virtual Tours are the next best thing.
          Journey through the 3D space at your own pace and take in every
          finishing detail.
        </p>  */}
        {imageObject ? (
        <iframe
        ref={iframeRef}
        width="100%"
        height="750"
        src={imageObject.imageUrl}
        title="Interactive 3D Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      
        ) : (
          <div className="d-flex bgwhite col-lg col-12 py-5 px-3 font32 rounded justify-content-center align-items-center">
            <p>No Virtual Tour Available</p>
          </div>
        )}
      </div>

      <div className="customContainer mt150" data-aos="fade-up">
        <h1 className="playfair text-start heading-color mb-5 font54">
          Neighborhood Guide
        </h1>

        <div className="row">
          <div className="col-lg-6 col-12 d-flex flex-column justify-content-center">
            <div>
              <h1 className="font40 Lgrey playfair heading-color">
                {propertyInfo.property_title}
              </h1>

              <div className="d-flex">
                <p className="inter mb-5 Lgrey font16">
                  {propertyInfo?.city?.cityName}
                </p>
                <p className="inter mb-5 Lgrey font16">
                  | {propertyInfo?.state?.stateName} |
                </p>
                <p className="inter mb-5 Lgrey font16">
                  {propertyInfo?.country?.countryName}
                </p>
              </div>

              <h6 className="inter Lgrey font16">
                SCHOOLS AND CULTURAL VENUES
              </h6>
              <div>
                <h6 className="inter black font20 ">Elementary School</h6>
                <h6 className="inter Lgrey font16 mb-5 border-bottom pb-2">
                  {propertyInfo?.neighborhoodInfo?.[0]?.elementarySchool}
                </h6>
              </div>

              <h6 className="inter Lgrey font16">
                SCHOOLS AND CULTURAL VENUES
              </h6>
              <div>
                <h6 className="inter black font20">Middle School</h6>
                <h6 className="inter Lgrey font16 mb-5 border-bottom pb-2">
                  {propertyInfo?.neighborhoodInfo?.[0]?.middleSchool}
                </h6>
              </div>

              <h6 className="inter Lgrey font16">
                SCHOOLS AND CULTURAL VENUES
              </h6>
              <div>
                <h6 className="inter black font20">High School</h6>
                <h6 className="inter Lgrey font16 mb-5 border-bottom pb-2">
                  {propertyInfo?.neighborhoodInfo?.[0]?.highSchool}
                </h6>
              </div>

              <h6 className="inter Lgrey font16">PARKS AND RECREATION</h6>
              <div>
                <h6 className="inter black font20">Park</h6>
                <h6 className="inter Lgrey font16 mb-5 border-bottom pb-2">
                  {propertyInfo?.neighborhoodInfo?.[0]?.parksRecreation}
                </h6>
              </div>

              <h6 className="inter Lgrey font16">SHOPPING AMENITIES</h6>
              <div>
                <h6 className="inter black font20">Shopping Complex</h6>
                <h6 className="inter Lgrey font16 mb-5">
                  {propertyInfo?.neighborhoodInfo?.[0]?.shoppingAmenities}
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12 mb-lg-0 mb-4">
            {/* <Map properties={null} /> */}
            {showMap && (
              <MapContainer
                center={[37.0902, -95.7129]}
                zoom={4}
                minZoom={2} // Set the minimum zoom level
                style={{ zIndex: '1', borderRadius: '10px' }}
                className="responsive-map"
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                {propertyInfo ? (
                  <Marker
                    position={[
                      parseFloat(propertyInfo?.latitude) || 0,
                      parseFloat(propertyInfo?.longitude) || 0,
                    ]}
                    icon={propertyIcon}
                    eventHandlers={
                      {
                        // click: handlePropertyClick,
                        // mouseover: (e) => e.target.setIcon(propertyIconHover),
                        // mouseout: (e) => e.target.setIcon(propertyIcon),
                      }
                    }
                  >
                    <Popup>
                      <div className="map-popup-link"
                      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                      >
                        <h2>{propertyInfo.property_title}</h2>
                        {propertyInfo.imageSrcLink && (
                          <img
                            src={getValidImageUrl(propertyInfo.imageSrcLink)}
                            alt={propertyInfo.property_title}
                            style={{ width: '100%' }}
                          />
                        )}
                        <p>Price: {propertyInfo.prices_range}</p>
                      </div>
                    </Popup>
                  </Marker>
                ) : (
                  <Marker
                    position={[
                      parseFloat(propertyInfo?.latitude) || 0,
                      parseFloat(propertyInfo?.longitude) || 0,
                    ]}
                    eventHandlers={
                      {
                        // mouseover: (e) => e.target.setIcon(propertyIconHover),
                        // mouseout: (e) => e.target.setIcon(propertyIcon),
                        // click: () => handleCityClick(property),
                      }
                    }
                    icon={cityLabel(propertyInfo?.city?.cityName || '')}
                  >
                   <Popup>
  <div
    className="map-popup-link"
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  >
    <h2>{propertyInfo?.property_title}</h2>
    {propertyInfo.imageSrcLink && (
      <img
        src={getValidImageUrl(propertyInfo.imageSrcLink)}
        alt={propertyInfo.property_title}
        style={{ width: '100%' }}
      />
    )}
    <p>Price: {propertyInfo.prices_range}</p>
  </div>
</Popup>

                  </Marker>
                )}

                {propertyInfo?.country?.countryName === 'India' && (
                  <MapCenter center={[20.5937, 78.9629]} zoom={5} />
                )}
                {propertyInfo?.country?.countryName ===
                  'United States of America' && (
                  <MapCenter center={[37.0902, -95.7129]} zoom={5} />
                )}
              </MapContainer>
            )}
          </div>
        </div>
      </div>

      {/* <div className="customContainer mt150 mb-5"   data-aos="fade-up">
        <div className="row my-5" data-aos="fade-up">
          <div className=" d-lg-block d-flex flex-column align-items-center">
            <div>
              <h1 className="playfair heading-color font54 text-lg-start text-center">
                Property Elevations
              </h1>
              <p className="font20 pt-3 inter text-lg-start text-center"  style={{ maxWidth: "721px" }}>
                Discover detailed property elevations revealing the home's
                architectural design, maximizing space and complementing the
                surrounding landscape.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <img
              src={require("../images/Pre1.jpg")}
              alt="Background Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              className=" img-fluid rounded-3"
            />
          </div>
          <div className="col-6">
            <img
              src={require("../images/Pre2.jpg")}
              alt="Background Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              className=" img-fluid rounded-3"
            />
          </div>
        </div>
      </div> */}
      {/* <Footer /> */}
      <ScrollToTopButton />
    </>
  );
};

export default PropertyDesc;
