import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Routes } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Image, Row, Col } from 'react-bootstrap';
import UpdatePassInside from '../Pages/UpdatePassInside';
import Sidebar from './Sidebar';
import menu from '../images/menu.svg';
import menu2 from '../images/menu2.png';
import PropertyId from '../Pages/PropertyId.json';
import { ROUTES } from '../Constant/Routes';

function Navb(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pop, setPop] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();

  function handlePropertyDesc(property_id) {
    navigate(ROUTES.PROPERTY_DESC, {
      state: { propertyInfo: { property_id: property_id } },
    });
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = location.pathname === '/contact-us' ? 0 : 0;
      if (window.scrollY > scrollThreshold) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    if (props.isDefault) {
      setScrolling(true);
    } else {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Check screen size on initial load
    handleResize();

    // Add event listener for screen resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === ROUTES.DEFAULT) {
      setTimeout(() => {
        handlePropertyDesc(PropertyId.pId);
      }, 100); // Delay by 100ms
    }
  }, [location.pathname, PropertyId.pId]);

  function formPop(e) {
    e.preventDefault();
    setPop((pValue) => !pValue);
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const navigate = useNavigate();

  function handleClickHome() {
    navigate(ROUTES.HOME);
  }

  function handleClick3() {
    navigate(ROUTES.PROJECTS);
  }

  function handleClick4() {
    navigate(ROUTES.ABOUT_US);
  }

  function contactUs() {
    navigate(ROUTES.CONTACT_US);
  }

  function handleClick7() {
    navigate(ROUTES.LOGIN);
  }

  function handleUpdateProfile() {
    navigate(ROUTES.UPDATE_PROFILE);
  }

  function handleClickModel() {
    navigate(ROUTES.SHOW_MODELS);
  }

  function closeUpdatePasswordPopup(data) {
    console.log('while closing popup : ', data);
    setPop(false);
  }

  // Function to handle logout
  function handleLogout() {
    // Clear session storage and navigate to the login page
    sessionStorage.clear();
    navigate(ROUTES.LOGIN);
  }

  useEffect(() => {
    // Check screen width on mount and resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 8); // Change the threshold as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {pop && <UpdatePassInside onClose={closeUpdatePasswordPopup} />}

      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />

      <div className="position-fixed zi100 w-100">
        {props.propertyInfo && (
          <div className="eventBlue w-100 py-3">
            <Row className="">
              <Col className="text-center inter white ps-4 font17px mt-3 font-weight-bold">
                {props.propertyInfo.incentive_offer}
              </Col>
              <Col className="text-center yellow font17px mt-3">
                {props.propertyInfo.event_details}
              </Col>
              <Col className="text-center text-success my-2">
                {' '}
                <button
                  onClick={() => contactUs(props.propertyInfo)}
                  className="contactSales-btn rounded label-background-color col-white font18 mt-lg-0 mt-3  px-3 py-2 me-4 "
                >
                  Contact Sales
                </button>
              </Col>
            </Row>
          </div>
        )}
        <Navbar
          expand="md"
          className={`d-flex justify-content-between  px-5 ${
            scrolling ? 'bg-whiteTrans' : ''
          }`}
          style={{ opacity: 100 }} // assuming opacity should remain 1
        >
          <Navbar.Brand href="/">
          <img
  className=""
  onClick={handleClickHome}
  src={require('../images/KRHome-final-regular.png')}
  alt="logo"
  width="105"  // Calculated width
  height="70"  // Specified height
/>

          </Navbar.Brand>
          {/* Conditional rendering for small and medium devices */}
          {!isSmallScreen && (
            <div>
              {location.pathname !== '/property-description' && (
                <button
                  onClick={() => {
                    contactUs();
                    const lowerOffset = 750;
                    window.scrollTo({ top: lowerOffset, behavior: 'smooth' });
                  }}
                  className="header-contact-btn label-btn label-background-color col-white h56 mt-lg-0 px-3 py-2 "
                  style={{
                    position: 'absolute',
                    top: '30px',
                    right: '120px',
                  }}
                >
                  Contact Us
                </button>
              )}

              <button
                onClick={() => {
                  handlePropertyDesc(PropertyId.pId);
                  const lowerOffset = 1000;
                  window.scrollTo({ top: lowerOffset, behavior: 'smooth' });
                }}
                className="header-contact-btn label-btn label-background-color col-white h56 mt-lg-0 px-3 py-2 "
                style={{
                  position: 'absolute',
                  top: '30px',
                  right: '290px',
                }}
              >
                Suwanee Preserves
              </button>

              <div
                className={` mt-3 ${scrolling ? 'bg-primary' : ''}`}
                onClick={toggleSidebar}
              >
                <button
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  className="burger-icon"
                  style={{
                    position: 'absolute',
                    top: '30px',
                    right: '50px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    height: '53px',
                    width: '53px',
                  }}
                >
                  <img
                    className=""
                    style={{}}
                    width={'53'}
                    height={'53'}
                    src={scrolling ? menu2 : menu2}
                    alt="First slide"
                  />
                </button>
              </div>
            </div>
          )}

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto"></Nav>
          </Navbar.Collapse>
        </Navbar>

        {isMobile && location.pathname !== ROUTES.PROPERTY_DESC && (
          <div
            className="button-container"
            style={{ position: 'absolute', top: '25px', right: '97px' }}
          >
            <button
              onClick={() => {
                handlePropertyDesc(PropertyId.pId);
                const lowerOffset = 1000;
                window.scrollTo({ top: lowerOffset, behavior: 'smooth' });
              }}
              className="constant-suwanee-btn label-background-color col-white h56 mt-lg-0 py-2 mx-3"
            >
              Suwanee Preserves
            </button>
          </div>
        )}
      </div>

      <div
        className={isSidebarOpen ? 'menu-overlay active' : 'menu-overlay'}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      ></div>
    </>
  );
}

export default Navb;
