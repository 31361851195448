import img1 from "./Prop39_Images/001 - 39 cub trce dahlonega ga.webp";
import img2 from "./Prop39_Images/063 - 39 cub trce dahlonega ga.webp";
import img3 from "./Prop39_Images/066 - 39 cub trce dahlonega ga.webp";
import img4 from "./Prop39_Images/071 - 39 cub trce dahlonega ga.webp";
import img5 from "./Prop39_Images/074 - 39 cub trce dahlonega ga.webp";
import img6 from "./Prop39_Images/083 - 39 cub trce dahlonega ga.webp";




const Prop39 = [

 
  {
    "SNO": 1,
    "id": 3,
    "imgType": "Exterior Front",
    "imgPath": img1
},

{
  "SNO": 2,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img2
},

{
  "SNO": 3,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img3
},


{
  "SNO": 4,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img4
},



{
  "SNO": 5,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img5
},



{
  "SNO": 6,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img6
},

];

export default Prop39;
