import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import ScrollTop from '../Components/ScrollTop';

const RootLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <React.Fragment>
      <ScrollTop />
      {/* Header */}
      <Navbar />
      <main>{children}</main>
      {/* Footer */}
      <Footer />
    </React.Fragment>
  );
};
export default RootLayout;
