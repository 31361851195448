import img1 from "./Prop445_Images/002 - 445 pirkle ave drone.webp";
import img2 from "./Prop445_Images/003 - 445 pirkle ave drone.webp";
import img3 from "./Prop445_Images/004 - 445 pirkle ave drone.webp";
import img4 from "./Prop445_Images/009 - 445 pirkle ave drone.webp";
import img5 from "./Prop445_Images/065 - 445 pirkle ave buford ga.webp";




const Prop445 = [

 
  {
    "SNO": 1,
    "id": 3,
    "imgType": "Exterior Front",
    "imgPath": img1
},

{
  "SNO": 2,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img2
},

{
  "SNO": 3,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img3
},


{
  "SNO": 4,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img4
},


{
  "SNO": 5,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img5
},

];

export default Prop445;
