import React, { useEffect, useState } from 'react';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import Select from 'react-select';
// import { Helmet } from 'react-helmet';
import Spinner from 'react-bootstrap/Spinner';
import { Form, Button, Row, Col, FormGroup, FormLabel } from 'react-bootstrap'; // Added FormGroup and FormLabel
import httpproperties from '../js/http-realestate-properties';
import 'aos/dist/aos.css'; // Import AOS CSS
import AOS from 'aos';
import { useLocation } from 'react-router-dom';
import httpuserprofile from '../js/http-lms_userprofile';
import httpcontactus from '../js/http-lms_contactus';
import PageSeoComponent from '../SEO';
import SEO from '../Constant/SEO.json';

const ContactUs = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState('general');
  const [properties, setProperties] = useState([]);
  const [option, setOption] = useState();
  const [propertyList, setPropertyList] = useState([]);
  const [error, setError] = useState();
  const [countriesList, setCountriesList] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [pop, setPop] = useState(false);
  const [propertyId, setPropertyId] = useState();
  const [inputerr, setInputerr] = useState({});
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    occupation: 'Customer',
    country: '',
    comment: '',
    appCode: 'KRD',
    propertyId: '',
    property_title: '',
    preferedcontact: '',
    enquiryType: '',
  });

  AOS.init({
    duration: 600, // Animation duration
    easing: 'ease-in-out', // Easing function
  });

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDropdown = (e) => {
    setOption(e.target.value);
  };

  function handleCountryChange(selectedCountry) {
    console.log('selected country', selectedCountry);
    setSelectedCountryOption(selectedCountry);

    setData((prevProperty) => ({
      ...prevProperty,
      country: selectedCountry.countryName,
    }));
  }

  const handleInput = (event) => {
    const { name, value, type, checked } = event.target;
    let fieldValue = type === 'checkbox' ? checked : value;

    // If the field is phoneNumber, remove non-numeric characters and limit length to 10
    if (name === 'phoneNumber') {
      // Remove any non-numeric characters
      fieldValue = fieldValue.replace(/\D/g, '');

      // Limit length to 10 characters
      fieldValue = fieldValue.slice(0, 10);

      // Check if the phone number has exactly 10 digits
      const isValidPhoneNumber = fieldValue.length === 10;

      // Set the error message if the phone number is invalid
      setInputerr((prevErrors) => ({
        ...prevErrors,
        phoneNumber: isValidPhoneNumber
          ? ''
          : 'Phone number must be of 10 digits!',
      }));
    }

    setData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  useEffect(() => {
    fetchPropertyList();

    if (location?.state?.propertyInfo) {
      setSelectedOption('property');
      setData((prevData) => {
        return {
          ...prevData,
          property_title: location.state.propertyInfo.property_title,
          propertyId: location.state.propertyInfo.property_id,
        };
      });
    }

    httpuserprofile.get('/userprofileLMS/getUserCountries').then((res) => {
      console.log('user countries', res);
      setCountriesList(res.data);

      // Set default selected country
      const defaultCountry = res.data.find(
        (country) => country.countryName === 'United States of America'
      );
      if (defaultCountry) {
        setSelectedCountryOption(defaultCountry);
      }
    });
  }, []);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      // border: `1px solid #ced4da`,
      // boxShadow: `0 0 0 1px`,
      // ':hover' : {
      //   // boxShadow: `0 0 0 1px `,
      //   backgroundColor : "blue"
      // }
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor:
        state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: '14px',
      paddingTop: '4px',
      paddingBottom: '4px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };

  const handleonClick = (event) => {
    let isvalid = validateForm();
    if (isvalid) {
      console.log(data.propertyId, 'ABCEDE');

      console.log(propertyList, data.propertyId);

      let title = propertyList.filter(
        (prop) => prop?.property_id == data?.propertyId
      )?.[0]?.property_title;

      data.property_title = title;

      if (selectedOption == 'general') {
        data.enquiryType = 'General';
      } else if (selectedOption == 'property') {
        data.enquiryType = 'Property';
      }
      // console.log(data);
      httpcontactus
        .post('allContactUS/saveContactData', data)
        .then((response) => {
          console.log('test', response.data);
          setProperties(response.data);
          setPop(true);
          setData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            occupation: '',
            country: '',
            comment: '',
            appCode: 'KRD',
            propertyId: '',
            property_title: '',
            enquiryType: '',
          });
          setTimeout(() => {
            setPop(false);
          }, 2800);
        })
        .catch((err) => {
          console.log(err, 'rtrtrtrt');
          setError(err.message);
        });

      // alert("your details are added successfully");
    } else {
      alert('please fill the all the required fields');
    }
  };

  const fetchPropertyList = () => {
    httpproperties
      .get('/propertyManagement/getPropertyIdAndTitleList')
      .then((result) => {
        console.log(result.data, 'PropList');
        setPropertyList(result.data);
      })
      .catch((err) => {
        console.log(err, 'ERRRR');
      });
  };

  const validateForm = () => {
    let err = {};
    if (data.firstName === '') {
      err.firstName = '*First name is required!';
    }
    if (data.lastName === '') {
      err.lastName = '*Last name is required!';
    }
    if (selectedOption === 'property' && data.propertyId === '') {
      err.propertyId = 'Property type is required';
    }
    if (data.email === '') {
      err.email = '*Email ID is required!';
    } else if (!validateEmail(data.email)) {
      err.email = '*Invalid email format!';
    }
    if (data.comment === '') {
      err.comment = '*Comments are required!';
    }
    if (data.phoneNumber === '') {
      err.phoneNumber = '*Phone Number is required!';
    } else if (!validatePhoneNumber(data.phoneNumber)) {
      err.phoneNumber = '*Invalid phone number!';
    }
    if (data.occupation === '') {
      err.occupation = '*Occupation is required!';
    }
    if (data.countryId === '') {
      err.country = '*Country is required!';
    }
    setInputerr({ ...err });
    return Object.keys(err).length === 0; // Return true if there are no validation errors
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
  };

  return (
    <>
      <PageSeoComponent {...SEO['contact-us']} />

      {/* <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Get in touch with us for inquiries about luxury custom homes, property listings, and more. We're here to assist you with your real estate needs in Cumming, Georgia."
        />
        <meta
          name="keywords"
          content="contact real estate, luxury homes contact, custom homes inquiry, property inquiry, real estate contact Cumming GA, luxury home builders contact, new homes inquiry, custom home builders contact, real estate services Cumming GA"
        />
        <link
          rel="canonical"
          href="https://www.krhomes.net/contact-us#contact-form"
        />
      </Helmet> */}

      {pop && (
        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
          <div className=" d-flex flex-column justify-content-center align-items-center P-Update-Success-2 ">
            <div>
              <img
                src={require('../images/Tick.png')}
                alt="success"
                className="img-fluid"
              />
            </div>
            <p className="text-center w213 ">
              Your query is successfully submitted will get back to you soon.
            </p>
          </div>
        </div>
      )}

      {/* <Navbar class="position-fixed d-flex justify-content-between zi100 py-2 w-100 greybg px-5" /> */}


      <div className="" style={{ position: 'relative', height: '100%' }}>
        <img
           src={require('../images/contus.png')}
          alt="Background"
          className="background-propertydesc-image"
          style={{ marginTop: '110px' }}
        />

        {/* <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: '10',
          }}
        >
          <h1>Project Specification</h1>
        </div> */}
      </div>


      <div
        id="contact-form"
        className="d-flex justify-content-center mt-5"
        data-aos="fade-up"
      >
        <div style={{ maxWidth: '850px',  }}>
          <h1 className="text-center inter black font28 mb-4 ">
            Contact Us by Email
          </h1>
          {/* <h2 className="text-center inter font20 mb-1 ">Or</h2>
          <h2 className="text-center inter font26 mb-4 ">Send a message</h2> */}
          <h2 className="text-center playfair font20" style={{ fontWeight: '550' }}>

          KR Homes excited to hear about your vision for a luxury dream home. Please Fill out the form below, and we’ll get back to you within 24 hours. Please provide as much detail as possible so we can better understand your needs.
          </h2>
        </div>
      </div>

      <div
        className="d-flex justify-content-center align-items-center my-5"
        data-aos="fade-up"
      >
        <div className="rounded-4 contact-form" data-aos="fade-up">
          {error && (
            <div className="error-message">
              <p className="ValidationColor">{error}</p>
            </div>
          )}

          <Form className=" bg-white ">
            <FormGroup className="mb-3" controlId="dropdown">
              <FormLabel>Select Option</FormLabel>
              <Form.Select
                value={selectedOption}
                onChange={handleDropdownChange}
              >
                <option value="general">General</option>
                <option value="property">Property</option>
              </Form.Select>
            </FormGroup>

            {selectedOption === 'property' && (
              <FormGroup className="mb-4" controlId="propertyType">
                <FormLabel className="required">Property Type</FormLabel>
                <Form.Select
                  // onChange={(e) => setPropertyId(e.target.value)}
                  name="propertyId"
                  aria-label="Select Property Type"
                  onChange={handleInput}
                  value={data.propertyId}
                >
                  <option value="">Select Property Type</option>
                  {propertyList.map((property, index) => (
                    <option
                      value={property.property_id}
                      key={property.property_id}
                    >
                      {property.property_title}
                    </option>
                  ))}
                </Form.Select>
                {inputerr.propertyId && (
                  <h6 className="ValidationColor">{inputerr.propertyId}</h6>
                )}
              </FormGroup>
            )}

            <Row className="mb-4">
              <Col>
                <FormGroup controlId="firstName">
                  <FormLabel className="required">First Name</FormLabel>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    value={data.firstName}
                    name="firstName"
                    onChange={handleInput}
                  />
                  {inputerr.firstName && (
                    <h6 className="ValidationColor">{inputerr.firstName}</h6>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    value={data.lastName}
                    name="lastName"
                    onChange={handleInput}
                  />
                  {inputerr.lastName && (
                    <h6 className="ValidationColor">{inputerr.lastName}</h6>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <FormGroup controlId="email">
                  <FormLabel className="required">Email ID</FormLabel>
                  <Form.Control
                    type="email"
                    placeholder="Email ID"
                    value={data.email}
                    name="email"
                    onChange={handleInput}
                  />
                  {inputerr.email && (
                    <h6 className="ValidationColor">{inputerr.email}</h6>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup controlId="phoneNumber">
                  <FormLabel className="required">Phone Number</FormLabel>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    value={data.phoneNumber}
                    name="phoneNumber"
                    maxLength={10}
                    onChange={handleInput}
                  />
                  {inputerr.phoneNumber && (
                    <h6 className="ValidationColor">{inputerr.phoneNumber}</h6>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <FormGroup controlId="occupation">
                  <FormLabel>Occupation</FormLabel>
                  <Form.Control
                    type="text"
                    placeholder="Occupation"
                    value={data.occupation}
                    name="occupation"
                    onChange={handleInput}
                  />
                  {inputerr.occupation && (
                    <h6 className="ValidationColor">{inputerr.occupation}</h6>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <Form.Group controlId="country">
                  <Form.Label>Country</Form.Label>
                  
<Select
  id="country-select"
  value={selectedCountryOption}
  onChange={handleCountryChange}
  placeholder="Select Country"
  className="multiselectdropdown"
  classNamePrefix="react-select"
  styles={colourStyles}
  getOptionLabel={(option) => `${option.countryName}`}
  options={countriesList}
/>

                  <h6 className="ValidationColor">{inputerr.country}</h6>
                </Form.Group>
              </Col>
            </Row>

            <FormGroup className="" controlId="comment">
              <FormLabel className="required">Message</FormLabel>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Comment"
                value={data.comment}
                name="comment"
                onChange={handleInput}
              />
              {inputerr.comment && (
                <h6 className="ValidationColor">{inputerr.comment}</h6>
              )}
            </FormGroup>
            <div className="d-flex justify-content-end ">
              <Button
                variant="primary"
                className="subheadings d-flex justify-content-center align-items-center sect-7-button mt-5"
                onClick={handleonClick}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default ContactUs;
