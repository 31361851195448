import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import Map from '../Components/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import httpproperties from '../js/http-realestate-properties';
// import { ReactComponent as StateIcon } from '../images/state.svg';
// import { ReactComponent as BuildingIcon } from '../images/building.svg';
// import { ReactComponent as EarthIcon } from '../images/earth.svg';
import State from "../images/state.png"
import Building from "../images/building1.png"
import Earth from "../images/earth.png"
import 'aos/dist/aos.css'; // Import AOS CSS
import AOS from 'aos';
// import Select from 'react-select';
// import { Helmet } from 'react-helmet';
import ScrollToTopButton from '../Components/ScrollToTop';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import SuwaneePreserves from "../images/PropertyImages/SuwaneePreserves/SuwaneePreserves";
import Prop448 from "../images/PropertyImages/Prop448/Prop448";
import Prop39 from "../images/PropertyImages/Prop39/Prop39";
import Prop445 from "../images/PropertyImages/Prop445/Prop445";
import httpuserprofile from '../js/http-lms_userprofile';
import { ReactComponent as SliderArrow } from '../images/slider-next-arrow.svg';
import { ROUTES } from '../Constant/Routes';
import PageSeoComponent from '../SEO';
import SEO from '../Constant/SEO.json';

const Home = () => {
  const [error, setError] = useState();
  const [expandedDescriptionIndex, setExpandedDescriptionIndex] =
    useState(null);
  const [properties, setProperties] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);

  // const [location, setLocation] = useState('');
  const [homeType, setHomeType] = useState('');
  const [propStatus, setPropStatus] = useState([]);
  const [propStatusFilter, setPropStatusFilter] = useState([]);
  const [propertyTitle, setPropertyTitle] = useState('');
  const [propertyAreaRange, setPropertyAreaRange] = useState('');
  const [noOfBedroomsRange, setNoOfBedroomsRange] = useState('');
  const [noOfStoryRange, setNoOfStoryRange] = useState('');
  const [noOfBathsRange, setNoOfBathsRange] = useState('');
  const [showMap, setShowMap] = useState(false);

  AOS.init({
    duration: 600, // Animation duration
    easing: 'ease-in-out', // Easing function
  });

  useEffect(() => {
    getPropertiesList();
    propertyStatus();

    httpuserprofile.get('/userprofileLMS/getUserCountries').then((res) => {
      // console.log('user countries', res);
      setCountriesList(res.data);
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMap(true);
    }, 1500);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  function handlePropertyDesc(property) {
    navigate(ROUTES.PROPERTY_DESC, { state: { propertyInfo: property } });
  }

  const handleToggleDescription = (index) => {
    if (expandedDescriptionIndex === index) {
      setExpandedDescriptionIndex(null);
    } else {
      setExpandedDescriptionIndex(index);
    }
  };

  const propertyStatus = () => {
    httpuserprofile
      .get('/userprofileLMS/getStaticValuesListById?indexCode=9')
      .then((result) => {
        // console.log(result.data, 'propStatus');
        setPropStatus(result.data);
      })
      .catch((err) => {
        // console.log(err, 'ERRRR');
      });
  };

  // function clearPropertiesList() {
  //   setSelectedCountryOption(null);
  //   setSelectedStateOption(null);
  //   setSelectedCityOption(null);
  //   setPropertyTitle('');
  //   setHomeType('');
  //   setPropertyAreaRange('');
  //   setNoOfBedroomsRange('');
  //   setNoOfStoryRange('');
  //   setNoOfBathsRange('');
  //   setPropStatusFilter('');

  //   httpproperties
  //     .get('propertyManagement/getAllPropertyListForUser', {
  //       params: {
  //         cityName: '',
  //         stateName: '',
  //         countryName: '',
  //         propertyAreaRange,
  //         noOfBedroomsRange,
  //         noOfStoryRange,
  //         noOfBathsRange,
  //         homeType,
  //         propertyTitle: '',
  //         status: '',
  //       },
  //     })
  //     .then((response) => {
  //       if (response.data === 'No records found') {
  //         setError(response.data);
  //       } else {
  //         // console.log('fetch properties list: ', response);
  //         setProperties(response.data);
  //       }
  //     })
  //     .catch((e) => {
  //       // console.log(e);
  //     });
  // }

  // function handleCountryChange(selectedCountry) {
  //   // console.log('selected country', selectedCountry);
  //   setSelectedCountryOption(selectedCountry);
  //   setSelectedStateOption(null);
  //   setSelectedCityOption(null);
  //   setStatesList([]);
  //   setCitiesList([]);

  //   if (selectedCountry) {
  //     // setProperty((prevProperty) => ({
  //     //   ...prevProperty,
  //     //   countryId: selectedCountry.countryId,
  //     // }));

  //     httpuserprofile
  //       .get(
  //         '/userprofileLMS/getUserStates?countryId=' + selectedCountry.countryId
  //       )
  //       .then((res) => {
  //         // console.log('user states', res);
  //         setStatesList(res.data);
  //       })
  //       .catch((error) => {
  //         // console.error('Error fetching states:', error);
  //       });
  //   }
  // }

  // function handleStateChange(selectedState) {
  //   // console.log('selected state', selectedState);
  //   setSelectedStateOption(selectedState);
  //   setSelectedCityOption(null);
  //   setCitiesList([]);

  //   if (selectedState) {
  //     // setProperty((prevProperty) => ({
  //     //   ...prevProperty,
  //     //   stateId: selectedState.stateId,
  //     // }));

  //     httpuserprofile
  //       .get('/userprofileLMS/getUserCities?stateId=' + selectedState.stateId)
  //       .then((res) => {
  //         // console.log('user cities', res);
  //         setCitiesList(res.data);
  //       })
  //       .catch((error) => {
  //         // console.error('Error fetching cities:', error);
  //       });
  //   }
  // }

  // function handleCityChange(selectedCity) {
  //   // console.log('selected city : ', selectedCity);
  //   setSelectedCityOption(selectedCity);
  // }

  // const colourStyles = {
  //   control: (styles) => ({
  //     ...styles,
  //     // border: `1px solid #ced4da`,
  //     // boxShadow: `0 0 0 1px`,
  //     // ':hover' : {
  //     //   // boxShadow: `0 0 0 1px `,
  //     //   backgroundColor : "blue"
  //     // }
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     fontWeight: state.isSelected ? 'bold' : 'normal',
  //     color: state.isFocused || state.isSelected ? 'white' : 'black',
  //     backgroundColor:
  //       state.isFocused || state.isSelected ? 'blue' : 'transparent',
  //     fontSize: '14px',
  //     paddingTop: '4px',
  //     paddingBottom: '4px',
  //   }),
  //   singleValue: (provided, state) => ({
  //     ...provided,
  //     color: state.data.color,
  //     fontSize: state.selectProps.myFontSize,
  //   }),
  // };

  // function handleSearch() {
  //   getPropertiesList();
  // }

  function getPropertiesList() {
    httpproperties
    .get('propertyManagement/getAllPropertyListForUser', {
      params: {
        cityName: selectedCityOption?.cityName || '',
        stateName: selectedStateOption?.stateName || '',
        countryName: selectedCountryOption?.countryName || '',
        propertyAreaRange,
        noOfBedroomsRange,
        noOfStoryRange,
        noOfBathsRange,
        homeType,
        propertyTitle,
        status: propStatusFilter,
      },
    })
    .then((response) => {
      if (response.data === 'No records found') {
        setError(response.data);
      } else {
        // console.log('fetch properties list: ', response);
        
        // Sort properties, prioritizing 'Suwanee Preserves'
        const sortedProperties = response.data.sort((a, b) => {
          if (a.property_title === 'Suwanee Preserves') return -1;
          if (b.property_title === 'Suwanee Preserves') return 1;
          return a.property_title.localeCompare(b.property_title);
        });
  
        setProperties(sortedProperties);
      }
    })
    .catch((e) => {
      // console.log(e);
    });
  
  }
  



  // const handlePrevious = () => {
  //   if (currentImageIndex > 0) {
  //     setCurrentImageIndex(currentImageIndex - 1);
  //   } else {
  //     setCurrentImageIndex(properties.length - 1); // Go to the last image if at the beginning
  //   }
  // };

  const navigate = useNavigate();

  // function handleProp() {
  //   navigate(ROUTES.PROJECTS);
  // }

  function handleModel() {
    navigate(ROUTES.SHOW_MODELS);
  }

  function contactUs(property) {
    navigate(ROUTES.CONTACT_US, { state: { propertyInfo: property } });
  }

  const windowHeight = window.innerHeight;

  return (
    <>
      <PageSeoComponent {...SEO.home} />

      {/* <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="Explore luxury homes, custom homes, and new homes in Cumming, GA. Find your dream home with our expert real estate services."
        />
        <meta
          name="keywords"
          content="single family home, luxury homes, custom homes, luxury custom homes, suwanee preserves, cumming custom luxury homes, sky lane, new homes in cumming, new custom luxury homes in cumming, new custom luxury homes, Luxury Homes Cumming GA, Luxury Real Estate Cumming Georgia, High-End Homes Cumming GA, Luxury Home Builders Cumming GA, New Luxury Homes in Cumming GA, Luxury Houses for Sale in Cumming GA, Custom Luxury Homes Cumming GA, Luxury Homes Forsyth County GA, Upscale Homes Cumming Georgia, Premium Properties Cumming GA, Luxury Residences Cumming GA, Gated Community Homes Cumming GA, Luxury Lakefront Homes Cumming GA, Modern Luxury Homes Cumming GA, Executive Homes Cumming GA, Luxury Living Cumming GA, Top Luxury Home Builders in Cumming GA, Exclusive Homes Cumming GA, Luxury Home Communities Cumming GA, Luxury Home Listings Cumming GA, Custom Home Construction Georgia, Custom Luxury Homes in Georgia, High-End Custom Homes Georgia, Luxury Custom Houses for Sale in Georgia, New Custom Home Developments Georgia, Custom Homes Forsyth County, High-End Custom Real Estate Georgia, Premium Custom Homes Georgia, Custom Residences in Georgia, Custom Gated Community Homes in Georgia, Custom Luxury Lakefront Homes Georgia, Executive Custom Homes Georgia, Top Custom Home Builders in Georgia, Custom Home Communities in Georgia, Custom Home Listings in Georgia"
        />
        <link rel="canonical" href="https://www.krhomes.net" />
      </Helmet> */}

      {/* <Navbar /> */}

      <Carousel
        style={{ height: '', overflow: 'hidden' }}
        className="home-banner"
      >
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src={`${process.env.PUBLIC_URL}/PP10.webp`}
            alt="First slide"
            
          />
          <Carousel.Caption
            style={{
              position: 'absolute',
              bottom: '0', // Use bottom to ensure the caption is at the bottom of the carousel
              transform: 'translateY(-20%)', // Adjusted to ensure proper positioning
              color: 'white',
              zIndex: 1, // Ensure this is high enough to be on top of the image
            }}
          >
            {/* <div className="container bgFilter rounded-4 pb-3 pt-3">
        <div className="row align-items-center">
          <div className="col-12 col-lg-9">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3">
                <Select
                  value={selectedCountryOption}
                  onChange={handleCountryChange}
                  placeholder="Select Country"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.countryName}`}
                  options={countriesList}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2 mt-sm-0">
                <Select
                  value={selectedStateOption}
                  onChange={handleStateChange}
                  placeholder="Select State"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.stateName}`}
                  options={statesList}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2 mt-md-0">
                <Select
                  value={selectedCityOption}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.cityName}`}
                  options={citiesList}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2 mt-md-0">
                <input
                  className="formInput"
                  type="text"
                  placeholder="Property Title"
                  value={propertyTitle}
                  onChange={(e) => setPropertyTitle(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-3 mt-3 mt-lg-0 d-flex flex-column flex-lg-row justify-content-lg-end align-items-center">
            <button
              className="btn btn-primary label-background-color px-4 py-3 mb-2 mb-lg-0 me-lg-2 w-100"
              onClick={handleSearch}
            >
              Find Properties
            </button>
            <button
              onClick={clearPropertiesList}
              className="btn btn-primary label-background-color px-4 py-3 mb-2 mb-lg-0 me-lg-2"
            >
              Clear
            </button>
          </div>
        </div>
      </div> */}
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src={require('../images/PP2.webp')}
            alt="First slide"

          />
          <Carousel.Caption
            style={{
              position: 'absolute',
              bottom: '0', // Use bottom to ensure the caption is at the bottom of the carousel
              transform: 'translateY(-20%)', // Adjusted to ensure proper positioning
              color: 'white',
              zIndex: 1, // Ensure this is high enough to be on top of the image
            }}
          >
            {/* <div className="container bgFilter rounded-4 pb-3 pt-3">
        <div className="row align-items-center">
          <div className="col-12 col-lg-9">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3">
                <Select
                  value={selectedCountryOption}
                  onChange={handleCountryChange}
                  placeholder="Select Country"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.countryName}`}
                  options={countriesList}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2 mt-sm-0">
                <Select
                  value={selectedStateOption}
                  onChange={handleStateChange}
                  placeholder="Select State"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.stateName}`}
                  options={statesList}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2 mt-md-0">
                <Select
                  value={selectedCityOption}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.cityName}`}
                  options={citiesList}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2 mt-md-0">
                <input
                  className="formInput"
                  type="text"
                  placeholder="Property Title"
                  value={propertyTitle}
                  onChange={(e) => setPropertyTitle(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-3 mt-3 mt-lg-0 d-flex flex-column flex-lg-row justify-content-lg-end align-items-center">
            <button
              className="btn btn-primary label-background-color px-4 py-3 mb-2 mb-lg-0 me-lg-2 w-100"
              onClick={handleSearch}
            >
              Find Properties
            </button>
            <button
              onClick={clearPropertiesList}
              className="btn btn-primary label-background-color px-4 py-3 mb-2 mb-lg-0 me-lg-2"
            >
              Clear
            </button>
          </div>
        </div>
      </div> */}
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src={require('../images/PP3.webp')}
            alt="First slide"
            
          />
          <Carousel.Caption
            style={{
              position: 'absolute',
              bottom: '0', // Use bottom to ensure the caption is at the bottom of the carousel
              transform: 'translateY(-20%)', // Adjusted to ensure proper positioning
              color: 'white',
              zIndex: 1, // Ensure this is high enough to be on top of the image
            }}
          >
            {/* <div className="container bgFilter rounded-4 pb-3 pt-3">
        <div className="row align-items-center">
          <div className="col-12 col-lg-9">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3">
                <Select
                  value={selectedCountryOption}
                  onChange={handleCountryChange}
                  placeholder="Select Country"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.countryName}`}
                  options={countriesList}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2 mt-sm-0">
                <Select
                  value={selectedStateOption}
                  onChange={handleStateChange}
                  placeholder="Select State"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.stateName}`}
                  options={statesList}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2 mt-md-0">
                <Select
                  value={selectedCityOption}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.cityName}`}
                  options={citiesList}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-3 mt-2 mt-md-0">
                <input
                  className="formInput"
                  type="text"
                  placeholder="Property Title"
                  value={propertyTitle}
                  onChange={(e) => setPropertyTitle(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-3 mt-3 mt-lg-0 d-flex flex-column flex-lg-row justify-content-lg-end align-items-center">
            <button
              className="btn btn-primary label-background-color px-4 py-3 mb-2 mb-lg-0 me-lg-2 w-100"
              onClick={handleSearch}
            >
              Find Properties
            </button>
            <button
              onClick={clearPropertiesList}
              className="btn btn-primary label-background-color px-4 py-3 mb-2 mb-lg-0 me-lg-2"
            >
              Clear
            </button>
          </div>
        </div>
      </div> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div
        className="customContainer mt-5 featured-properties-home"
        data-aos="fade-up"
      >
        {/* <div className="row d-flex justify-content-end mb-4">
          <div className="col-lg-6 col-12 d-lg-block d-flex flex-column align-items-center">
            <div>
              <h1 className="h1 playfair heading-color font54 text-lg-start text-center">
                Featured Properties
              </h1>
              <p className="font20 pt-3 inter text-lg-start text-center">
                Explore our handpicked selection of featured properties. Each
                listing offers a glimpse into exceptional homes and investments
                available through KR Real Estate.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-12 d-flex flex-column flex-lg-row align-items-center justify-content-lg-end ">
            <button
              onClick={handleProp}
              className="label-btn h56 px-3 py-2 mt-lg-5"
            >
              View All Properties
              <FontAwesomeIcon className="ms-2" icon={faArrowCircleRight} />
            </button>
          </div>
        </div> */}

        {/* Row for displaying property images */}
        <div>
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            slidesPerGroup={1}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesPerGroup: 2
              },
              1280: {
                slidesPerView: 2,
                spaceBetween: 30,
                slidesPerGroup: 2
              },
            }}
            pagination={{
              type: 'fraction',
              el: '.property-dot',
              clickable: true,
            }}
            navigation={{
              nextEl: '.property-arrow-right',
              prevEl: '.property-arrow-left',
            }}
            modules={[Pagination, Navigation]}
          >
            {properties.map((property, index) => {
              // const filteredImages = property.imageSrcLink
              //   ? property.imageSrcLink.filter(image => image.imgId !== 9 && image.imgId !== 12 && image.imgId !== 17)
              //   : [];
              // const imageUrl = filteredImages.length > 0 ? filteredImages[0].imageUrl : '';

              return (
                <SwiperSlide key={index}>
        <div className="col-md-6 col-lg-6 mb-5 w-100" key={index}>
                <div
                  className="card mb-4 px-3 py-3 rounded-3"
                  style={{
                    borderRadius: "0.5px",
                    border: "0.5px solid #dddddd",
                  }}
                >
                   <p className="playfair playfair heading-color font32 mb-3 ">
                        {property.property_title}
                      </p>
                      <div className="d-flex">
                        <p className="font16 inter Lgrey">
                                 {property.city.cityName}
                        </p>
                        <p className="font16 inter Lgrey px-3">
                                | {property.state.stateName} |
                        </p>
                         <p className="font16 inter Lgrey">
                                 {property.state.stateName}
                        </p>

                      </div>
                  <div className="Model-image imagek">
                  {property.property_title === 'Suwanee Preserves' ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "350px",
      }}
    >
      <img
        src={SuwaneePreserves[0].imgPath}
        alt=""
        className="imagek rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        className="rounded-5"
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "#F0F7FF",
          padding: "5px",
          border: "1px solid #7CC0FD",
          borderRadius: "5px",
        }}
      >
        <p
          className="px-3 py-1 inter"
          style={{
            margin: "0",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {property.status}
        </p>
      </div>
    </div>
  ) : property.property_title === '1#Dahlonega-GA-30533-USA' ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "350px",
      }}
    >
      <img
        src={Prop448[0].imgPath}
        alt=""
        className="imagek rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        className="rounded-5"
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "#F0F7FF",
          padding: "5px",
          border: "1px solid #7CC0FD",
          borderRadius: "5px",
        }}
      >
        <p
          className="px-3 py-1 inter"
          style={{
            margin: "0",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {property.status}
        </p>
      </div>
    </div>
  ) : property.property_title === '2#Dahlonega-GA-30533-USA' ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "350px",
      }}
    >
      <img
        src={Prop39[3].imgPath}
        alt=""
        className="imagek rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        className="rounded-5"
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "#F0F7FF",
          padding: "5px",
          border: "1px solid #7CC0FD",
          borderRadius: "5px",
        }}
      >
        <p
          className="px-3 py-1 inter"
          style={{
            margin: "0",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {property.status}
        </p>
      </div>
    </div>
  ): property.property_title === '3#Dahlonega-GA-30533-USA' ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "350px",
      }}
    >
      <img
        src={Prop445[4].imgPath}
        alt=""
        className="imagek rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        className="rounded-5"
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "#F0F7FF",
          padding: "5px",
          border: "1px solid #7CC0FD",
          borderRadius: "5px",
        }}
      >
        <p
          className="px-3 py-1 inter"
          style={{
            margin: "0",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {property.status}
        </p>
      </div>
    </div>
  )  : property.imageSrcLink[0] && (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "350px",
      }}
    >
      <img
         src={
          property.imageSrcLink.find(image => image.imgId !== 9 && image.imgId !== 12 && image.imgId !== 17)?.imageUrl || ""
        }  
        alt=""
        className="imagek rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        className="rounded-5"
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "#F0F7FF",
          padding: "5px",
          border: "1px solid #7CC0FD",
          borderRadius: "5px",
        }}
      >
        <p
          className="px-3 py-1 inter"
          style={{
            margin: "0",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {property.status}
        </p>
      </div>
    </div>
  )}
                  </div>
                  <div className="mt-3">
                    <div className="Model-details">
                      {/* <p className="playfair playfair heading-color font32 mb-0 ">
                        {property.property_title}
                      </p> */}
                      <p
                        className="inter font20 Lgrey"
                        style={{ fontWeight: 700 }}
                      >
                        {property.prices_range}
                      </p>

                      <p className="font16 inter Lgrey">
                        {expandedDescriptionIndex === index
                          ? property.community_description
                          : property.community_description.substring(0, 150)}
                        {property.community_description.length > 150 && (
                          <>
                            {" "}
                            <a
        href="#"
        className="text-primary"
        style={{ cursor: 'pointer', fontWeight: 600, textDecoration: 'none', }}
        onClick={(e) => {
          e.preventDefault();
          handleToggleDescription(index);
        }}
      >
        {expandedDescriptionIndex === index ? ' Show Less' : '... More'}
      </a>
                          </>
                        )}
                      </p>
                      <div className="row d-flex justify-content-between mt-3">


                        <div className="d-flex col-lg-7 col-12">
                          <div className="col-3 d-flex align-items-center">
                            <img
                              src={require("../images/bed-alt.png")}
                              alt="Bedroom Icon"
                              className="me-1"
                            />
                            <h6
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {property.no_of_bedrooms_range}
                            </h6>
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <img
                              src={require("../images/bath.png")}
                              alt="Bathroom Icon"
                              className="me-1"
                            />
                            <h6
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {property.number_of_baths_range}
                            </h6>
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <img
                              src={require("../images/building.png")}
                              alt="Half Bath Icon"
                              className="me-2"
                            />
                            <h6
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {property.number_of_story_range}
                            </h6>
                          </div>
                          <div className="col-3 d-flex align-items-center">
                            <img
                              src={require("../images/garage-car.png")}
                              alt="Garage Icon"
                              className="me-1"
                            />
                            <h6
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {property.number_of_garages_range}
                            </h6>
                          </div>
                        </div>

                        <div className="col-lg-5 col-12 d-flex justify-content-lg-end">
                          <div className=" d-flex align-items-center">
                            <img
                              src={require("../images/area.png")}
                              alt="Garage Icon"
                              className="me-1"
                            />
                            <h6
                              className="mt-2 ms-1 inter font20 Lgrey"
                              style={{ fontWeight: 600 }}
                            >
                              {property.property_area_range}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1 col-md-1 p-1 p-md-0 ps-lg-3 mx-4"></div>
                  <hr className="mt-3 mb-3" /> {/* Line */}
                  <div className="d-flex justify-content-between">
                    <button
                      onClick={() => contactUs(property)}
                      className="btn inter"
                      style={{ color: "#0064C2", fontWeight: 500 }}
                    >
                      Contact Us
                    </button>

                    <button
                      className="btn h56 btn-primary label-background-color px-4"
                      onClick={() => handlePropertyDesc(property)}
                    >
                      View Property
                    </button>
                  </div>
                </div>
              </div>
        </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="slider-actions">
            <button
              type="button"
              className="slider-action-arrow slider-action-arrow--left property-arrow-left"
              aria-label="prev arrow"
            >
              <SliderArrow />
            </button>
            <div className="slider-action-dot property-dot" />
            <button
              type="button"
              className="slider-action-arrow slider-action-arrow--right property-arrow-right"
              aria-label="next arrow"
            >
              <SliderArrow />
            </button>
          </div>
        </div>
      </div>

      <div className="customContainer mt150" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6 col-12">
            <img
              src={require('../images/homeside.jpg')}
              alt="home left"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-6 col-12 d-lg-block d-flex flex-column align-items-center mt-5">
            <h1
              className="h1 font54 heading-color playfair"
              style={{ maxWidth: '678px' }}
            >
              We Provide Right Choice of Properties that You need.
            </h1>
            <p className="font20 inter mt-3" style={{ maxWidth: '640px' }}>
              Our aim is to challenge norms and standards through innovative
              global projects, transforming perceptions on quality to enrich
              communities and society as a whole.
            </p>

            <div className="d-flex mt-5">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="p-3 bg-white h-100 shadow rounded-3">
                    <h6 className="playfair font20">Prime Locations</h6>
                    <p className="inter font16">
                      In-demand areas, exceptional living.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="p-3 bg-white h-100 shadow rounded-3">
                    <h6 className="playfair font20">Trusted</h6>
                    <p className="inter font16">
                      Experience you can trust. Results you deserve.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="p-3 bg-white h-100 shadow rounded-3">
                    <h6 className="playfair font20">Luxurious</h6>
                    <p className="inter font16">
                      Where luxury meets impeccable design.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={handleModel}
              className="label-btn h56 px-3 mt-5  py-2"
            >
              Explore Models
              <FontAwesomeIcon className="ms-2" icon={faArrowCircleRight} />
            </button>
          </div>
        </div>
      </div>

      <div className="customContainer mt150" data-aos="fade-up">
        <h1 className="h1 text-center playfair heading-color font54">
          Find Properties
        </h1>

        <div className="d-flex justify-content-center ">
          <p
            className="text-center inter font20 my-4 "
            style={{ maxWidth: '558px' }}
          >
            Explore our curated selection of properties, each offering a unique
            story and a chance to redefine your life.
          </p>
        </div>
      </div>


      <div className="customContainer home-map" data-aos="fade-up">
        {showMap && <Map properties={properties} />}
      </div>


      <section className="zi100" data-aos="fade-up">
        <h1 className="h1 text-center playfair heading-color mb-2 mt-5 font54">
          About Brand
        </h1>
        <div className="mt-3 d-flex justify-content-center ">
          <p
            className="text-center inter mb-4 font20"
            style={{ maxWidth: '518px', margin: '0 auto' }}
          >
            We are a global luxury real estate developer dedicated to reshaping
            the essence of living through the creation of sustainable
            communities.
          </p>
        </div>

        <div className="row  mt-4 px-5 mx-5">


          
          <div className=" d-flex justify-content-center col">
            {/* <StateIcon className="me-2" /> */}

            <img
  className="me-2"
  src={State}
  alt="First slide"
  style={{ width: '50px', height: '50px' }}
/>


            <div className="d-flex mt-3">
              <h3 className="inter label-color me-1 font24">Multi</h3>
              <h4 className="mt-1 font18 greylabel">Cities</h4>
            </div>
          </div>

          <div className=" d-flex justify-content-center col mt-md-0 mt-4">
            {/* <BuildingIcon className="me-2" /> */}

            <img
  className="me-2"
  src={Building}
  alt="First slide"
  style={{ width: '50px', height: '50px' }}
/>

            <div className=" d-flex mt-3">
              <h3 className="inter label-color me-1 font24">Executive</h3>
              <h4 className="mt-1 font18 greylabel">Residences</h4>
            </div>
          </div>

          <div className="mt-md-0 mt-4 d-flex justify-content-center col">
            {/* <EarthIcon className="me-2" /> */}

            <img
  className="me-2"
  src={Earth}
  alt="First slide"
  style={{ width: '50px', height: '50px' }}
/>

            <div className="d-flex mt-3">
              <h3 className="inter label-color me-1 font24">Multi</h3>
              <h4 className="mt-1 font18 greylabel">Countries</h4>
            </div>
          </div>
        </div>
      </section>

      <div className="customContainer mt-5 " data-aos="fade-up">
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <img
                src={require('../images/head1.png')}
                alt="Card Img"
                className="rounded-3"
              />
            </div>
          </div>
          <div className="col-md-4 my-lg-0 my-4">
            <div className="card">
              <img
                src={require('../images/head2.png')}
                alt="Card Img"
                className="rounded-3"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <img
                src={require('../images/head3.png')}
                alt="Card Img"
                className="rounded-3"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="customContainer mt150" data-aos="fade-up">
        <h1 className="h1 playfair heading-color font54">
          Crafted Homes by Impeccable
        </h1>

        <p className="inter font20 mt-4" style={{ maxWidth: '712px' }}>
          We handcraft luxury homes where exceptional detail and craftsmanship
          are woven into every design, regardless of style or customization.
        </p>

        <div className="row mt-5">
          <div className="col-lg-6 col-md-12 ">
            <img
              src={require('../images/craft1.png')}
              alt="Craft1"
              className=" img-fluid  rounded-3"
            />
          </div>

          <div className="col-lg-6 col-md-12 mt-lg-0 mt-4">
            <img
              src={require('../images/craft2.png')}
              alt="Craft2"
              className="img-fluid  rounded-3"
            />
          </div>
        </div>
      </div>

      <div class="faq-home customContainer mt150 mb-5" data-aos="fade-up">
        <h1 class="h1 text-center playfair heading-color font54">
          Frequently Asked Question (FAQ)
        </h1>
        <div class="row justify-content-center mt-3">
          <div class="col-lg-5 mt-5 pb-lg-5 mb-lg-5">
            <h1 class="inter font32">Ask Your Question</h1>
            <p class="inter font20">
              If the question is not available on our FAQ section, Feel free to
              contact us personally, we will resolve your respective doubts.
            </p>
            <button onClick={contactUs} className="btn btn-primary h-56 mt-3">
              Contact Us
            </button>
          </div>
          <div className="col-lg-5 width-fit-con">
            <div className="accordion" id="faq-wrap">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    How do I start designing my custom home?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#faq-wrap"
                >
                  <div className="accordion-body">
                    Searching for the right design and builder may seem a
                    daunting task at first, but KR Real Estate has simplified
                    the process. Customers can find inspiration online at
                    ARHomes.com and browse hundreds of floor plan designs along
                    with 3D visualizations and model tours. The website provides
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    What if I don’t already have land or a lot for my home?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faq-wrap"
                >
                  <div className="accordion-body">
                    Searching for the right design and builder may seem a
                    daunting task at first, but KR Real Estate has simplified
                    the process. Customers can find inspiration online at
                    ARHomes.com and browse hundreds of floor plan designs along
                    with 3D visualizations and model tours. The website provides
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    How long does it take to build a custom home?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faq-wrap"
                >
                  <div className="accordion-body">
                    Searching for the right design and builder may seem a
                    daunting task at first, but KR Real Estate has simplified
                    the process. Customers can find inspiration online at
                    ARHomes.com and browse hundreds of floor plan designs along
                    with 3D visualizations and model tours. The website provides
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    What if I don’t already have land or a lot for my home?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faq-wrap"
                >
                  <div className="accordion-body">
                    Searching for the right design and builder may seem a
                    daunting task at first, but KR Real Estate has simplified
                    the process. Customers can find inspiration online at
                    ARHomes.com and browse hundreds of floor plan designs along
                    with 3D visualizations and model tours. The website provides
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    What are the advantages of building a custom home?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#faq-wrap"
                >
                  <div className="accordion-body">
                    Searching for the right design and builder may seem a
                    daunting task at first, but KR Real Estate has simplified
                    the process. Customers can find inspiration online at
                    ARHomes.com and browse hundreds of floor plan designs along
                    with 3D visualizations and model tours. The website provides
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}

      <ScrollToTopButton />
    </>
  );
};

export default Home;
