import React, { useState, useEffect } from 'react';
// import Navbar from '../Components/Navbar';
// import Footer from '../Components/Footer';
// import { Helmet } from 'react-helmet';
import httpproperties from '../js/http-realestate-properties';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preserve1 from '../images/ModelImages/Preserve1/Preserve1';
import Preserve2 from '../images/ModelImages/Preserve2/Preserve2';
import Preserve3 from '../images/ModelImages/Preserve3/Preserve3';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import ScrollToTopButton from '../Components/ScrollToTop';
import SuwaneePreserves from '../images/PropertyImages/SuwaneePreserves/SuwaneePreserves';
import Prop448 from '../images/PropertyImages/Prop448/Prop448';
import Prop39 from '../images/PropertyImages/Prop39/Prop39';
import Prop445 from '../images/PropertyImages/Prop445/Prop445';
import Model448 from '../images/ModelImages/Model448/Model448';
import Model39 from '../images/ModelImages/Model39/Model39';
import Model445 from '../images/ModelImages/Model445/Model445';
import PropertyBrochure from '../images/Brochure/PropertyBrochure';
import ModelBrochure from '../images/Brochure/ModelBrochure';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { ReactComponent as SliderArrow } from '../images/slider-next-arrow.svg';
import PageSeoComponent from '../SEO';
import SEO from '../Constant/SEO.json';

const Specifications = () => {
  const [properties, setProperties] = useState([]);
  const [models, setModels] = useState([]);
  const [error, setError] = useState();
  const [selectedBrochurePdf, setSelectedBrochurePdf] = useState(null);

  useEffect(() => {
    getPropertiesList();
    getModelsList();
  }, []);

  function getPropertiesList() {
    httpproperties
      .get('propertyManagement/getAllPropertyListForUser')
      .then((response) => {
        if (response.data === 'No records found') {
          setError(response.data);
        } else {
          console.log('fetch properties list: ', response);

          // Sort properties in ascending order by a specific field, e.g., 'propertyName'
          const sortedProperties = response.data.sort((a, b) => {
            // Replace 'propertyName' with the appropriate field you want to sort by
            return a.property_title.localeCompare(b.property_title);
          });

          setProperties(sortedProperties);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const handleDownloadBrochure = (brochureImage) => {
    if (brochureImage && brochureImage.image_url) {
      const link = document.createElement('a');
      link.href = brochureImage.image_url;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Brochure image not found or invalid URL');
    }
  };

  const getDocuments = async (id, isProperty, propertyOrModelTitle) => {
    let brochureImage = null;

    // If it's a property, set the appropriate brochure path based on title
    if (isProperty) {
      if (propertyOrModelTitle === 'Suwanee Preserves') {
        brochureImage = PropertyBrochure[0]?.brochurePath;
      } else if (propertyOrModelTitle === '1#Dahlonega-GA-30533-USA') {
        brochureImage = PropertyBrochure[1]?.brochurePath;
      } else if (propertyOrModelTitle === '2#Dahlonega-GA-30533-USA') {
        brochureImage = PropertyBrochure[2]?.brochurePath;
      } else if (propertyOrModelTitle === '3#Dahlonega-GA-30533-USA') {
        brochureImage = PropertyBrochure[3]?.brochurePath;
      }
    } else {
      // If it's a model, set the appropriate brochure path based on title
      if (propertyOrModelTitle === 'Preserve#1') {
        brochureImage = ModelBrochure[0]?.brochurePath;
      } else if (propertyOrModelTitle === 'Preserve#2') {
        brochureImage = ModelBrochure[1]?.brochurePath;
      } else if (propertyOrModelTitle === 'Preserve#3') {
        brochureImage = ModelBrochure[2]?.brochurePath;
      } else if (propertyOrModelTitle === 'model#39') {
        brochureImage = ModelBrochure[3]?.brochurePath;
      } else if (propertyOrModelTitle === 'model#445') {
        brochureImage = ModelBrochure[4]?.brochurePath;
      } else if (propertyOrModelTitle === 'model#448') {
        brochureImage = ModelBrochure[5]?.brochurePath;
      }
    }

    // If brochureImage is not set from hardcoded paths, fetch it from the API
    if (!brochureImage) {
      try {
        const url = isProperty
          ? `/propertyManagement/getUrlByImgType?imgType=Specification&propertyId=${id}`
          : `/propertyManagement/getUrlByImgType?imgType=Specification&modelId=${id}`;

        const res = await httpproperties.get(url);
        brochureImage = res.data?.image_url; // Assuming res.data contains image_url
      } catch (err) {
        console.log(err);
      }
    }

    // Set the selected brochure to download if it exists
    if (brochureImage) {
      handleDownloadBrochure({ image_url: brochureImage });
    } else {
      console.error('Brochure image not found or invalid URL');
    }
  };

  useEffect(() => {
    if (selectedBrochurePdf) {
      handleDownloadBrochure(selectedBrochurePdf);
    }
  }, [selectedBrochurePdf]);

  function getModelsList() {
    httpproperties
      .get('/modelInformation/allmodelinformation')
      .then((response) => {
        if (response.data === 'No Records Found') {
          // setLoading(1);
          setError(response.data);
        } else {
          // setLoading(1);
          console.log('fetch Model list: ', response);

          // Sort models in ascending order by a specific field, e.g., 'modelName'
          const sortedModels = response.data.sort((a, b) => {
            // Replace 'modelName' with the actual field you want to sort by
            return a.modelTitle.localeCompare(b.modelTitle);
          });

          setModels(sortedModels);
        }
      })
      .catch((e) => {
        // setLoading(1);
        console.log(e);
        setError('Error fetching models');
      });
  }

  return (
    <>
      <PageSeoComponent {...SEO.default} />

      {/* <Helmet>
        <title>Specifications</title>
        <meta
          name="description"
          content="Easiest way to find your dream home"
        />
        <meta name="keywords" content="keywords, separated, by, commas" />
        <link rel="canonical" href="https://www.example.com/page" />
      </Helmet> */}

      {/* <Navbar class="position-fixed d-flex justify-content-between zi100 py-2 w-100 greybg px-5" /> */}
      {/* 
      <Navbar
        
        class="position-fixed d-flex justify-content-between zi100 py-2 w-100  greybg px-5"
      /> */}

      <div className="" style={{ position: 'relative', height: '100%' }}>
        <img
          src={require('../images/specification.png')}
          alt="Background"
          className="background-propertydesc-image"
          style={{ marginTop: '110px' }}
        />

        {/* <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: '10',
          }}
        >
          <h1>Project Specification</h1>
        </div> */}
      </div>

      {error && <h3 className="text-center mt-5 mb-2">{error}</h3>}

      <div className="property-specification-wrap d-flex justify-content-center">
        <div className="container">
          <div className="accordion" id="property-specification-accordion">
            <div className="accordion-item">
              <h2 className="accordion-header playfair ps-3 pt-3">
                Property Specification
              </h2>
              <div id="collapseOne" className="accordion-collapse show">
                <div className="accordion-body">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    breakpoints={{
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        slidesPerGroup: 2,
                      },
                      1280: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                        slidesPerGroup: 3,
                      },
                    }}
                    pagination={{
                      type: 'fraction',
                      el: '.property-dot',
                      clickable: true,
                    }}
                    navigation={{
                      nextEl: '.property-arrow-right',
                      prevEl: '.property-arrow-left',
                    }}
                    modules={[Pagination, Navigation]}
                  >
                    {properties.map((property, index) => {
                      const validImages = property.imageSrcLink.filter(
                        (image) =>
                          image.imgId !== 9 &&
                          image.imgId !== 12 &&
                          image.imgId !== 17
                      );
                      const imageUrl =
                        validImages.length > 0 ? validImages[0].imageUrl : '';

                      const brochureDownload = property.imageSrcLink.filter(
                        (image) => image.imgId !== 17
                      );
                      const brochureDownloadURL =
                        brochureDownload.length > 0
                          ? brochureDownload[0].imageUrl
                          : '';
                      return (
                        <SwiperSlide key={index}>
                          <div className="card ">
                            <div className="property-specification-img rounded-3">
                              {property.property_title ===
                              'Suwanee Preserves' ? (
                                <img
                                  src={SuwaneePreserves[0].imgPath}
                                  alt="propImg"
                                  className="imagek rounded-3"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              ) : property.property_title ===
                                '1#Dahlonega-GA-30533-USA' ? (
                                <img
                                  src={Prop448[0].imgPath}
                                  alt="propImg"
                                  className="imagek rounded-3"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              ) : property.property_title ===
                                '2#Dahlonega-GA-30533-USA' ? (
                                <img
                                  src={Prop39[3].imgPath}
                                  alt="propImg"
                                  className="imagek rounded-3"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              ) : property.property_title ===
                                '3#Dahlonega-GA-30533-USA' ? (
                                <img
                                  src={Prop445[4].imgPath}
                                  alt="propImg"
                                  className="imagek rounded-3"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              ) : (
                                imageUrl && (
                                  <img
                                    src={imageUrl}
                                    alt="propImg"
                                    className="imagek rounded-3"
                                  />
                                )
                              )}
                              <h3 className="property-specification-title">
                                {property.property_title}
                              </h3>
                              <div className="overlay">
                                <div className="bottom-content py-2 bottom-0 start-0 w-100 d-flex justify-content-between rounded-3 ">
                                  {' '}
                                  {/* Position the content at the bottom */}
                                  <h3>{property.property_title}</h3>
                                  <a
                                    onClick={() =>
                                      getDocuments(
                                        property.property_id,
                                        true,
                                        property.property_title
                                      )
                                    }
                                  >
                                    <button className="btn btn-primary border-0 color-primary">
                                      Download
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon={faArrowCircleDown}
                                      />
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>

                  <div className="slider-actions">
                    <button
                      type="button"
                      className="slider-action-arrow slider-action-arrow--left property-arrow-left"
                      aria-label="prev arrow"
                    >
                      <SliderArrow />
                    </button>
                    <div className="slider-action-dot property-dot" />
                    <button
                      type="button"
                      className="slider-action-arrow slider-action-arrow--right property-arrow-right"
                      aria-label="next arrow"
                    >
                      <SliderArrow />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header playfair ps-3 pt-3">
                Model Specification
              </h2>
              <div id="collapseTwo" className="accordion-collapse show">
                <div className="accordion-body">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    breakpoints={{
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        slidesPerGroup: 2,
                      },
                      1280: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                        slidesPerGroup: 3,
                      },
                    }}
                    pagination={{
                      type: 'fraction',
                      el: '.property-model-dot',
                      clickable: true,
                    }}
                    navigation={{
                      nextEl: '.property-model-arrow-right',
                      prevEl: '.property-model-arrow-left',
                    }}
                    modules={[Pagination, Navigation]}
                  >
                    {models.map((models, index) => {
                      // const validImages = models.imageSrcLink.filter(
                      //   (image) =>
                      //     image.imgId !== 9 &&
                      //     image.imgId !== 12 &&
                      //     image.imgId !== 17
                      // );

                      // const imageUrl =
                      //   validImages.length > 0 ? validImages[0].imageUrl : '';

                      // const brochureDownload = models.imageSrcLink.filter(
                      //   (image) => image.imgId !== 17
                      // );

                      // const brochureDownloadURL =
                      //   brochureDownload.length > 0
                      //     ? brochureDownload[0].imageUrl
                      //     : '';

                      return (
                        <SwiperSlide key={index}>
                          <div className="card ">
                            <div className="property-specification-img rounded-3">
                              {models.modelTitle === 'Preserve#1' ? (
                                <img
                                  src={Preserve1[0].imgPath}
                                  alt="modelImg"
                                  className="imagek rounded-3"
                                />
                              ) : models.modelTitle === 'Preserve#2' ? (
                                <img
                                  src={Preserve2[0].imgPath}
                                  alt="modelImg"
                                  className="imagek rounded-3"
                                />
                              ) : models.modelTitle === 'Preserve#3' ? (
                                <img
                                  src={Preserve3[0].imgPath}
                                  alt="modelImg"
                                  className="imagek rounded-3"
                                />
                              ) : models.modelTitle === 'model#448' ? (
                                <img
                                  src={Model448[1].imgPath}
                                  alt="modelImg"
                                  className="imagek rounded-3"
                                />
                              ) : models.modelTitle === 'model#39' ? (
                                <img
                                  src={Model39[6].imgPath}
                                  alt="modelImg"
                                  className="imagek rounded-3"
                                />
                              ) : models.modelTitle === 'model#445' ? (
                                <img
                                  src={Model445[1].imgPath}
                                  alt="modelImg"
                                  className="imagek rounded-3"
                                />
                              ) : (
                                models.image_url && (
                                  <img
                                    src={models.image_url}
                                    alt="modelImg"
                                    className="imagek rounded-3"
                                  />
                                )
                              )}
                              <h3 className="property-specification-title">
                                {models.modelTitle}
                              </h3>
                              <div className="overlay">
                                {' '}
                                {/* Div below the image */}
                                <div className="bottom-content py-2 bottom-0 start-0 w-100 d-flex justify-content-between rounded-3 ">
                                  {' '}
                                  {/* Position the content at the bottom */}
                                  <h3>{models.modelTitle}</h3>
                                  <a
                                    onClick={() =>
                                      getDocuments(
                                        models.id,
                                        false,
                                        models.modelTitle
                                      )
                                    }
                                  >
                                    <button className="btn btn-primary border-0 color-primary">
                                      Download
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon={faArrowCircleDown}
                                      />
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>

                  <div className="slider-actions">
                    <button
                      type="button"
                      className="slider-action-arrow slider-action-arrow--left property-model-arrow-left"
                      aria-label="prev arrow"
                    >
                      <SliderArrow />
                    </button>
                    <div className="slider-action-dot property-model-dot" />
                    <button
                      type="button"
                      className="slider-action-arrow slider-action-arrow--right property-model-arrow-right"
                      aria-label="next arrow"
                    >
                      <SliderArrow />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
      <ScrollToTopButton />
    </>
  );
};

export default Specifications;
