import Preserve1FloorPlan from "./ModelFloorPlan/Preserve_1.pdf"
import Preserve2FloorPlan from "./ModelFloorPlan/Preserve_2.pdf"
import Preserve3FloorPlan from "./ModelFloorPlan/Preserve_3.pdf"
import Model39FloorPlan from "./ModelFloorPlan/model_39.jpg"
import Model445FloorPlan from "./ModelFloorPlan/model_445.jpg"
import Model448FloorPlan from "./ModelFloorPlan/model_448.jpg"





const ModelFloorPlan = [

 
  {
    "SNO": 1,
    "floorPath": Preserve1FloorPlan
},

{
  "SNO": 2,
  "floorPath": Preserve2FloorPlan
},

{
  "SNO": 3,
  "floorPath": Preserve3FloorPlan
},

{
  "SNO": 4,
  "floorPath": Model39FloorPlan
},

{
    "SNO": 5,
    "floorPath": Model445FloorPlan
  },

  
  {
    "SNO": 5,
    "floorPath": Model448FloorPlan
  },
  



];

export default ModelFloorPlan;