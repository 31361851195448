//import axios from 'axios'
// this file used to create a setup to connect with backend while using API calls
//while using an API call we need to add header with token(getting in the response while user login) to that for getting authorized by backend
// import { useNavigate } from "react-router-dom";

export default function Setup(instance) {
    // if user trying to access any page with out login into dashboard this case will work 
    // check the isLoggedin flag value in localStorage
    // if it is false then redirecting to the login page
    var isLogged=sessionStorage.getItem("LOGGEDIN");
    //console.log(isLogged)
    if(isLogged == 'false'){
        sessionStorage.clear()
        localStorage.clear()
        // window.location.replace('http://lmsportaluser-ui-dev.s3-website-us-east-1.amazonaws.com/')
    }
    
    // const navigate = useNavigate()
    // creating an instace and later on this instance will used to create axios instance in main.js file
    instance.interceptors.request.use(function(config) {
        if(sessionStorage.getItem("LOGGEDIN")){
            config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('JWT');
            config.headers['UserId'] = sessionStorage.getItem('userEmail');
        }
        return config
    }, function(err) {
        return Promise.reject(err)
    })


    // this instance will help when getting 401 error(unauthorized) from server side then directly it will redirect to the login page
    instance.interceptors.response.use(function(response) {
        return response
    }, function(err) {
        if(err.response) {            
            if(err.response.status == '401') {
                // navigate('/');
                sessionStorage.clear()
                localStorage.clear()
                // window.location.replace('http://lmsportaluser-ui-dev.s3-website-us-east-1.amazonaws.com/')
            }
        } else if(err.request) {
            console.log(err.request)
        }
        return Promise.reject(err)
    })
}