import React from 'react';
import { Helmet } from 'react-helmet-async';

type OpenGraphProps = {
  title?: string;
  description?: string;
  url?: string;
  imageUrl?: string;
  siteName?: string;
};

type TwitterProps = {
  cardType?: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  imageAlt?: string;
};

type PinterestProps = {
  imageUrl?: string;
};

type PageSeoComponentProps = {
  title?: string;
  description?: string;
  keywords?: string;
  url?: string;
  opengraph: OpenGraphProps;
  twitter: TwitterProps;
  pinterest: PinterestProps;
};

const PageSeoComponent: React.FC<PageSeoComponentProps> = ({
  title,
  description,
  keywords,
  url = window.location.href,
  opengraph,
  twitter,
  pinterest,
}) => {
  const defaultImage = `https://krhomes.net/cover/kr-homes-2.jpg`;

  return (
    <Helmet
      prioritizeSeoTags
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={window.location.href} />

      {/* Open Graph Tags */}
      <meta property="og:title" content={opengraph?.title || title} />
      <meta
        property="og:description"
        content={opengraph?.description || description}
      />
      <meta property="og:url" content={opengraph?.url || url} />
      <meta property="og:image" content={defaultImage} />
      <meta property="og:site_name" content={opengraph?.siteName} />

      {/* Twitter Card Tags */}
      <meta
        name="twitter:card"
        content={twitter?.cardType || 'summary_large_image'}
      />
      <meta name="twitter:title" content={twitter?.title || title} />
      <meta
        name="twitter:description"
        content={twitter?.description || description}
      />
      <meta name="twitter:image" content={defaultImage} />
      <meta name="twitter:image:alt" content={twitter?.imageAlt || ''} />

      {/* Pinterest Tag */}
      <meta property="og:image" content={defaultImage} />
      <meta name="pinterest-rich-pin" content="true" />
    </Helmet>
  );
};

export default PageSeoComponent;
