import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import React, { useEffect, useState, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';
import mapPin from '../images/homeicon.png';
import { ROUTES } from '../Constant/Routes';

const propertyIcon = L.icon({
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  iconUrl: require('../images/homeicon.png'),
});

const propertyIconHover = L.icon({
  iconSize: [48, 48],
  iconAnchor: [24, 48],
  iconUrl: require('../images/homeicon.png'),
});

function Map({ properties }) {
  useEffect(() => {
    console.log(properties, 'propertiesList767676767676767');
  }, [properties]);

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedOption, setSelectedOption] = useState('USA');
  const mapRef = useRef(null);
  const navigate = useNavigate();

  function MapCenter({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  const handleCityClick = (property) => {
    if (mapRef.current) {
      mapRef.current.flyTo(
        [
          parseFloat(property?.latitude) || 0,
          parseFloat(property?.longitude) || 0,
        ],
        15,
        {
          duration: 1,
        }
      );
      setSelectedProperty(property);
    }
  };

  const handlePropertyClick = () => {
    // Handle property marker click if needed
  };

  const handleBack = () => {
    setSelectedProperty(null);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    if (event.target.value === 'India') {
      setSelectedProperty(null);
    } else if (event.target.value === 'USA') {
      setSelectedProperty(null);
    }
  };

  const cityLabel = (city) =>
    L.divIcon({
      className: 'city-label',
      html: `<img src="${mapPin}" width="32" height="32" title="${city}"> <span>${city}</span>`,
      // html: `<span>${city}</span>`
    });

  useEffect(() => {
    if (selectedOption === 'USA' && mapRef.current) {
      mapRef.current.setView([37.0902, -95.7129], 4);
    }
  }, []);

  const getValidImageUrl = (imageSrcLink) => {
    const filteredImages = imageSrcLink
      ? imageSrcLink.filter(
          (image) =>
            image.imgId !== 9 && image.imgId !== 12 && image.imgId !== 17
        )
      : [];
    return filteredImages.length > 0 ? filteredImages[0].imageUrl : '';
  };

  const handlePropertyDesc = (property) => {
    console.log('kkokoko');
    navigate(ROUTES.PROPERTY_DESC, { state: { propertyInfo: property } });
  };

  return (
    <div>
      <div className="text-center">
  <label 
    htmlFor="countrySelect" 
    style={{
      position: 'absolute',
      top: '5px',
      left: '60px',
      zIndex: 10000,
    }}
  >
    Select Country:
  </label>
  <select
    id="countrySelect"
    className="selectbox form-select form-select-map shadow-sm py-2 px-2 w-25"
    style={{
      position: 'absolute',
      top: '32px',
      left: '60px',
      zIndex: 10000,
    }}
    value={selectedOption}
    onChange={handleOptionChange}
  >
    {/* <option value="">Select Country</option> */}
    <option value="India">India</option>
    <option value="USA">USA</option>
  </select>
</div>


      <MapContainer
        ref={mapRef}
        center={[37.0902, -95.7129]}
        zoom={4}
                minZoom={3}
        style={{ height: '750px', zIndex: '1', borderRadius: '10px' }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {selectedProperty ? (
          <Marker
            position={[
              parseFloat(selectedProperty?.latitude) || 0,
              parseFloat(selectedProperty?.longitude) || 0,
            ]}
            icon={propertyIcon}
            eventHandlers={
              {
                // click: handlePropertyClick,
                // mouseover: (e) => e.target.setIcon(propertyIconHover),
                // mouseout: (e) => e.target.setIcon(propertyIcon),
              }
            }
          >
            <Popup>
              <div
                className="map-popup-link"
                onClick={() => handlePropertyDesc(selectedProperty)}
              >
                <h2>{selectedProperty.property_title}</h2>
                {selectedProperty.imageSrcLink && (
                  <img
                    src={getValidImageUrl(selectedProperty.imageSrcLink)}
                    alt={selectedProperty.property_title}
                    style={{ width: '100%' }}
                  />
                )}
                <p>Price: {selectedProperty.prices_range}</p>
              </div>
            </Popup>
          </Marker>
        ) : (
          properties.map((property, index) => (
            <Marker
              key={index}
              position={[
                parseFloat(property?.latitude) || 0,
                parseFloat(property?.longitude) || 0,
              ]}
              eventHandlers={
                {
                  // mouseover: (e) => e.target.setIcon(propertyIconHover),
                  // mouseout: (e) => e.target.setIcon(propertyIcon),
                  // click: () => handleCityClick(property),
                }
              }
              icon={cityLabel(property?.city?.cityName || '')}
            >
              <Popup>
                <div
                  className="map-popup-link"
                  onClick={() => handlePropertyDesc(property)}
                >
                  <h2>{property?.property_title}</h2>
                  {property.imageSrcLink && (
                    <img
                      src={getValidImageUrl(property.imageSrcLink)}
                      alt={property?.property_title}
                      style={{ width: '100%' }}
                    />
                  )}
                  <p>Price: {property.prices_range}</p>
                </div>
              </Popup>
            </Marker>
          ))
        )}

        {selectedOption === 'India' && (
          <MapCenter center={[20.5937, 78.9629]} zoom={5} />
        )}
        {selectedOption === 'USA' && (
          <MapCenter center={[37.0902, -95.7129]} zoom={5} />
        )}

        {selectedProperty && (
          <div className="back-button-container">
            <button className="back-button" onClick={handleBack}>
              Back
            </button>
          </div>
        )}
      </MapContainer>
    </div>
  );
}

export default Map;
