import img1 from "./Prop448_Images/1_001 - 448 golden bear pass dahlonega ga.webp"
import img2 from "./Prop448_Images/4_004 - 448 golden bear pass dahlonega ga.webp"
import img3 from "./Prop448_Images/61_061 - 448 golden bear pass dahlonega ga.webp";
import img4 from "./Prop448_Images/63_001 - 448 golden bear pass.webp";
import img5 from "./Prop448_Images/70_008 - 448 golden bear pass.webp";



const Prop448 = [

 
  {
    "SNO": 1,
    "id": 3,
    "imgType": "Exterior Front",
    "imgPath": img1
},

{
  "SNO": 2,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img2
},

{
  "SNO": 3,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img3
},


{
  "SNO": 4,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img4
},


{
  "SNO": 5,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img5
},

];

export default Prop448;
