import React, { useState, useEffect } from 'react';
import Footer from '../Components/Footer';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import httpproperties from '../js/http-realestate-properties';
import { Carousel } from 'react-bootstrap';
// import { Helmet } from 'react-helmet';
import ScrollToTopButton from '../Components/ScrollToTop';
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import httpuserprofile from '../js/http-lms_userprofile';
import Select from 'react-select';
import 'aos/dist/aos.css'; // Import AOS CSS
import { ROUTES } from '../Constant/Routes';
import SuwaneePreserves from "../images/PropertyImages/SuwaneePreserves/SuwaneePreserves";
import Prop448 from "../images/PropertyImages/Prop448/Prop448";
import Prop39 from "../images/PropertyImages/Prop39/Prop39";
import Prop445 from "../images/PropertyImages/Prop445/Prop445";
import AOS from 'aos';
import axios from 'axios';
import PageSeoComponent from '../SEO';
import SEO from '../Constant/SEO.json';

const Projects = () => {
  const [properties, setProperties] = useState([]);
  const [error, setError] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProp, setSelectedProp] = useState();
  const [showSalesTeamInfo, setShowSalesTeamInfo] = useState(false);
  const [expandedDescriptionIndex, setExpandedDescriptionIndex] =
    useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);

  const [location, setLocation] = useState('');
  const [homeType, setHomeType] = useState('');
  const [propStatus, setPropStatus] = useState([]);
  const [propStatusFilter, setPropStatusFilter] = useState([]);
  const [propertyTitle, setPropertyTitle] = useState('');
  const [propertyAreaRange, setPropertyAreaRange] = useState('');
  const [noOfBedroomsRange, setNoOfBedroomsRange] = useState('');
  const [noOfStoryRange, setNoOfStoryRange] = useState('');
  const [noOfBathsRange, setNoOfBathsRange] = useState('');

  const cardsPerPage = 12;

  const handlePageChange = (selectedItem) => {
    setCurrentPage(selectedItem.selected);
    window.scrollTo(0, 1400); // Scroll to top
  };

  AOS.init({
    duration: 600, // Animation duration
    easing: 'ease-in-out', // Easing function
  });

  const indexOfLastCard = (currentPage + 1) * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = properties.slice(indexOfFirstCard, indexOfLastCard);

  const navigate = useNavigate();

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    getPropertiesList();
    propertyStatus();

    httpuserprofile.get('/userprofileLMS/getUserCountries').then((res) => {
      console.log('user countries', res);
      setCountriesList(res.data);
    });
  }, []);

  const handleToggleDescription = (index) => {
    if (expandedDescriptionIndex === index) {
      setExpandedDescriptionIndex(null);
    } else {
      setExpandedDescriptionIndex(index);
    }
  };

  const propertyStatus = () => {
    httpuserprofile
      .get('/userprofileLMS/getStaticValuesListById?indexCode=9')
      .then((result) => {
        console.log(result.data, 'propStatus');
        setPropStatus(result.data);
      })
      .catch((err) => {
        console.log(err, 'ERRRR');
      });
  };

  function getPropertiesList() {
    setSelectedCountryOption(null);
    setSelectedStateOption(null);
    setSelectedCityOption(null);
    setPropertyTitle('');
    setHomeType('');
    setPropertyAreaRange('');
    setNoOfBedroomsRange('');
    setNoOfStoryRange('');
    setNoOfBathsRange('');
    setPropStatusFilter('');
    httpproperties
    .get('/propertyManagement/getAllPropertyList')
    .then((response) => {
      if (response.data === 'No records found') {
        setLoading(1);
        setError(response.data);
      } else {
        setLoading(1);
        console.log('fetch properties list: ', response);
        
        // Sort properties, prioritizing 'Suwanee Preserves'
        const sortedProperties = response.data.sort((a, b) => {
          if (a.property_title === 'Suwanee Preserves') return -1;
          if (b.property_title === 'Suwanee Preserves') return 1;
          return a.property_title.localeCompare(b.property_title);
        });
  
        setProperties(sortedProperties);
      }
    })
    .catch((e) => {
      console.log(e);
    });
  
  }

  function contactUs(property) {
    navigate(ROUTES.CONTACT_US, { state: { propertyInfo: property } });
  }

  function handlePropertyDesc(property) {
    navigate(ROUTES.PROPERTY_DESC, { state: { propertyInfo: property } });
  }

  function getSelectedProp(id) {
    setShowPopup(!showPopup);

    axios
      .get(
        `http://localhost:13059/propertyManagement/getPropertyListByIdOrTitle?propertyId=${id}`
      )
      .then((response) => {
        setSelectedProp(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleCountryChange(selectedCountry) {
    console.log('selected country', selectedCountry);
    setSelectedCountryOption(selectedCountry);
    setSelectedStateOption(null);
    setSelectedCityOption(null);
    setStatesList([]);
    setCitiesList([]);

    if (selectedCountry) {
      // setProperty((prevProperty) => ({
      //   ...prevProperty,
      //   countryId: selectedCountry.countryId,
      // }));

      httpuserprofile
        .get(
          '/userprofileLMS/getUserStates?countryId=' + selectedCountry.countryId
        )
        .then((res) => {
          console.log('user states', res);
          setStatesList(res.data);
        })
        .catch((error) => {
          console.error('Error fetching states:', error);
        });
    }
  }

  function handleStateChange(selectedState) {
    console.log('selected state', selectedState);
    setSelectedStateOption(selectedState);
    setSelectedCityOption(null);
    setCitiesList([]);

    if (selectedState) {
      // setProperty((prevProperty) => ({
      //   ...prevProperty,
      //   stateId: selectedState.stateId,
      // }));

      httpuserprofile
        .get('/userprofileLMS/getUserCities?stateId=' + selectedState.stateId)
        .then((res) => {
          console.log('user cities', res);
          setCitiesList(res.data);
        })
        .catch((error) => {
          console.error('Error fetching cities:', error);
        });
    }
  }

  function handleCityChange(selectedCity) {
    console.log('selected city : ', selectedCity);
    setSelectedCityOption(selectedCity);

    // if (selectedCity) {
    //   setProperty((prevProperty) => ({
    //     ...prevProperty,
    //     cityId: selectedCity.cityId,
    //   }));
    // }
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      // border: `1px solid #ced4da`,
      // boxShadow: `0 0 0 1px`,
      // ':hover' : {
      //   // boxShadow: `0 0 0 1px `,
      //   backgroundColor : "blue"
      // }
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor:
        state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: '14px',
      paddingTop: '4px',
      paddingBottom: '4px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };

  const handleSearch = async () => {
    try {
      const response = await httpproperties.get(
        '/propertyManagement/getAllPropertyListForUser',
        {
          params: {
            cityName: selectedCityOption?.cityName || '',
            stateName: selectedStateOption?.stateName || '',
            countryName: selectedCountryOption?.countryName || '',
            propertyAreaRange,
            noOfBedroomsRange,
            noOfStoryRange,
            noOfBathsRange,
            homeType,
            propertyTitle,
            status: propStatusFilter,
          },
        }
      );
      setLoading(1);
      if (response.data === 'No records found') {
        setProperties([]); // Set an empty array if no records are found
      } else {
        setProperties(response.data);
      }
    } catch (error) {
      setLoading(1);
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <PageSeoComponent {...SEO['projects']} />

      {/* <Helmet>
        <title>Find Your Home</title>
        <meta
          name="description"
          content="Easiest way to find your dream home. Discover luxury homes, custom homes, and more."
        />
        <meta
          name="keywords"
          content="single family home, luxury homes, custom homes, luxury custom homes, Suwanee Preserves, Cumming custom luxury homes, Sky Lane, new homes in Cumming, Luxury Homes Cumming GA, Luxury Real Estate Cumming Georgia, High-End Homes Cumming GA, Luxury Home Builders Cumming GA, New Luxury Homes in Cumming GA, Luxury Houses for Sale in Cumming GA, Custom Luxury Homes Cumming GA, Luxury Homes Forsyth County GA, Upscale Homes Cumming Georgia, Premium Properties Cumming GA, Luxury Residences Cumming GA, Gated Community Homes Cumming GA, Luxury Lakefront Homes Cumming GA, Modern Luxury Homes Cumming GA, Executive Homes Cumming GA, Luxury Living Cumming GA, Top Luxury Home Builders in Cumming GA, Exclusive Homes Cumming GA, Luxury Home Communities Cumming GA, Luxury Home Listings Cumming GA, Custom Home Construction Georgia, Custom Luxury Homes in Georgia, High-End Custom Homes Georgia, Luxury Custom Houses for Sale in Georgia, New Custom Home Developments Georgia, Custom Homes Forsyth County, High-End Custom Real Estate Georgia, Premium Custom Homes Georgia, Custom Residences in Georgia, Custom Gated Community Homes in Georgia, Custom Luxury Lakefront Homes Georgia, Executive Custom Homes Georgia, Top Custom Home Builders in Georgia, Custom Home Communities in Georgia, Custom Home Listings in Georgia"
        />
        <link rel="canonical" href="https://www.krhomes.net/projects" />
      </Helmet> */}

      {/* <Navbar class="position-fixed d-flex justify-content-between zi100 py-2 w-100 greybg px-5" /> */}

      <Carousel style={{ height: '', overflow: 'hidden' }}
      className="home-banner">
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src={require('../images/PP2.jpg')}
            alt="First slide"
            // style={{ maxHeight: '100vh', objectFit: 'cover', width: '100%' }}
          />
          <Carousel.Caption
            style={{
              position: 'absolute',
              bottom: '0', // Use bottom to ensure the caption is at the bottom of the carousel
              transform: 'translateY(-20%)', // Adjusted to ensure proper positioning
              color: 'white',
              zIndex: 1, // Ensure this is high enough to be on top of the image
            }}
          ></Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src={require('../images/PP3.jpg')}
            alt="Second slide"
            // style={{ maxHeight: '100vh', objectFit: 'cover', width: '100%' }}
          />
          <Carousel.Caption
            style={{
              position: 'absolute',
              bottom: '0', // Use bottom to ensure the caption is at the bottom of the carousel
              transform: 'translateY(-20%)', // Adjusted to ensure proper positioning
              color: 'white',
              zIndex: 1, // Ensure this is high enough to be on top of the image
            }}
          ></Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src={require('../images/PP10.png')}
            alt="Third slide"
            // style={{ maxHeight: '100vh', objectFit: 'cover', width: '100%' }}
          />
          <Carousel.Caption
            style={{
              position: 'absolute',
              bottom: '0', // Use bottom to ensure the caption is at the bottom of the carousel
              transform: 'translateY(-20%)', // Adjusted to ensure proper positioning
              color: 'white',
              zIndex: 1, // Ensure this is high enough to be on top of the image
            }}
          ></Carousel.Caption>
        </Carousel.Item>

        {/* Add more Carousel.Items as needed */}
      </Carousel>
      <div className="d-flex justify-content-center " data-aos="fade-up">
        <h1
          className=" text-center playfair heading-color font54 mt150"
          style={{ maxWidth: '1000px' }}
        >
          Discover a premium lifestyle
        </h1>
      </div>

      {/* <div className="container bgFilter rounded-4 pb-2 pt-3">
        <div className="row">
          <div className="col-10">
            <div className="row d-flex justify-content-center mb-3 ">
              <div className="col">
                <Select
                  value={selectedCountryOption}
                  onChange={handleCountryChange}
                  placeholder="Select Country"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.countryName}`}
                  options={countriesList}
                />
              
              </div>

              <div className="col">
                <Select
                  value={selectedStateOption}
                  onChange={handleStateChange}
                  placeholder="Select State"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.stateName}`}
                  options={statesList}
                />
             
              </div>

              <div className="col">
                <Select
                  value={selectedCityOption}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.cityName}`}
                  options={citiesList}
                />
              
              </div>

              <div className="col">
                <input
                  className="filter-input"
                  type="text"
                  placeholder="Property Title"
                  value={propertyTitle}
                  onChange={(e) => setPropertyTitle(e.target.value)}
                />
              </div>
            </div>

            

            <div className="row mt-4">
              <div className="col">
                <input
                  className="filter-input"
                  type="text"
                  placeholder="Property Area Range"
                  value={propertyAreaRange}
                  onChange={(e) => setPropertyAreaRange(e.target.value)}
                />
              </div>

              <div className="col">
                <input
                  className="filter-input"
                  type="text"
                  placeholder="No. of Bedrooms Range"
                  value={noOfBedroomsRange}
                  onChange={(e) => setNoOfBedroomsRange(e.target.value)}
                />
              </div>

              <div className="col">
                <input
                  className="filter-input"
                  type="text"
                  placeholder="No. of Stories Range"
                  value={noOfStoryRange}
                  onChange={(e) => setNoOfStoryRange(e.target.value)}
                />
              </div>

              <div className="col">
                <input
                  className="filter-input"
                  type="text"
                  placeholder="No. of Baths Range"
                  value={noOfBathsRange}
                  onChange={(e) => setNoOfBathsRange(e.target.value)}
                />
              </div>
            </div>



            <div className="row mt-4 pb-3">

            <div className="col-3">


            <select

className="status-input"
name="home_type"
aria-label="Default select example"
value={propStatusFilter}
onChange={(e) => setPropStatusFilter(e.target.value)}
>
<option value="">Select Status</option>
{propStatus.map((status) => (
<option value={status.indexDescription} key={status.indexCode}>
{status.indexDescription}
</option>
))}
</select>
</div>



<div className="col">




                <input
                  className="filter-input"
                  type="text"
                  placeholder="Home Type"
                  value={homeType}
                  onChange={(e) => setHomeType(e.target.value)}
                />


              </div>



              
            </div>
          </div>

          <div className="col-2">
            <div className="row">
              <button
                className="btn h56 btn-primary label-background-color px-4"
                onClick={handleSearch}
              >
                Find Properties
              </button>
            </div>

            <div className="row">
              <button
                onClick={getPropertiesList}
                className="btn inter label-color mt-4"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div> */}

{error && <h3 className="text-center mb-5">{error}</h3>}

{loading === 0 ? (
  <div className="d-flex bgwhite col-lg col-12 py-5 px-3 rounded justify-content-center align-items-center">
    {" "}
    <Spinner animation="border" variant="secondary" />
  </div>
)  : properties.length === 0 ? (
  <div className="d-flex bgwhite col-lg col-12 py-5 px-3 rounded justify-content-center align-items-center">
    <p>No records found</p>
  </div>
) : (
  <div className=" customContainer mt150 " data-aos="fade-up">
    <div className="row" data-aos="fade-up">
      {currentCards.map((property, index) => (
        <div className="col-md-6 col-lg-4 mb-5" key={index}>
          <div
            className="card mb-4 px-3 py-3 rounded-3"
            style={{
              borderRadius: "0.5px",
              border: "0.5px solid #dddddd",
            }}
          >
            <p className="playfair playfair heading-color font32 mb-3 ">
                  {property.property_title}
                </p>

                <div className="row">
                  <p className="col-md font16 inter Lgrey">
                    {property.city_name}

                  </p>
                  <p className="col-md font16 inter Lgrey">
                    {property.state_name} 
                  </p>
                  <p className="col-md font16 inter Lgrey">
                    {property.country_name}
                  </p>

                </div>

            <div className="Model-image imagek">
            <div className="Model-image imagek">
  {property.property_title === 'Suwanee Preserves' ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "350px",
      }}
    >
      <img
        src={SuwaneePreserves[0].imgPath}
        alt="propImg"
        className="imagek rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        className="rounded-5"
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "#F0F7FF",
          padding: "5px",
          border: "1px solid #7CC0FD",
          borderRadius: "5px",
        }}
      >
        <p
          className="px-3 py-1 inter"
          style={{
            margin: "0",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {property.status}
        </p>
      </div>
    </div>
  ) : property.property_title === '1#Dahlonega-GA-30533-USA' ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "350px",
      }}
    >
      <img
        src={Prop448[0].imgPath}
        alt="propImg"
        className="imagek rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        className="rounded-5"
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "#F0F7FF",
          padding: "5px",
          border: "1px solid #7CC0FD",
          borderRadius: "5px",
        }}
      >
        <p
          className="px-3 py-1 inter"
          style={{
            margin: "0",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {property.status}
        </p>
      </div>
    </div>
  ) : property.property_title === '2#Dahlonega-GA-30533-USA' ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "350px",
      }}
    >
      <img
        src={Prop39[3].imgPath}
         alt="propImg"
        className="imagek rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        className="rounded-5"
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "#F0F7FF",
          padding: "5px",
          border: "1px solid #7CC0FD",
          borderRadius: "5px",
        }}
      >
        <p
          className="px-3 py-1 inter"
          style={{
            margin: "0",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {property.status}
        </p>
      </div>
    </div>
  ): property.property_title === '3#Dahlonega-GA-30533-USA' ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "350px",
      }}
    >
      <img
        src={Prop445[4].imgPath}
         alt="propImg"
        className="imagek rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        className="rounded-5"
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "#F0F7FF",
          padding: "5px",
          border: "1px solid #7CC0FD",
          borderRadius: "5px",
        }}
      >
        <p
          className="px-3 py-1 inter"
          style={{
            margin: "0",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {property.status}
        </p>
      </div>
    </div>
  )  :property.image_url ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "350px",
      }}
    >
      <img
        src={property.image_url}
         alt="propImg"
        className="imagek rounded-3"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        className="rounded-5"
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          backgroundColor: "#F0F7FF",
          padding: "5px",
          border: "1px solid #7CC0FD",
          borderRadius: "5px",
        }}
      >
        <p
          className="px-3 py-1 inter"
          style={{
            margin: "0",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {property.status}
        </p>
      </div>
    </div>
  ) : null}
</div>

            </div>
            <div className="mt-2">
              <div className="Model-details">
                {/* <p className="playfair playfair heading-color font32 mb-0 ">
                  {property.property_title}
                </p> */}
                <p
                  className="inter font20 Lgrey"
                  style={{ fontWeight: 700 }}
                >
                  {property.prices_range}
                </p>

                <p className="font16 inter Lgrey">
                  {expandedDescriptionIndex === index
                    ? property.community_description
                    : property.community_description.substring(0, 100)}
                  {property.community_description.length > 100 && (
                    <>
                      {" "}
                      <a
        href="#"
        className="text-primary"
        style={{ cursor: 'pointer', fontWeight: 600, textDecoration: 'none', }}
        onClick={(e) => {
          e.preventDefault();
          handleToggleDescription(index);
        }}
      >
        {expandedDescriptionIndex === index ? ' Show Less' : '... More'}
      </a>
                    </>
                  )}
                </p>
                <div className="row d-flex justify-content-between mt-3">
                  <div className="d-flex col-lg-7 col-12">
                    <div className="col-3 d-flex align-items-center">
                      <img
                        src={require("../images/bed-alt.png")}
                        alt="Bedroom Icon"
                        className="me-1"
                      />
                      <h1
                        className="mt-2 ms-1 inter font20 Lgrey"
                        style={{ fontWeight: 600 }}
                      >
                        {property.no_of_bedrooms_range}
                      </h1>
                    </div>
                    <div className="col-3 d-flex align-items-center">
                      <img
                        src={require("../images/bath.png")}
                        alt="Bathroom Icon"
                        className="me-1"
                      />
                      <h1
                        className="mt-2 ms-1 inter font20 Lgrey"
                        style={{ fontWeight: 600 }}
                      >
                        {property.number_of_baths_range}
                      </h1>
                    </div>
                    <div className="col-3 d-flex align-items-center">
                      <img
                        src={require("../images/building.png")}
                        alt="Half Bath Icon"
                        className="me-2"
                      />
                      <h1
                        className="mt-2 ms-1 inter font20 Lgrey"
                        style={{ fontWeight: 600 }}
                      >
                        {property.number_of_story_range}
                      </h1>
                    </div>
                    <div className="col-3 d-flex align-items-center">
                      <img
                        src={require("../images/garage-car.png")}
                        alt="Garage Icon"
                        className="me-1"
                      />
                      <h1
                        className="mt-2 ms-1 inter font20 Lgrey"
                        style={{ fontWeight: 600 }}
                      >
                        {property.number_of_garages_range}
                      </h1>
                    </div>
                  </div>

                  <div className="col-lg-5 col-12 d-flex justify-content-lg-end">
                    <div className=" d-flex align-items-center">
                      <img
                        src={require("../images/area.png")}
                        alt="Garage Icon"
                        className="me-1"
                      />
                      <h1
                        className="mt-2 ms-1 inter font20 Lgrey"
                        style={{ fontWeight: 600 }}
                      >
                        {property.property_area_range}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1 col-md-1 p-1 p-md-0 ps-lg-3 mx-4"></div>
            <hr className="mt-3 mb-3" /> {/* Line */}
            <div className="d-flex justify-content-between">
              <button
                onClick={() => contactUs(property)}
                className="btn inter"
                style={{ color: "#0064C2", fontWeight: 500 }}
              >
                Contact Us
              </button>

              <button
                className="btn h56 btn-primary label-background-color px-4"
                onClick={() => handlePropertyDesc(property)}
              >
                View Property
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>

          {properties.length > cardsPerPage && (
            <div className="pagination-container">
              <ReactPaginate
                pageCount={Math.ceil(properties.length / cardsPerPage)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
                previousLabel={
                  <img
                    src={require('../images/prev.png')}
                    alt="Prev Icon"
                    className="mb-2"
                  />
                }
                nextLabel={
                  <img
                    src={require('../images/next.png')}
                    alt="Next Icon"
                    className=""
                  />
                }
              />
            </div>
          )}
        </div>
      )}

      {/* <Footer /> */}

      <ScrollToTopButton />
    </>
  );
};

export default Projects;
