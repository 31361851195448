import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Navb from '../Components/Navbar';
import Footer from '../Components/Footer';
import httpproperties from '../js/http-realestate-properties';
// import { Helmet } from 'react-helmet';
import { ROUTES } from '../Constant/Routes';
import { Form } from 'react-bootstrap';

const PropertyList = () => {
  const [properties, setProperties] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [selectedPropertyForActive, setSelectedPropertyForActive] =
    useState(null);
  const [propertyStatusList, setPropertyStatusList] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState(null);
  const [selectedInactiveStatusId, setSelectedInactiveStatusId] = useState(68);
  const [selectedPropertyDetails, setSelectedPropertyDetails] = useState();
  const [pop, setPop] = useState(false);
  const [error, setError] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProperties, setFilteredProperties] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getPropertiesData();
  }, []);

  useEffect(() => {
    filterProperties();
  }, [searchQuery, properties]);

  function navToPrevRoute() {
    navigate(ROUTES.MY_PROPERTIES);
  }

  function getPropertiesData() {
    httpproperties
      .get(
        'propertyManagement/getMyPropertiesList?profile_id=' +
          sessionStorage.getItem('profileId')
      )
      .then((response) => {
        if (response.data === 'No records found') {
          setError(response.data);
        } else {
          setProperties(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const handleUpdate = (property) => {
    setSelectedProperty(property);
    navigate(ROUTES.UPDATE_PROPERTY, { state: { selectedProp: property } });
  };

  const openDeleteModal = (property) => {
    setSelectedProperty(property);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedProperty(null);
    setShowDeleteModal(false);
  };

  function refresh() {
    httpproperties
      .put('/propertyManagement/updateImageLinks')
      .then((res) => {
        console.log('Refresh status:', res);
        setPop(true);

        setTimeout(() => {
          setPop(false);
        }, 2800);
      })
      .catch((error) => {
        console.error('Error refreshing images links:', error);
      });
  }

  const deleteProperty = () => {
    if (!selectedProperty) return;
    const propertyId = selectedProperty.property_id;
    httpproperties
      .delete(`propertyManagement/deletePropertyById?propertyId=${propertyId}`)
      .then((response) => {
        console.log('Property deleted:', response);
        // Refresh properties after deletion
        getPropertiesData();
        closeDeleteModal();
      })
      .catch((error) => {
        console.error('Error deleting property:', error);
        // Handle error here
        closeDeleteModal();
      });
  };

  const openActiveModal = (property) => {
    setSelectedPropertyForActive(property);
    setShowActiveModal(true);
  };

  const closeActiveModal = () => {
    setSelectedPropertyForActive(null);
    setShowActiveModal(false);
  };

  const updatePropertyStatus = () => {
    if (!selectedPropertyForActive || !selectedStatusId) return;
    const propertyId = selectedPropertyForActive.property_id;
    // Replace with your API endpoint to update property status
    httpproperties
      .put(
        `propertyManagement/updatePropertyStatus?propertyId=${propertyId}&status=${selectedStatusId}`
      )
      .then((response) => {
        console.log('Property status updated:', response);
        // Perform any necessary actions after updating property status
        closeActiveModal();
      })
      .catch((error) => {
        console.error('Error updating property status:', error);
        // Handle error here
        closeActiveModal();
      });
  };

  const changePropertyStatus = (e) => {
    setSelectedStatusId(e.target.value);
  };

  const handleSortingOptionChange = (event) => {
    // Implement sorting option change logic here if needed
  };

  const filterProperties = () => {
    if (!searchQuery.trim()) {
      setFilteredProperties(properties);
    } else {
      const filtered = properties.filter((property) =>
        property.property_title
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
      setFilteredProperties(filtered);
    }
  };

  return (
    <>
      {/* <Helmet>
        <title>My Properties</title>
        <meta
          name="description"
          content="Easiest way to find your dream home"
        />
        <meta name="keywords" content="keywords, separated, by, commas" />
        <link rel="canonical" href="https://www.example.com/page" />
      </Helmet> */}

      {pop && (
        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
          <div className=" d-flex flex-column justify-content-center align-items-center P-Update-Success-2 ">
            <div>
              <img
                src={require('../images/Tick.png')}
                alt="success"
                className="img-fluid"
              />
            </div>
            <p className="text-center w213 ">
              Successfully images links has been updated
            </p>
          </div>
        </div>
      )}

      {/* <Navb
        isDefault={true}
        class="d-flex justify-content-between zi100 py-2 w-100 greybg px-5 position-fixed"
      /> */}
      <Container>
        <div className="my-property d-flex justify-content-between mb-4 pt-3">
          <div className="d-flex mt-5 pt-3">
            <div
              className=" d-flex align-items-center pointer"
              onClick={navToPrevRoute}
            >
              <img
                className="pe-3 backarrow"
                src={require('../images/leftarrow.png')}
                alt="back"
              />
              <h4 className="SectionHeadings mt-2">Properties List</h4>
            </div>
          </div>

          <div className="mt-5 pt-5 d-flex flex-column">
            <Button onClick={() => navigate(ROUTES.ADD_PROPERTY)}>
              Add Property
            </Button>
            {/* <Button className="my-4" onClick={() => navigate(ROUTES.ADD_EVENT)}>
              Add Event
            </Button> */}
            <Button className='mt-4' onClick={refresh}>Refresh Images Links</Button>
          </div>
        </div>

        {error && <h3 className="text-center mb-5">{error}</h3>}

        <div className="container">
          <Form.Control
            type="text"
            placeholder="Search Property"
            className=" w-25 mb-3 "
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          <div className="row">
            {filteredProperties.map((property) => {
              const validImages = property.imageSrcLink.filter(
                (image) =>
                  image.imgId !== 9 && image.imgId !== 12 && image.imgId !== 17
              );
              const imageUrl =
                validImages.length > 0 ? validImages[0].imageUrl : '';

              return (
                <div className="col-lg-4" key={property.property_id}>
                  <div className="card mb-4 shadow">
                    <div className="Model-image imagek">
                      {imageUrl && (
                        <img
                          src={imageUrl}
                          className="w-100"
                          alt="API Image"
                          style={{ height: '300px' }}
                        />
                      )}
                    </div>
                    <div className="d-flex m-4">
                      <div className="Model-details ms-3">
                        <p>{property.property_title}</p>
                        <p>{property.community_description}</p>
                        <p>Price: {property.prices_range}</p>
                        <p>Status: {property.status}</p>
                      </div>
                      <div className="col-1 col-md-1 p-1 p-md-0 ps-lg-3">
                        <div className="Model-actions">
                          <Dropdown
                            drop={'down-centered'}
                            align={{ lg: 'start' }}
                          >
                            <Dropdown.Toggle
                              className="dropdown-more p-0"
                              variant="success"
                              title="more actions"
                              id="dropdown-basic"
                            >
                              <img
                                src={require('../images/more.png')}
                                alt="Image not found"
                                className="more"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  handleUpdate(property);
                                  setShowActiveModal(true);
                                }}
                              >
                                Update Property
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => openDeleteModal(property)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Property</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the property{' '}
            {selectedProperty?.name}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={deleteProperty}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showActiveModal} onHide={closeActiveModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedPropertyForActive?.status === 'active'
                ? 'Make Inactive'
                : 'Make Active'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 d-flex">
              <div className="col-4">
                <div className="col-4">Property Name</div>
                <div className="col-8">
                  :{' '}
                  {selectedPropertyForActive
                    ? selectedPropertyForActive.property_title
                    : ''}
                </div>
              </div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-4 my-auto">Property Status</div>
              <div className="col-8 d-flex">
                {' '}
                :{' '}
                <Form.Select
                  onChange={changePropertyStatus}
                  className="form-select col-6"
                  id="roleselector"
                  aria-label="Default select example"
                  defaultValue={
                    selectedPropertyForActive
                      ? selectedPropertyForActive.status
                      : ''
                  }
                >
                  <option value="">Select Status</option>
                  {propertyStatusList.map((data, index) => (
                    <option value={data.indexCode} key={data.indexCode}>
                      {data.indexDescription}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeActiveModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={updatePropertyStatus}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default PropertyList;
