import SuwaneePreservesSitePlan from "./PropertySitePlan/SuwaneePreserves.pdf"
import Dahlenoga1SitePlan from "./PropertySitePlan/Dahlonega_1.pdf"
import Dahlenoga2SitePlan from "./PropertySitePlan/Dahlonega_2.pdf"
import Dahlenoga3SitePlan from "./PropertySitePlan/Dahlonega_3.jpg"




const PropertySitePlan = [

 
  {
    "SNO": 1,
    "sitePath": SuwaneePreservesSitePlan
},

{
  "SNO": 2,
  "sitePath": Dahlenoga1SitePlan
},

{
  "SNO": 3,
  "sitePath": Dahlenoga2SitePlan
},

{
  "SNO": 4,
  "sitePath": Dahlenoga3SitePlan
},



];

export default PropertySitePlan;