import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Navb from '../Components/Navbar';
import httpproperties from '../js/http-realestate-properties';
import httpuserprofile from '../js/http-lms_userprofile';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../Constant/Routes';
// import { Helmet } from 'react-helmet';
import Select from 'react-select';

const UpdateProp = () => {
  const location = useLocation();

  const [property, setProperty] = useState({});
  const [formErros, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);
  const [pop, setPop] = useState(false);
  const [home, setHome] = useState([]);
  const [propStatus, setPropStatus] = useState([]);
  const [imgList, setImgList] = useState([]);
  const [propertyId, setPropertyId] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [comType, setComType] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [deleteListId, setDeleteListId] = useState([]);
  const [brochureErrorMessage, setBrochureErrorMessage] = useState();
  const [selectedBrochurePdf, setSelectedBrochurePdf] = useState(null);
  const [imgErrorMessage, setImgErrorMessage] = useState();
  const [floorErrorMessage, setFloorErrorMessage] = useState();
  const [selectedFloorPdf, setSelectedFloorPdf] = useState(null);
  const [vtourErrorMessage, setVtourErrorMessage] = useState();
  const [Vtour, setVtour] = useState();

  const handleBrochureChange = (event) => {
    const file = event.target.files[0];
    if (selectedBrochurePdf?.image_url) {
      let data = deleteListId;
      data.push(selectedBrochurePdf.id);
      setDeleteListId(data);
    }
    setSelectedBrochurePdf(file);
    setBrochureErrorMessage('');
  };

  const handleFloorChange = (event) => {
    const floorfile = event.target.files[0];
    console.log();
    if (selectedFloorPdf?.image_url) {
      let data = deleteListId;
      data.push(selectedFloorPdf.id);
      setDeleteListId(data);
    }
    setSelectedFloorPdf(floorfile);
    setFloorErrorMessage('');
  };

  const handleVtourChange = (event) => {
    setVtour(event.target.value);
    console.log(Vtour, 'TOURRRRR');
    setVtourErrorMessage('');
  };

  const navigate = useNavigate();

  useEffect(() => {
    // console.log(location.state.selectedProp.property_id, "oijoijoijoijoij");

    // setPropertyId(location.state.selectedProp);

    getSelectedProp(location.state.selectedProp.property_id);

    getHomes();

    communityType();

    propertyStatus();

    getImgList();

    getUserData();

    getDocuments();

    //fetching countries list
    httpuserprofile.get('/userprofileLMS/getUserCountries').then((res) => {
      console.log('user countries', res);
      setCountriesList(res.data);
    });
  }, []);

  function navToPrevRoute() {
    navigate(ROUTES.MY_PROPERTIES);
  }

  function getUserData() {
    //fetching user data
    httpuserprofile
      .get(
        `/userprofileLMS/getLoggedInUserDetails?userId=${sessionStorage.getItem(
          'userId'
        )}&roleId=${sessionStorage.getItem('roleId')}&authCode=KRDREBM390`
      )
      .then((response) => {
        console.log(response);
        sessionStorage.setItem('profileId', response.data.profileId);
        console.log('UserDetails in update user:', response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let fieldValue = type === 'checkbox' ? checked : value;

    // If the field is contact_number, remove non-numeric characters and limit length to 10
    if (name === 'contact_number') {
      // Remove any non-numeric characters
      fieldValue = fieldValue.replace(/\D/g, '');

      // Limit length to 10 characters
      fieldValue = fieldValue.slice(0, 10);

      const isValidContactNumber = fieldValue.length === 10;
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        salesTeam_contactno: isValidContactNumber
          ? ''
          : 'Mobile number must be of 10 digits!',
      }));
    }

    setProperty((prevProperty) => ({
      ...prevProperty,
      [name]: fieldValue,
    }));
  };

  const getDocuments = () => {
    ['Site Plan', 'Specification'].forEach(async (docType, index) => {
      await httpproperties
        .get(
          `/propertyManagement/getUrlByImgType?imgType=${docType}&propertyId=${location.state.selectedProp.property_id}`
        )
        .then((res) => {
          console.log(res, 'ddddddddddddddddd');
          if (index === 0) {
            setSelectedFloorPdf(res.data);
          }
          if (index === 1) {
            console.log(res.data, 'zzzzzzzzzzzzz');
            setSelectedBrochurePdf(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const propertyStatus = () => {
    httpuserprofile
      .get('/userprofileLMS/getStaticValuesListById?indexCode=9')
      .then((result) => {
        console.log(result.data, 'propStatus');
        setPropStatus(result.data);
      })
      .catch((err) => {
        console.log(err, 'ERRRR');
      });
  };

  const getImgList = () => {
    httpproperties
      .get('/propertyManagement/getImageTypeList')
      .then((result) => {
        const filteredData = result.data.filter(
          (item) => ![9, 10, 12, 17].includes(item.id)
        );
        console.log(filteredData, 'Filtered ImgList');
        setImgList(filteredData);
      })
      .catch((err) => {
        console.log(err, 'ERRRR');
      });
  };

  const getHomes = () => {
    httpuserprofile
      .get('/userprofileLMS/getStaticValuesListById?indexCode=10')
      .then((result) => {
        console.log(result.data, 'Homes');
        setHome(result.data);
      })
      .catch((err) => {
        console.log(err, 'ERRRR');
      });
  };

  const communityType = () => {
    httpuserprofile
      .get('/userprofileLMS/getStaticValuesListById?indexCode=12')
      .then((result) => {
        console.log(result.data, 'ComType');
        setComType(result.data);
      })
      .catch((err) => {
        console.log(err, 'ERRRR');
      });
  };

  const saveImg = async (pId) => {
    try {
      for (const x of imageList) {
        const formData = new FormData();
        formData.append('propertyOrModelImages', x.image);

        const config = {
          method: 'post',
          url: `/propertyManagement/uploadPropertyImages?userProfileId=${sessionStorage.getItem(
            'profileId'
          )}&propertyId=${pId}&imageTypeId=${
            x.id
          }&imageType=property&modelId=&creationUserI=${sessionStorage.getItem(
            'userEmail'
          )}`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        };

        try {
          const response = await httpproperties(config);

          // Handle the response data if needed
          console.log(response.data);
        } catch (error) {
          // Handle errors if needed
          console.error(error);
        }
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };

  const saveBrochurePdf = async (pId) => {
    try {
      const formData = new FormData();
      formData.append('propertyOrModelImages', selectedBrochurePdf);

      const config = {
        method: 'post',
        url: `/propertyManagement/uploadPropertyImages?userProfileId=${sessionStorage.getItem(
          'profileId'
        )}&propertyId=${pId}&imageTypeId=
            17
          &imageType=property&modelId=&creationUserI=${sessionStorage.getItem(
            'userEmail'
          )}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };

      try {
        const response = await httpproperties(config); // Using httpproperties instead of axios

        // Handle the response data if needed
        console.log(response.data);
      } catch (error) {
        // Handle errors if needed
        console.error(error);
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };

  const saveFloorPdf = async (pId) => {
    try {
      const formData = new FormData();
      formData.append('propertyOrModelImages', selectedFloorPdf);

      const config = {
        method: 'post',
        url: `/propertyManagement/uploadPropertyImages?userProfileId=${sessionStorage.getItem(
          'profileId'
        )}&propertyId=${pId}&imageTypeId=
            12
          &imageType=property&modelId=&creationUserI=${sessionStorage.getItem(
            'userEmail'
          )}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };

      try {
        const response = await httpproperties(config); // Using httpproperties instead of axios

        // Handle the response data if needed
        console.log(response.data);
      } catch (error) {
        // Handle errors if needed
        console.error(error);
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };

  const saveVtour = async (pId) => {
    try {
      const formData = new FormData();
      formData.append('srcVideoLink', Vtour); // Vtour is explicitly treated as text

      const config = {
        method: 'post',
        url: `/propertyManagement/uploadPropertyImages?userProfileId=${sessionStorage.getItem(
          'profileId'
        )}&propertyId=${pId}&imageTypeId=9&imageType=property&modelId=&creationUserI=${sessionStorage.getItem(
          'userEmail'
        )}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };

      try {
        const response = await httpproperties(config); // Using httpproperties instead of axios

        // Handle the response data if needed
        console.log(response.data);
      } catch (error) {
        // Handle errors if needed
        console.error(error);
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      Object.keys(validate(property)).length !== 0 ||
      imageList.length === 0 ||
      !selectedBrochurePdf ||
      !selectedFloorPdf ||
      !Vtour
    ) {
      const propertyErrors = validate(property);
      const hasPropertyErrors = Object.keys(propertyErrors).length !== 0;
      const hasNoImages = imageList.length === 0;
      const hasNoBrochure = !selectedBrochurePdf;
      const hasNoFloorPlan = !selectedFloorPdf;
      const hasNoVtour = !Vtour;

      if (hasPropertyErrors) {
        setFormErrors(propertyErrors);
      } else {
        setFormErrors({});
      }

      if (hasNoImages) {
        setImgErrorMessage('Please add at least one image.');
      } else {
        setImgErrorMessage('');
      }

      if (hasNoBrochure) {
        setBrochureErrorMessage('Please add brochure.');
      } else {
        setBrochureErrorMessage('');
      }

      if (hasNoFloorPlan) {
        setFloorErrorMessage('Please add site plan.');
      } else {
        setFloorErrorMessage('');
      }
      if (hasNoVtour) {
        setVtourErrorMessage('Please add virtual tour.');
      } else {
        setVtourErrorMessage('');
      }
    } else {
      deleteListApi();
      deleteVTour();
      property.updated_user_i = sessionStorage.getItem('userEmail');
      httpproperties
        .put('/propertyManagement/updatePropertyDetails', property)
        .then((response) => {
          // Handle success response
          if (response.data) {
            setPop(true);
            setProperty({
              property_title: '',
              community_type: '',
              home_type: '',
              property_description: '',
              community_description: '',
              community_highlights: '',
              prices_range: '',
              property_area_range: '',
              no_of_bedrooms_range: '',
              number_of_baths_range: '',
              number_of_halfbath_range: '',
              number_of_story_range: '',
              number_of_garages_range: '',
              status: '',
              address1: '',
              address2: '',
              countryId: '',
              stateId: '',
              cityId: '',
              zipcode: '',
              latitude: '',
              longitude: '',
              county_metro: '',
              features: '',
              profile_id: sessionStorage.getItem('profileId'),
              first_name: '',
              last_name: '',
              middle_name: '',
              email_id: '',
              contact_number: '',
              working_hours_from: '',
              working_hours_to: '',
              creation_user_i: '',
              updated_user_i: sessionStorage.getItem('userEmail'),
            });
            setTimeout(() => {
              setPop(false);
            }, 1500);

            const brochureInput = document.getElementById('brochureFile');
            const floorInput = document.getElementById('floorFile');
            brochureInput.value = null;
            floorInput.value = null;

            setSelectedCountryOption(null);
            setSelectedStateOption(null);
            setSelectedCityOption(null);
            saveImg(propertyId);
            setFormErrors('');
            setImageList([]);
            saveBrochurePdf(propertyId);
            saveFloorPdf(propertyId);
            saveVtour(propertyId);
            // getSelectedProp(propertyId);
            setImageList([]);
            setSelectedBrochurePdf(null);
            setSelectedFloorPdf(null);
            setVtour('');
            // window.location.reload();
          } else {
            console.log('ERR');
          }
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  // reset selected state and city
  // fetch states list based on selected country
  function handleCountryChange(e) {
    console.log('selected country', e);
    //reset states & cities, once changed selected country
    setSelectedCountryOption(e);
    setSelectedStateOption(null);
    setSelectedCityOption(null);
    setStatesList([]);
    setCitiesList([]);
    property.stateId = null;
    property.cityId = null;
    // formData.countryId = e.id;
    setProperty((pValue) => {
      console.log(pValue);
      return {
        ...pValue,
        ['countryId']: e.countryId,
      };
    });
    // handleChange(e)
    httpuserprofile
      .get('/userprofileLMS/getUserStates?countryId=' + e.countryId)
      .then((res) => {
        console.log('user states', res);
        setStatesList(res.data);
      });
  }
  // triggered after changing the state
  // reset selected city
  // fetch cities list based on selected state
  function handleStateChange(e) {
    console.log('selected state', e);
    //reset cities, once changed selected country
    setSelectedStateOption(e);
    setSelectedCityOption(null);
    setCitiesList([]);
    // formData.stateId = e.id;
    property.cityId = null;
    setProperty((pValue) => {
      console.log(pValue);
      return {
        ...pValue,
        ['stateId']: e.stateId,
      };
    });
    // handleChange(e)
    httpuserprofile
      .get('/userprofileLMS/getUserCities?stateId=' + e.stateId)
      .then((res) => {
        console.log('user cities', res);
        setCitiesList(res.data);
      });
  }
  // triggered after changing the city
  function handleCityChange(e) {
    console.log('selected city : ', e);
    setSelectedCityOption(e);
    // formData.cityId = e.id;
    setProperty((pValue) => {
      console.log(pValue);
      return {
        ...pValue,
        ['cityId']: e.cityId,
      };
    });
  }

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (contactNumber) => {
    const regex = /^\d{10}$/;
    return regex.test(contactNumber);
  };

  const validate = (values) => {
    const errors = {};
    console.log(values.home_type, 'vvvvvvvvv');
    if (!values.property_title) {
      errors.property_title = 'Property Title is required!';
    }
    if (!values.home_type) {
      errors.home_type = 'Home Type is required!';
    }
    if (!values.community_type) {
      errors.community_type = 'Community Type is required!';
    }
    if (!values.prices_range) {
      errors.prices_range = 'Price is required!';
    }
    if (!values.property_area_range) {
      errors.property_area_range = 'Area is required!';
    }
    if (!values.no_of_bedrooms_range) {
      errors.no_of_bedrooms_range = 'No.of bedrooms is required!';
    }
    if (!values.number_of_baths_range) {
      errors.number_of_baths_range = 'No.of Baths is required!';
    }
    if (!values.number_of_halfbath_range) {
      errors.number_of_halfbath_range = 'No.of Halfbaths is required!';
    }
    if (!values.number_of_story_range) {
      errors.number_of_story_range = 'No.of Stories is required!';
    }
    if (!values.number_of_garages_range) {
      errors.number_of_garages_range = 'No.of Garages is required!';
    }
    if (!values.status) {
      errors.status = 'Status is required!';
    }
    if (!values.address1) {
      errors.address1 = 'Address 1 is required!';
    }
    if (!values.countryId) {
      errors.country = 'Country is required!';
    }
    if (!values.stateId) {
      errors.state = 'State is required!';
    }
    if (!values.cityId) {
      errors.city = 'City is required!';
    }
    if (!values.zipcode) {
      errors.zipcode = 'Zip Code is required!';
    }
    if (!values.latitude) {
      errors.latitude = 'Latitude is required!';
    }
    if (!values.longitude) {
      errors.longitude = 'Longitude is required!';
    }
    if (!values.county_metro) {
      errors.county_metro = 'County/Metro/Corporation Name is required!';
    }

    if (values.addSalesTeamInfo) {
      // If "add sales team info" checkbox is checked
      if (!property.first_name) {
        errors.salesTeam_firstname = 'First Name is required!';
      }
      if (!property.last_name) {
        errors.salesTeam_lastname = 'Last Name is required!';
      }
      if (!property.email_id) {
        errors.salesTeam_emailid = 'Email Address is required!';
      } else if (!isValidEmail(property.email_id)) {
        errors.salesTeam_emailid = 'Invalid Email Address!';
      }
      if (!property.contact_number) {
        errors.salesTeam_contactno = 'Contact Number is required!';
      } else if (!validatePhoneNumber(property.contact_number)) {
        errors.salesTeam_contactno = '*Invalid Contact Number!';
      }
    }
    console.log('before return errors : ', errors);
    console.log('before return errors : ', property);
    return errors;
  };

  useEffect(() => {
    console.log(property, 'pppppppppppp');
  }, [property]);

  const getSelectedProp = async (propId) => {
    await httpproperties
      .get(
        `/propertyManagement/getPropertyListByIdOrTitle?propertyId=${propId}`
      )
      .then((result) => {
        console.log(result.data, 'Updating Prop. Details:');
        setProperty({
          ...result.data,
          first_name: result?.data?.salesPersonDetails?.[0]?.first_name ?? '',
          last_name: result?.data?.salesPersonDetails?.[0]?.last_name ?? '',
          middle_name: result?.data?.salesPersonDetails?.[0]?.middle_name ?? '',
          email_id: result?.data?.salesPersonDetails?.[0]?.email_id ?? '',
          contact_number:
            result?.data?.salesPersonDetails?.[0]?.contact_number ?? '',
        });
        console.log(result.data.salesPersonDetails[0], 'ssssssssssssssss');
        setPropertyId(result.data.property_id);
        setImageList(
          result.data.imageSrcLink
            .filter(
              (image) =>
                image.imgId != 9 && image.imgId != 12 && image.imgId != 17
            )
            .map((imageSrc, index) => ({
              // type: imageSrc?.imageTypeDetails?.imgType || null,
              type: imageSrc.imageTypeDetails.imgType,
              image: imageSrc.imageUrl,
              id: imageSrc.id,
            }))
        );
        setSelectedCityOption(result.data.city);
        setSelectedStateOption(result.data.state);
        setSelectedCountryOption(result.data.country);
        setVtour(
          result.data?.imageSrcLink?.find((image) => image.imgId === 9)
            ?.imageUrl ||
            result.data?.imageSrcLink?.find((image) => image.imgId === 9)
              ?.imageUrl ||
            ''
        );

        httpuserprofile
          .get(
            '/userprofileLMS/getUserStates?countryId=' +
              result.data.country.countryId
          )
          .then((res) => {
            console.log('user states', res);
            setStatesList(res.data);
          });

        httpuserprofile
          .get(
            '/userprofileLMS/getUserCities?stateId=' + result.data.state.stateId
          )
          .then((res) => {
            console.log('user cities', res);
            setCitiesList(res.data);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      // border: `1px solid #ced4da`,
      // boxShadow: `0 0 0 1px`,
      // ':hover' : {
      //   // boxShadow: `0 0 0 1px `,
      //   backgroundColor : "blue"
      // }
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor:
        state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: '14px',
      paddingTop: '4px',
      paddingBottom: '4px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };

  const handleAddToList = () => {
    let type = imgList.filter((x) => x.id == selectedType);

    console.log(imgList[0].id, selectedType, type, 'tttttttttt');
    if (selectedType && selectedImage) {
      const newImage = {
        type: type?.[0]?.imgType ?? '',
        image: selectedImage,
        id: type?.[0]?.id ?? '',
      };
      setImageList([...imageList, newImage]);
      setSelectedType('');
      setSelectedImage(null);
      setImgErrorMessage('');
      const fileInput = document.getElementById('imageFile');
      fileInput.value = null;
    }
  };

  function deleteListApi() {
    if (deleteListId.length > 0) {
      deleteListId.forEach((id) => {
        httpproperties.delete(
          `propertyManagement/deletePropertyImages?id=${id}`
        );
      });
    }
  }

  function deleteVTour() {
    property?.imageSrcLink
      ?.filter((img) => img.imgId === 9)
      .forEach((img) => {
        httpproperties.delete(
          `propertyManagement/deletePropertyImages?id=${img.id}`
        );
      });
  }

  const handleRemoveFromList = (indexToRemove, image) => {
    console.log({ image });
    setImageList((prevImageList) => {
      const newList = [...prevImageList];
      newList.splice(indexToRemove, 1);
      return newList;
    });
    setDeleteListId((ids) => {
      ids.push(image.id);
      return ids;
    });
    setSelectedType('');
    const fileInput = document.getElementById('imageFile');
    fileInput.value = null;
  };

  const renderSalesTeamInfoFields = () => {
    if (property.addSalesTeamInfo) {
      return (
        <div>
          <Form.Group controlId="firstName">
            <Form.Label className="required">First Name</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              value={property.first_name}
              onChange={handleChange}
            />
            <p className="red font12">{formErros.salesTeam_firstname}</p>
          </Form.Group>
          <Form.Group controlId="middleName">
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              type="text"
              name="middle_name"
              value={property.middle_name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="last_name">
            <Form.Label className="required">Last Name</Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              value={property.last_name}
              onChange={handleChange}
            />
            <p className="red font12">{formErros.salesTeam_lastname}</p>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label className="required">Email Id</Form.Label>
            <Form.Control
              type="email"
              name="email_id"
              value={property.email_id}
              onChange={handleChange}
            />
            <p className="red font12">{formErros.salesTeam_emailid}</p>
          </Form.Group>
          <Form.Group controlId="contactNo">
            <Form.Label className="required">Contact No.</Form.Label>
            <Form.Control
              type="text"
              name="contact_number"
              maxLength={10}
              value={property.contact_number}
              onChange={handleChange}
            />
            <p className="red font12">{formErros.salesTeam_contactno}</p>
          </Form.Group>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {/* <Helmet>
        <title>Update Property</title>
        <meta
          name="description"
          content="Easiest way to find your dream home"
        />
        <meta name="keywords" content="keywords, separated, by, commas" />
        <link rel="canonical" href="https://www.example.com/page" />
      </Helmet> */}

      {pop && (
        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
          <div className=" d-flex flex-column justify-content-center align-items-center P-Update-Success-2 ">
            <div>
              <img
                src={require('../images/Tick.png')}
                alt="success"
                className="img-fluid"
              />
            </div>
            <p className="text-center w213 ">
              Sucessfully property has been updated
            </p>
          </div>
        </div>
      )}

      {/* <Navb
        isDefault={true}
        class="d-flex justify-content-between zi100 py-2 w-100 greybg px-5"
      /> */}

      <div className="col-lg-12 d-flex mt-5">
        <div
          className=" d-flex align-items-center ms-5 mt-5 pt-4 pointer"
          onClick={navToPrevRoute}
        >
          <img
            className="pe-3 backarrow"
            src={require('../images/leftarrow.png')}
            alt="back"
          />
          <h4 className="SectionHeadings mt-2">Update Property</h4>
        </div>
      </div>

      <div className="add-property-page">
        <h1>Property Info</h1>
        <Form onSubmit={handleSubmit}>
          <div className="row add-property-form">
            <div className="col-md-4">
              <Form.Group controlId="property_title">
                <Form.Label className="required">Property Title</Form.Label>
                <Form.Control
                  type="text"
                  name="property_title"
                  value={property.property_title}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.property_title}</p>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group controlId="community_type">
                <Form.Label className="required">Community Type</Form.Label>
                <Form.Select
                  value={property.community_type}
                  name="community_type"
                  aria-label="Default select example"
                  onChange={handleChange}
                >
                  <option value="">Select Community Type</option>
                  {comType.map((status, index) => {
                    return (
                      <option
                        value={status.indexDescription}
                        key={status.indexCode}
                      >
                        {status.indexDescription}
                      </option>
                    );
                  })}
                </Form.Select>
                <p className="red font12">{formErros.community_type}</p>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group controlId="home_type">
                <Form.Label className="required">Home Type</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  value={property.home_type}
                  name="home_type"
                  aria-label="Default select example"
                >
                  <option value="">Select Home Type</option>
                  {home.map((status, index) => {
                    return (
                      <option
                        value={status.indexDescription}
                        key={status.indexCode}
                      >
                        {status.indexDescription}
                      </option>
                    );
                  })}
                </Form.Select>
                <p className="red font12">{formErros.home_type}</p>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group controlId="prices_range">
                <Form.Label className="required">Price Range</Form.Label>
                <Form.Control
                  type="text"
                  name="prices_range"
                  value={property.prices_range}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.prices_range}</p>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group controlId="property_area_range">
                <Form.Label className="required">
                  Property Area Range
                </Form.Label>
                <Form.Control
                  type="text"
                  name="property_area_range"
                  value={property.property_area_range}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.property_area_range}</p>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group controlId="no_of_bedrooms_range">
                <Form.Label className="required">
                  No. of Bedrooms Range
                </Form.Label>
                <Form.Control
                  type="text"
                  name="no_of_bedrooms_range"
                  value={property.no_of_bedrooms_range}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.no_of_bedrooms_range}</p>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group controlId="number_of_baths_range">
                <Form.Label className="required">No. of Baths Range</Form.Label>
                <Form.Control
                  type="text"
                  name="number_of_baths_range"
                  value={property.number_of_baths_range}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.number_of_baths_range}</p>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group controlId="number_of_halfbath_range">
                <Form.Label className="required">
                  No. of Half Baths Range
                </Form.Label>
                <Form.Control
                  type="text"
                  name="number_of_halfbath_range"
                  value={property.number_of_halfbath_range}
                  onChange={handleChange}
                />
                <p className="red font12">
                  {formErros.number_of_halfbath_range}
                </p>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group controlId="number_of_story_range">
                <Form.Label className="required">
                  No. of Stories Range
                </Form.Label>
                <Form.Control
                  type="text"
                  name="number_of_story_range"
                  value={property.number_of_story_range}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.number_of_story_range}</p>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group controlId="number_of_garages_range">
                <Form.Label className="required">No. of Garages</Form.Label>
                <Form.Control
                  type="text"
                  name="number_of_garages_range"
                  value={property.number_of_garages_range}
                  onChange={handleChange}
                />
                <p className="red font12">
                  {formErros.number_of_garages_range}
                </p>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group controlId="status">
                <Form.Label className="required">Status</Form.Label>
                <Form.Select
                  name="status"
                  value={property.status}
                  onChange={handleChange}
                  aria-label="Default select example"
                >
                  <option value="">Select Status</option>
                  {propStatus.map((status, index) => {
                    return (
                      <option
                        value={status.indexDescription}
                        key={status.indexCode}
                      >
                        {status.indexDescription}
                      </option>
                    );
                  })}
                </Form.Select>
                <p className="red font12">{formErros.home_type}</p>
              </Form.Group>

              {/* 
              <Form.Group controlId="status">
  <Form.Label className="required">Status</Form.Label>
  <div className="position-relative">
    <Form.Control
      as="select"
      name="status"
      value={property.status}
      onChange={handleChange}
      className="form-select"
    >
      <option value="">Select Status</option>
      <option value="upcoming">Upcoming</option>
      <option value="inprogress">In Progress</option>
      <option value="completed">Completed</option>
    </Form.Control>
    <div className="dropdown-arrow"></div>
  </div>
  <p className="red font12">{formErros.status}</p>
</Form.Group> */}
            </div>
          </div>
          <Row>
            <Col md={6} lg={4}>
              {/* Add more form groups for other fields */}
            </Col>
            <Col md={6} lg={4}>
              {/* Add more form groups for other fields */}
            </Col>
            <Col md={6} lg={4}>
              {/* Add more form groups for other fields */}
            </Col>
            <Col md={6} lg={4}></Col>
          </Row>

          <h2 className="mt-5">Location</h2>

          <div className="row">
            <div className="col-md-4">
              <Form.Group controlId="address1">
                <Form.Label className="required">Address 1</Form.Label>
                <Form.Control
                  type="text"
                  name="address1"
                  value={property.address1}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.address1}</p>
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group controlId="address2">
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                  type="text"
                  name="address2"
                  value={property.address2}
                  onChange={handleChange}
                />
                <p className="red font12"></p>
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group controlId="city">
                <Form.Label className="required">Country</Form.Label>
                <Select
                  value={selectedCountryOption}
                  onChange={handleCountryChange}
                  placeholder="Select Country"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.countryName}`}
                  options={countriesList}
                />
                <p className="red font12">{formErros.country}</p>
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group controlId="state">
                <Form.Label className="required">State</Form.Label>
                <Select
                  value={selectedStateOption}
                  onChange={handleStateChange}
                  placeholder="Select State"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.stateName}`}
                  options={statesList}
                />
                <p className="red font12">{formErros.state}</p>
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group controlId="city">
                <Form.Label className="required">City</Form.Label>
                <Select
                  value={selectedCityOption}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  styles={colourStyles}
                  getOptionLabel={(option) => `${option.cityName}`}
                  options={citiesList}
                />
                <p className="red font12">{formErros.city}</p>
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group controlId="zipcode">
                <Form.Label className="required">Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  name="zipcode"
                  value={property.zipcode}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.zipcode}</p>
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group controlId="latitude">
                <Form.Label className="required">Latitude</Form.Label>
                <Form.Control
                  type="text"
                  name="latitude"
                  value={property.latitude}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.latitude}</p>
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group controlId="longitude">
                <Form.Label className="required">Longitude</Form.Label>
                <Form.Control
                  type="text"
                  name="longitude"
                  value={property.longitude}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.longitude}</p>
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group controlId="longitude">
                <Form.Label className="required">
                  County/Metro/Corporation Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="county_metro"
                  value={property.county_metro}
                  onChange={handleChange}
                />
                <p className="red font12">{formErros.county_metro}</p>
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Form.Group className="mt-2" controlId="addSalesTeamInfo">
                <Form.Check
                  type="checkbox"
                  label="Do you want to update sales team info?"
                  name="addSalesTeamInfo"
                  checked={property.addSalesTeamInfo}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>

          {/* <Form.Group controlId="county">
              <Form.Label>County</Form.Label>
              <Form.Control
                type="text"
                name="county"
                value={property.county}
                onChange={handleChange}
              />
            </Form.Group> */}

          <h2 className="mt-4">Sales Team Info</h2>
          {renderSalesTeamInfoFields()}

          <Row>
            <h2 className="mt-5">Other Information</h2>
            <Form.Group controlId="community_description">
              <Form.Control
                name="community_description"
                value={property.community_description}
                as="textarea"
                rows={3}
                onChange={handleChange}
                placeholder="Why You Will Love This Community..."
              />
              <p className="red font12">{formErros.community_description}</p>
            </Form.Group>
          </Row>

          <Row className="my-5">
            <Form.Group controlId="features">
              <Form.Control
                name="features"
                value={property.features}
                as="textarea"
                rows={3}
                onChange={handleChange}
                placeholder="Features..."
              />
              {/* <p className="red font12">{formErros.property_description}</p> */}
            </Form.Group>
          </Row>

          <Row className="my-5">
            <Form.Group controlId="property_description">
              <Form.Control
                name="property_description"
                value={property.property_description}
                as="textarea"
                rows={3}
                onChange={handleChange}
                placeholder="Property Description..."
              />
              <p className="red font12">{formErros.property_description}</p>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group controlId="community_highlights">
              <Form.Control
                name="community_highlights"
                value={property.community_highlights}
                as="textarea"
                rows={3}
                onChange={handleChange}
                placeholder="community highlights..."
              />
              <p className="red font12">{formErros.community_highlights}</p>
            </Form.Group>
          </Row>

          <Row className="mt-2">
            <Col>
              <h2 className="mt-2">Upload Property Images</h2>

              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="imageType">
                      <Form.Label>Select Type of Image:</Form.Label>

                      <Form.Select
                        onChange={handleTypeChange}
                        name="homeType"
                        aria-label="Default select example"
                        value={selectedType}
                      >
                        <option value="">Select Image Type</option>
                        {imgList.map((image, index) => (
                          <option value={image.id} key={image.id}>
                            {image.imgType}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="imageFile">
                      <Form.Label>Upload Image:</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="align-self-end">
                    <Button variant="primary" onClick={handleAddToList}>
                      Add to List
                    </Button>
                  </Col>
                </Row>
              </Form>
              <div className={'font12 red ms-2 pb-1 pt-1'}>
                {imgErrorMessage}
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col>
              {imageList.length === 0 ? (
                <p>No images added yet.</p>
              ) : (
                <div className="d-flex flex-wrap">
                  {imageList.map((image, index) => (
                    <div key={index} className="m-3">
                      <p>Type: {image.type}</p>
                      <img
                        src={
                          image.image instanceof File
                            ? URL.createObjectURL(image.image)
                            : image.image
                        }
                        alt={image.type}
                        style={{ width: '200px', height: '200px' }}
                      />
                      <div className="text-center mt-2">
                        <Button
                          variant="danger"
                          className="remove-button"
                          onClick={() => handleRemoveFromList(index, image)}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <h2 className="mt-2">Upload Brochure/Specification</h2>

              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="">
                      <Form.Label className="mb-4"></Form.Label>
                      <Form.Control
                        type="text"
                        name="pdfFile"
                        disabled
                        value="Brochure/Specification"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="brochureFile">
                      <Form.Label className="required form-label">
                        Upload Brochure/Specification:
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/pdf"
                        onChange={handleBrochureChange}
                      />
                    </Form.Group>

                    {selectedBrochurePdf && <div className="ms-2"></div>}
                  </Col>
                </Row>
              </Form>
              <div className={'font12 red ms-2 pb-1 pt-1'}>
                {brochureErrorMessage}
              </div>
            </Col>
          </Row>

          <Row className="">
            <Col>
              {selectedBrochurePdf !== null && (
                <div className="d-flex flex-wrap">
                  <div className="m-3">
                    <h6>
                      {selectedBrochurePdf?.name ??
                        selectedBrochurePdf?.image_url
                          .split('?')[0]
                          .split('/')
                          .pop()}
                    </h6>
                    {/* <div className="text-center mt-2">
                        <Button
                          variant="danger"
                          className="remove-button"
                          onClick={() => {setSelectedBrochurePdf(null);
                            const fileInput = document.getElementById("pdffile");
                          fileInput.value = null;}}
                        >
                          Remove
                        </Button>
                      </div> */}
                  </div>
                </div>
              )}
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <h2 className="mt-2">Upload Site Plan</h2>

              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="pdfFile">
                      <Form.Label className="mb-4"></Form.Label>
                      <Form.Control
                        type="text"
                        name="pdfFile"
                        disabled
                        value="Floor Plan"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="floorFile">
                      <Form.Label className="required form-label">
                        Upload Site Plan
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/pdf"
                        onChange={handleFloorChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <div className={'font12 red ms-2 pb-1 pt-1'}>
                {floorErrorMessage}
              </div>
            </Col>
          </Row>

          <Row className="">
            <Col>
              {selectedFloorPdf !== null && (
                <div className="d-flex flex-wrap">
                  <div className="m-3">
                    <h6>
                      {selectedFloorPdf?.name ??
                        selectedFloorPdf?.image_url
                          .split('?')[0]
                          .split('/')
                          .pop()}
                    </h6>
                  </div>
                </div>
              )}
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <h2 className="mt-2">Upload Virtual Tour</h2>
              <Form>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="pdfFile">
                      <Form.Label className="mb-4"></Form.Label>
                      <Form.Control
                        type="text"
                        name="src"
                        disabled
                        value="Virtual Tour"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="imageFile">
                      <Form.Label className="required form-label">
                        Upload Virtual Tour
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="vTour"
                        value={Vtour}
                        onChange={handleVtourChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className={'font12 red ms-2 pb-1 pt-1'}>
                  {vtourErrorMessage}
                </div>
                <Row className="">
                  <Col className="mt-3">
                    {
                      property?.imageSrcLink?.filter(
                        (img) => img.imgId === 9
                      )?.[0]?.imageUrl
                    }
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <Button type="submit">Update</Button>
        </Form>
      </div>
    </>
  );
};

export default UpdateProp;
