import SuwaneePreservesBrochure from "./PropertyBrochure/SuwaneePreserves.pdf"
import Dahlenoga1Brochure from "./PropertyBrochure/Dahlenoga_1.pdf"
import Dahlenoga2Brochure from "./PropertyBrochure/Dahlenoga_2.pdf"
import Dahlenoga3Brochure from "./PropertyBrochure/Dahlenoga_3.pdf"




const PropertyBrochure = [

 
  {
    "SNO": 1,
    "brochurePath": SuwaneePreservesBrochure
},

{
  "SNO": 2,
  "brochurePath": Dahlenoga1Brochure
},

{
  "SNO": 3,
  "brochurePath": Dahlenoga2Brochure
},

{
  "SNO": 4,
  "brochurePath": Dahlenoga3Brochure
},



];

export default PropertyBrochure;