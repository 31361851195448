import img1 from "./SuwaneePreserves_Images/PP10.webp";
import img2 from "./SuwaneePreserves_Images/PP2.webp";
import img3 from "./SuwaneePreserves_Images/PP3.webp";




const SuwaneePreserves = [

 
  {
    "SNO": 1,
    "id": 3,
    "imgType": "Exterior Front",
    "imgPath": img1
},

{
  "SNO": 2,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img2
},

{
  "SNO": 3,
  "id": 3,
  "imgType": "Exterior Front",
  "imgPath": img3
},

];

export default SuwaneePreserves;
