import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import Navb from '../Components/Navbar';
import httpuserprofile from '../js/http-lms_userprofile';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Footer from '../Components/Footer';
// import { Helmet } from "react-helmet";
import SEO from '../Constant/SEO.json';
import PageSeoComponent from '../SEO';

const RegistrationPage = () => {
  const [userType, setUserType] = useState('10');
  const [userRoles, setUserRoles] = useState([]);
  //multiselects
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({});
  const [formErros, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [defaultuserDetails, setUserDetails] = useState({});
  const [backupuserdetails, setBackupUserDetails] = useState({});
  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };
  //API call when page loader
  useEffect(() => {
    //fetching user roles
    httpuserprofile
      .get('/userprofileLMS/getUserRoles?appCode=KRD')
      .then((res) => {
        console.log('user roles', res);
        setUserRoles(res.data);
        getUserData();
      });
    //fetching countries list
    httpuserprofile.get('/userprofileLMS/getUserCountries').then((res) => {
      console.log('user countries', res);
      setCountriesList(res.data);
    });
    console.log('usertypes list : ', userRoles);
    console.log('countries list : ', countriesList);
  }, []);
  function getUserData() {
    //fetching user data
    httpuserprofile
      .get(
        `/userprofileLMS/getLoggedInUserDetails?userId=${sessionStorage.getItem(
          'userId'
        )}&roleId=${sessionStorage.getItem('roleId')}&authCode=KRDREBM390`
      )
      .then((response) => {
        console.log('UserDetails in update user:', response);
        setSelectedCityOption(response.data.city);
        setSelectedStateOption(response.data.state);
        setSelectedCountryOption(response.data.country);
        setUserDetails(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // validate data and enable/disable submit btn
  useEffect(() => {
    if (
      defaultuserDetails.firstName &&
      defaultuserDetails.lastName &&
      defaultuserDetails.emailId &&
      defaultuserDetails.phoneNumber &&
      defaultuserDetails.address &&
      defaultuserDetails.cityId &&
      defaultuserDetails.stateId &&
      defaultuserDetails.countryId
    ) {
      console.log('false');
      setDisable(false);
    } else {
      console.log('true');
      setDisable(true);
    }
  }, [defaultuserDetails]);
  //updating formdata for final submission
  function handleChange(e) {
    setErrorMessage('');
    const { name, value } = e.target;
    setUserDetails((pValue) => {
      console.log(pValue);
      return {
        ...pValue,
        [name]: value,
      };
    });
    setFormErrors(validate(defaultuserDetails));
    console.log(defaultuserDetails);
  }
  function submit() {
    setFormErrors(validate(defaultuserDetails));
  }
  // triggered after changing the country
  // reset selected state and city
  // fetch states list based on selected country
  function handleCountryChange(e) {
    console.log('selected country', e);
    //reset states & cities, once changed selected country
    setSelectedCountryOption(e);
    setSelectedStateOption(null);
    setSelectedCityOption(null);
    setStatesList([]);
    setCitiesList([]);
    defaultuserDetails.stateId = null;
    defaultuserDetails.cityId = null;
    // formData.countryId = e.id;
    setUserDetails((pValue) => {
      console.log(pValue);
      return {
        ...pValue,
        ['countryId']: e.countryId,
      };
    });
    // handleChange(e)
    httpuserprofile
      .get('/userprofileLMS/getUserStates?countryId=' + e.countryId)
      .then((res) => {
        console.log('user states', res);
        setStatesList(res.data);
      });
  }
  // triggered after changing the state
  // reset selected city
  // fetch cities list based on selected state
  function handleStateChange(e) {
    console.log('selected state', e);
    //reset cities, once changed selected country
    setSelectedStateOption(e);
    setSelectedCityOption(null);
    setCitiesList([]);
    // formData.stateId = e.id;
    defaultuserDetails.cityId = null;
    setUserDetails((pValue) => {
      console.log(pValue);
      return {
        ...pValue,
        ['stateId']: e.stateId,
      };
    });
    // handleChange(e)
    httpuserprofile
      .get('/userprofileLMS/getUserCities?stateId=' + e.stateId)
      .then((res) => {
        console.log('user cities', res);
        setCitiesList(res.data);
      });
  }
  // triggered after changing the city
  function handleCityChange(e) {
    console.log('selected city : ', e);
    setSelectedCityOption(e);
    // formData.cityId = e.id;
    setUserDetails((pValue) => {
      console.log(pValue);
      return {
        ...pValue,
        ['cityId']: e.cityId,
      };
    });
  }
  // final api call to save user information
  // update issubmit data if don't have any errors
  function submitData(e) {
    e.preventDefault();
    setFormErrors(validate(defaultuserDetails));
    setIsSubmit(true);
  }
  const validate = (values) => {
    const errors = {};
    const allowaplaspaces = /^[a-zA-Z ]*$/;
    const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const phonenumberregex = /^[6789]\d{9}$/;
    if (!values.firstName) {
      errors.firstName = 'First Name is required!';
    } else if (!allowaplaspaces.test(values.firstName)) {
      errors.firstName = 'First name should contain alphabets and spaces';
    }
    if (!values.lastName) {
      errors.lastName = 'Last Name is required!';
    } else if (!allowaplaspaces.test(values.lastName)) {
      errors.lastName = 'Last name should contain alphabets and spaces';
    }
    if (!values.emailId) {
      errors.emailId = 'Email Id is required!';
    } else if (!emailregex.test(values.emailId)) {
      errors.emailId = 'Please enter valid email id';
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Phone Number is required!';
    } else if (!phonenumberregex.test(values.phoneNumber)) {
      errors.phoneNumber = 'Phone number must be of 10 digits only';
    }
    if (!values.address1) {
      errors.address1 = 'Address is required!';
    }
    if (!values.cityId) {
      errors.cityId = 'City is required!';
    }
    if (!values.stateId) {
      errors.stateId = 'State is required!';
    }
    if (!values.countryId) {
      errors.countryId = 'Country is required!';
    }
    // if(!values.zipCode){
    //   errors.zipCode = "Zipcode is required!"
    // }

    return errors;
  };
  function handleKeyDown(e) {
    const keyCode = e.keyCode;
    const shiftKey = e.shiftKey;

    // Allow letters and special characters, but not numbers
    if (
      (!shiftKey && keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105)
    ) {
      e.preventDefault();
    } else if (
      keyCode === 187 ||
      keyCode === 189 ||
      keyCode === 219 ||
      keyCode === 221
    ) {
      e.preventDefault();
    }
  }

  function handleKeyDown2(e) {
    const keyCode = e.keyCode;

    // Allow numbers only, but not letters or special characters
    if (
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 187 ||
      keyCode === 189 ||
      keyCode === 191 ||
      keyCode === 107 ||
      keyCode === 109 ||
      keyCode === 106 ||
      keyCode === 111 ||
      keyCode === 187 ||
      keyCode === 107
    ) {
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (Object.keys(formErros).length === 0 && isSubmit) {
      setSubmitLoading(true);
      console.log('final submission : ', defaultuserDetails);
      httpuserprofile
        .put('/userprofileLMS/updateProfileDetails', defaultuserDetails)
        .then((response) => {
          console.log('update response : ', response.data);
          setErrorMessage('Updated sucessfully');
          setSubmitLoading(false);
          setTimeout(() => {
            setErrorMessage('');
          }, 5000);
        })
        .catch((e) => {
          console.log('error saving user : ', e.response);
          setSubmitLoading(false);
          setIsSubmit(false);
        });

      console.log(defaultuserDetails);
    }
  }, [formErros]);
  function resetEntireForm() {
    let form = document.getElementById('create-User-Form');
    let roledropdown = document.getElementById('roleselector');
    if (form) {
      form.reset();
    }
    if (roledropdown) {
      roledropdown.value = '';
    }
    setUserDetails({});
    setFormErrors({});
    setIsSubmit(false);
    setSubmitLoading(false);
    setSelectedCityOption(null);
    setSelectedStateOption(null);
    setSelectedCountryOption(null);
  }
  function testClick(e) {
    let form = document.getElementById('create-User-Form');
    if (form) {
      form.reset();
    }
    setErrorMessage('');
    setUserDetails({});
    setFormErrors({});
    setIsSubmit(false);
    setSelectedCityOption(null);
    setSelectedStateOption(null);
    setSelectedCountryOption(null);
    console.log('value of selected dropdown : ', e.target.value);
    setUserDetails((pValue) => {
      return {
        ...pValue,
        roleId: e.target.value,
      };
    });
  }
  function selectcheck(e) {
    setUserDetails((pValue) => {
      return {
        ...pValue,
        roleId: e.target.value,
      };
    });
  }
  function cancelForm(e) {
    let form = document.getElementById('create-User-Form');
    if (form) {
      form.reset();
    }
    e.preventDefault();
    setErrorMessage('');
    setUserDetails({});
    setFormErrors({});
    setIsSubmit(false);
    setSelectedCityOption(null);
    setSelectedStateOption(null);
    setSelectedCountryOption(null);
    setStatesList(null);
    setCitiesList(null);
    resetEntireForm();
  }
  // react-select custom styles and events
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      // border: `1px solid #ced4da`,
      // boxShadow: `0 0 0 1px`,
      // ':hover' : {
      //   // boxShadow: `0 0 0 1px `,
      //   backgroundColor : "blue"
      // }
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor:
        state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: '14px',
      paddingTop: '4px',
      paddingBottom: '4px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };

  const renderForm = () => {
    return (
      <Form id="create-User-Form">
        {/* { (formData.roleId == 10)  &&  <h2 className='text-center mb-4'>Builder Registration</h2>}
          { (formData.roleId == 11 || !formData.roleId)  &&  <h2 className='text-center mb-4'>Customer Registration</h2>} */}
        <div className="d-flex flex-wrap col-12">
          {defaultuserDetails.roleId == 10 && (
            <Form.Group
              className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
              controlId="companyName"
            >
              <Form.Label className="required">Company Name:</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                onChange={handleChange}
              />
              <p className=" font12">{formErros.companyName}</p>
            </Form.Group>
          )}
          {defaultuserDetails.roleId == 10 && (
            <Form.Group
              className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
              controlId="websiteUrl"
            >
              <Form.Label>Website URL:</Form.Label>
              <Form.Control
                type="text"
                name="websiteUrl"
                onChange={handleChange}
              />
              <p className=" font12">{formErros.websiteUrl}</p>
            </Form.Group>
          )}
          <Form.Group
            className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
            controlId="firstName"
          >
            <Form.Label className="required">First Name:</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              placeholder="Enter first name"
              onChange={handleChange}
              defaultValue={defaultuserDetails.firstName}
            />
            <p className="red font12">{formErros.firstName}</p>
          </Form.Group>
          <Form.Group
            className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
            controlId="middleName"
          >
            <Form.Label>Middle Name:</Form.Label>
            <Form.Control
              type="text"
              name="middleName"
              placeholder="Enter middle name"
              onChange={handleChange}
              defaultValue={defaultuserDetails.middleName}
            />
            <p className="red font12">{formErros.middleName}</p>
          </Form.Group>
          <Form.Group
            className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
            controlId="lastName"
          >
            <Form.Label className="required">Last Name:</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              placeholder="Enter last name"
              onChange={handleChange}
              defaultValue={defaultuserDetails.lastName}
            />
            <p className="red font12">{formErros.lastName}</p>
          </Form.Group>
          <Form.Group
            className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
            controlId="phoneNumber"
          >
            <Form.Label className="required">Contact Number:</Form.Label>
            <Form.Control
              type="text"
              name="phoneNumber"
              placeholder="Enter phone number"
              onChange={handleChange}
              defaultValue={defaultuserDetails.phoneNumber}
            />
            <p className="red font12">{formErros.phoneNumber}</p>
          </Form.Group>
          <Form.Group
            className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
            controlId="email"
          >
            <Form.Label className="required">Email:</Form.Label>
            <Form.Control
              type="email"
              name="emailId"
              disabled
              placeholder="Enter email id"
              onChange={handleChange}
              defaultValue={defaultuserDetails.emailId}
            />
            <p className="red font12">{formErros.emailId}</p>
          </Form.Group>
          <Form.Group
            className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
            controlId="address1"
          >
            <Form.Label className="required">Address1:</Form.Label>
            <Form.Control
              type="text"
              name="address1"
              placeholder="Enter address"
              onChange={handleChange}
              defaultValue={defaultuserDetails.address}
            />
            <p className="red font12">{formErros.address1}</p>
          </Form.Group>
          <Form.Group
            className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
            controlId="address2"
          >
            <Form.Label>Address2:</Form.Label>
            <Form.Control
              type="text"
              name="address2"
              placeholder="Enter address"
              onChange={handleChange}
              defaultValue={defaultuserDetails.address1}
            />
          </Form.Group>
          <Form.Group
            className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
            controlId="country"
          >
            <Form.Label className="required">Country:</Form.Label>
            <Select
              value={selectedCountryOption}
              onChange={handleCountryChange}
              placeholder="Select Country"
              className="multiselectdropdown"
              classNamePrefix="react-select"
              styles={colourStyles}
              getOptionLabel={(option) => `${option.countryName}`}
              options={countriesList}
            />
            <p className="red font12">{formErros.countryId}</p>
          </Form.Group>
          <Form.Group
            className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
            controlId="state"
          >
            <Form.Label className="required">State:</Form.Label>
            <Select
              value={selectedStateOption}
              onChange={handleStateChange}
              placeholder="Select State"
              className="multiselectdropdown"
              classNamePrefix="react-select"
              styles={colourStyles}
              getOptionLabel={(option) => `${option.stateName}`}
              options={statesList}
            />
            <p className="red font12">{formErros.stateId}</p>
          </Form.Group>
          <Form.Group
            className="col-12 col-md-6 col-lg-4 ps-1 pe-1"
            controlId="city"
          >
            <Form.Label className="required">City:</Form.Label>
            <Select
              value={selectedCityOption}
              onChange={handleCityChange}
              placeholder="Select City"
              className="multiselectdropdown"
              classNamePrefix="react-select"
              styles={colourStyles}
              getOptionLabel={(option) => `${option.cityName}`}
              options={citiesList}
            />
            <p className="red font12">{formErros.cityId}</p>
          </Form.Group>
          {/* <Form.Group className='col-12 col-md-6 col-lg-4 ps-1 pe-1' controlId="zipCode">
            <Form.Label className='required'>Zip/pin Code:</Form.Label>
            <Form.Control type="text" name="zipCode" placeholder="Enter zip/pin code" onChange={handleChange}/>
            <p className="red font12">{formErros.zipCode}</p>
          </Form.Group> */}
        </div>
        {/* <span>By Clicking Get Register you are agreed to the Worknotion</span> */}
      </Form>
    );
  };

  return (
    <>
      <PageSeoComponent {...SEO.register} />
      {/* <Helmet>
        <title>Update Profile</title>
        <meta
          name="description"
          content="Easiest way to find your dream home"
        />
        <meta name="keywords" content="keywords, separated, by, commas" />
        <link rel="canonical" href="https://www.example.com/page" />
      </Helmet> */}

      {/* <Navb isDefault={true} class="d-flex justify-content-between zi100 py-2 w-100 greybg px-5 position-fixed" /> */}
      <div className="d-flex pt-5 col-12">
        <div className="col-4 pt-5 d-none d-lg-block">
          <img
            className="d-block col-10 mx-auto my-auto"
            src={require('../images/sideimg1.png')}
            alt="First slide"
            // style={{ height: '700px'}}
          />
        </div>

        <div className="registration-page col-12 col-lg-7 pt-5 p-4">
          <h2 className="mt-4">Edit Profile</h2>
          {/* <Form>
        <Form.Group controlId="userType">
          <Form.Label>User Type:</Form.Label>
          <Form.Select
                onChange={testClick}
                className="form-select"
                id="roleselector"
                aria-label="Default select example"
                defaultValue={defaultuserDetails.roleId}
                
              >
                <option value="">Select Role</option>
                {userRoles.map((data, index) => {
                  return (
                    <option value={data.roleId} key={data.roleId}>{data.roleName}</option>
                )})}
              </Form.Select>
        </Form.Group>
      </Form> */}
          {renderForm()}
          <div className="col-12 text-center d-flex justify-content-around">
            {submitLoading ? (
              <div className="col-6 col-md-4 mt-3 mx-auto">
                <Spinner
                  className="mx-auto"
                  animation="border"
                  variant="secondary"
                />
              </div>
            ) : (
              <Button
                className="col-6 col-md-4 mx-auto"
                variant="primary"
                onClick={submitData}
              >
                Update
              </Button>
            )}
            {/* <Button className="col-6 col-md-4 mx-auto" variant='secondary' onClick={cancelForm}>Cancel</Button> */}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default RegistrationPage;
