import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, Dropdown, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Navb from '../Components/Navbar';
import Footer from '../Components/Footer';
import httpproperties from '../js/http-realestate-properties';
import { ROUTES } from '../Constant/Routes';
// import { Helmet } from 'react-helmet';

const ModelsList = () => {
  const [models, setModels] = useState([]);
  const [sortingOption, setSortingOption] = useState('all');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [selectedModelForActive, setSelectedModelForActive] = useState(null);
  const [modelImgType, setModelImgType] = useState('');
  const [uploadeImage, setUploadedImage] = useState(null);
  const [error, setError] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredModels, setFilteredModels] = useState([]);

  const navigate = useNavigate();

  function addModel() {
    navigate(ROUTES.ADD_MODEL);
  }

  function navToPrevRoute() {
    navigate(ROUTES.MY_MODELS);
  }

  useEffect(() => {
    getModelList();
  }, []);

  useEffect(() => {
    filterModels();
  }, [searchQuery, models]);

  function getModelList() {
    httpproperties
      .get(
        `/modelInformation/getModelInfoByUser/${sessionStorage.getItem(
          'profileId'
        )}`
      )
      .then((response) => {
        if (response.data === 'No records found') {
          setError(response.data);
        } else {
          console.log('fetch model list : ', response);
          setModels(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const deleteModel = (modelId) => {
    httpproperties
      .delete(`modelInformation/deleteModelById?id=${modelId}`)
      .then((response) => {
        console.log('Model deleted:', response);
        getModelList();
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error('Error deleting model:', error);
        setShowDeleteModal(false);
      });
  };

  const toggleModelStatus = (modelId) => {
    const updatedModels = models.map((model) => {
      if (model.id === modelId) {
        return {
          ...model,
          status: model.status === 'active' ? 'inactive' : 'active',
        };
      }
      return model;
    });
    setModels(updatedModels);
  };

  const openDeleteModal = (model) => {
    setSelectedModel(model);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedModel(null);
    setShowDeleteModal(false);
  };

  const openActiveModal = (model) => {
    setSelectedModelForActive(model);
    setShowActiveModal(true);
  };

  const closeActiveModal = () => {
    setSelectedModelForActive(null);
    setShowActiveModal(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setModelImgType(value);
  };

  function handleFileEvent(e) {
    console.log(e.target.files);
    setUploadedImage(e.target.files[0]);
  }

  function UpdateModel(model) {
    setSelectedModel(model);
    navigate(ROUTES.UPDATE_MODELS, { state: { selectedModel: model } });
  }

  const filterModels = () => {
    if (!searchQuery.trim()) {
      setFilteredModels(models);
    } else {
      const filtered = models.filter((model) =>
        model.modelTitle.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredModels(filtered);
    }
  };

  return (
    <>
      {/* <Helmet>
        <title>My Models</title>
        <meta
          name="description"
          content="Easiest way to find your dream home"
        />
        <meta name="keywords" content="keywords, separated, by, commas" />
        <link rel="canonical" href="https://www.example.com/page" />
      </Helmet> */}

      {/* <Navb
        isDefault={true}
        class="d-flex justify-content-between zi100 py-2 w-100 greybg px-5 position-fixed"
      /> */}
      <Container className="pt-5">
        <div className="my-models-title d-flex justify-content-between mb-4 mt-5">
          <div className="d-flex">
            <div
              className=" d-flex align-items-center mt-4 pointer"
              onClick={navToPrevRoute}
            >
              <img
                className="pe-3 backarrow"
                src={require('../images/leftarrow.png')}
                alt="back"
              />
              <h4 className="SectionHeadings mt-2">Models List</h4>
            </div>
          </div>
          <div className="mt-4 ">
            <Button onClick={addModel}>Add Model</Button>
          </div>
        </div>
        <div className="container">
          <Form.Control
            type="text"
            placeholder="Search Model"
            className=" w-25 mb-3 "
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="row">
            {Array.isArray(filteredModels) ? (
              filteredModels.map((model) => {
                const validImages = model.imageSrcLink.filter(
                  (image) =>
                    image.imgId !== 9 &&
                    image.imgId !== 12 &&
                    image.imgId !== 17
                );
                const imageUrl =
                  validImages.length > 0 ? validImages[0].imageUrl : '';

                return (
                  <div className="col-lg-4" key={model.id}>
                    <div className="card mb-4 shadow">
                      <div className="Model-image imagek">
                        {imageUrl && (
                          <img
                            src={imageUrl}
                            className="w-100"
                            alt="API Image"
                            style={{ height: '300px' }}
                          />
                        )}
                      </div>
                      <div className="d-flex m-4">
                        <div className="Model-details ms-3">
                          <p>
                            <strong>Title:</strong> {model.modelTitle}
                          </p>
                          <p>
                            <strong>Description:</strong>{' '}
                            {model.modelDescription}
                          </p>
                          <p>
                            <strong>Price:</strong> {model.price}
                          </p>
                          <p>
                            <strong>Number of Bedrooms:</strong>{' '}
                            {model.noOfBedrooms}
                          </p>
                          <p>
                            <strong>Number of Garages:</strong>{' '}
                            {model.noOfGarages}
                          </p>
                        </div>
                        <div className="Model-actions">
                          <Dropdown
                            drop={'down-centered'}
                            align={{ lg: 'start' }}
                          >
                            <Dropdown.Toggle
                              className="dropdown-more p-0"
                              variant="success"
                              title="more actions"
                              id="dropdown-basic"
                            >
                              <img
                                src={require('../images/more.png')}
                                alt="Image not found"
                                className="more"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => UpdateModel(model)}>
                                Update Model
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => openDeleteModal(model)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>{models}</div>
            )}
          </div>
        </div>
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Model</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the model:{' '}
            {selectedModel?.modelTitle}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => deleteModel(selectedModel?.id)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showActiveModal} onHide={closeActiveModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedModelForActive?.status === 'active'
                ? 'Make Inactive'
                : 'Make Active'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to{' '}
            {selectedModelForActive?.status === 'active'
              ? 'make this Model inactive'
              : 'make this Model active'}
            ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeActiveModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                toggleModelStatus(selectedModelForActive?.id);
                closeActiveModal();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default ModelsList;
