import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from '../Constant/Routes';

import Home from '../Pages/Home';
import Specifications from '../Pages/Specifications';
import AboutUs from '../Pages/AboutUs';
import ContactUs from '../Pages/ContactUs';
import Projects from '../Pages/Projects';
import FindYourHome from '../Pages/FindYourHome';
import RegistrationPage from '../Pages/RegistrationPage';
import Verifyotp from '../Pages/Verifyotp';
import Updatepass from '../Pages/Updatepass';
import SignInBS from '../Pages/SignInBS';
import ForgetPassword from '../Pages/ForgetPassword';
import PropertyList from '../Pages/MyProperties';
import AddPropertyPage from '../Pages/AddProperty';
import AddModels from '../Pages/MyModels';
import AddModelPage from '../Pages/AddModel';
import UpdateProp from '../Pages/UpdateProp';
import ShowModel from '../Pages/ShowModel';
import UpdateModels from '../Pages/UpdateModels';
import PropertyDesc from '../Pages/PropertyDesc';
import ModelDesc from '../Pages/ModelDesc';
import UpdatePassInside from '../Pages/UpdatePassInside';
import PropertyModels from '../Pages/PropertyModels';
import AddEvent from '../Pages/EB5/AddEvent';
import MyEvents from '../Pages/EB5/MyEvents';
import UpdateEvent from '../Pages/EB5/UpdateEvent';
import CheckRegisterUsers from '../Pages/EB5/CheckRegisterUsers';
import ContactDetails from '../Pages/EB5/ContactDetails';

const RoutesComponents = () => {
  return (
    <Routes>
      <Route path={ROUTES.DEFAULT} element={<Home />} />
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.LOGIN} element={<SignInBS />} />
      <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPassword />} />
      <Route path={ROUTES.UPDATE_PASSWORD} element={<Updatepass />} />
      <Route path={ROUTES.VERIFY_OTP} element={<Verifyotp />} />
      <Route path={ROUTES.SPECIFICATIONS} element={<Specifications />} />
      <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
      <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
      <Route path={ROUTES.PROJECTS} element={<Projects />} />
      <Route path={ROUTES.FIND_YOUR_HOME} element={<FindYourHome />} />
      <Route path={ROUTES.UPDATE_PROFILE} element={<RegistrationPage />} />
      <Route path={ROUTES.MY_PROPERTIES} element={<PropertyList />} />
      <Route path={ROUTES.ADD_PROPERTY} element={<AddPropertyPage />} />
      <Route path={ROUTES.MY_MODELS} element={<AddModels />} />
      <Route path={ROUTES.PROPERTY_MODELS} element={<PropertyModels />} />
      <Route path={ROUTES.ADD_EVENT} element={<AddEvent />} />
      <Route path={ROUTES.ADD_MODEL} element={<AddModelPage />} />
      <Route path={ROUTES.UPDATE_PROPERTY} element={<UpdateProp />} />
      <Route path={ROUTES.SHOW_MODELS} element={<ShowModel />} />
      <Route path={ROUTES.UPDATE_MODELS} element={<UpdateModels />} />
      <Route path={ROUTES.PROPERTY_DESC} element={<PropertyDesc />} />
      <Route path={ROUTES.MODEL_DESC} element={<ModelDesc />} />
      <Route path={ROUTES.MY_EVENTS} element={<MyEvents />} />
      <Route path={ROUTES.UPDATE_EVENT} element={<UpdateEvent />} />
      <Route path={ROUTES.REGISTER_EVENT} element={<CheckRegisterUsers />} />
      <Route path={ROUTES.CONTACT_DETAILS} element={<ContactDetails />} />
      <Route
        path={ROUTES.UPDATE_USER_PASSWORD}
        element={<UpdatePassInside setPop={undefined} />}
      />
    </Routes>
    
  );
};

export default RoutesComponents;

// const PageLoader = () => {
//   return (
//     <div className="page-loader">
//       <div className="loader"></div>
//     </div>
//   );
// };
