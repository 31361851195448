// src/constants/routes.js

export const ROUTES = {
  DEFAULT: '/',
  HOME: '/home',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forget-password',
  UPDATE_PASSWORD: '/update-password',
  VERIFY_OTP: '/verify-otp',
  SPECIFICATIONS: '/specifications',
  ABOUT_US: '/about-us',
  CONTACT_US: '/contact-us',
  PROJECTS: '/projects',
  FIND_YOUR_HOME: '/find-your-home',
  UPDATE_PROFILE: '/update-profile',
  MY_PROPERTIES: '/my-properties',
  ADD_PROPERTY: '/add-property',
  MY_MODELS: '/my-models',
  PROPERTY_MODELS: '/property-models',
  ADD_EVENT: '/add-event',
  ADD_MODEL: '/add-model',
  UPDATE_PROPERTY: '/update-property',
  SHOW_MODELS: '/show-models',
  UPDATE_MODELS: '/update-models',
  PROPERTY_DESC: '/luxury-properties',
  MODEL_DESC: '/model-description',
  MY_EVENTS: '/my-events',
  UPDATE_EVENT: '/update-event',
  UPDATE_USER_PASSWORD: '/update-user-password',
  REGISTER_EVENT: '/register-event',
  CONTACT_DETAILS: '/contact-details',

};
